
import DataDefinition from '../DataDefinition';
import AdvancedButtonRegionContent from './AdvancedButtonRegionContent';
import ButtonRegionContent from './ButtonRegionContent';
import { ElementData } from './ElementData';
import ImageRegionContent from './ImageRegionContent';

class  ButtonWrapper extends DataDefinition {
    @Reflect.metadata('design:type', ElementData)
    btnObj: ElementData<ButtonRegionContent 
                        | AdvancedButtonRegionContent | ImageRegionContent> | null;
    value: string;
    status: number;

    constructor({
        btnObj = null,
        value = "",
        status = 0   
    }: Partial<ButtonWrapper> = {}) {
        super();
        // 安装allowedFields
        this.allowedFields = ['btnObj', 'value', 'status'];
        this.btnObj = btnObj;
        this.value = value;
        this.status = status;
    }    
}



class  FunctionConfig extends DataDefinition {
    funcName: string;
    funcParams: Record<string, any>;

    constructor({
        funcName = "",
        funcParams = {},
    }: Partial<FunctionConfig> = {}) {
        super();
        // 安装allowedFields
        this.allowedFields = ['funcName', 'funcParams'];
        this.funcName = funcName;
        this.funcParams = funcParams;
    }
    
}

class PromptSetVarConfig extends DataDefinition {
    prompt: string;
    destVar: string;
    //避免未来有不断拓展的参数
    mode?: string;

    constructor({
        prompt = "",
        destVar = "",
        mode = ""
    }: Partial<PromptSetVarConfig> = {}) {
        super();
        // 安装allowedFields
        this.allowedFields = ['prompt', 'destVar', 'mode'];
        this.prompt = prompt;
        this.destVar = destVar;
        this.mode = mode;
    }
}

export  { FunctionConfig, PromptSetVarConfig, ButtonWrapper };