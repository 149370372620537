import { ElementContent } from './ElementData';
import { FunctionConfig } from './UtilTypes';
import { cloneDeep } from 'lodash';

class ButtonRegionContent extends ElementContent {
    borderRadius: number;
    backgroundColor: string;
    fontColor: string;
    // 按钮显示的文字
    value: string;
    fontSize: number;
    @Reflect.metadata("design:type", FunctionConfig)
    onclickFunc: FunctionConfig | null;
    shadowX: number;
    shadowY: number;
    shadowBlur: number;
    shadowSpread: number;
    shadowColor: string;
    borderWidth: number;
    borderColor: string;
    bulletCircleRadius: number;
    bulletCircleColor: string;
    bulletLetterColor: string;
    bulletLetterSize: number;
    bulletLetter: string;
    bulletEnabled: boolean;
    clickSoundUrl: string;
    showAudioIcon: boolean;
    valueAudioUrl: string;
    buttonType: string;
    // 这个elment可以是选择题地一个选项，需要记录选项的状态
    status?: number;
    zIndex: number;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 100,
        height = 50,
        borderRadius = 5,
        backgroundColor = "#1890ff",
        fontColor = "#ffffff",
        value = "按钮",
        fontSize = 16,
        onclickFunc = null,
        shadowX = 0,
        shadowY = 0,
        shadowBlur = 0,
        shadowSpread = 0,
        shadowColor = "#000000",
        borderWidth = 0,
        borderColor = "#000000",
        bulletCircleRadius = 20,
        bulletCircleColor = "#88B1DD",
        bulletLetterColor = "#000000",
        bulletLetterSize = 16,
        bulletLetter = "A",
        bulletEnabled = true,
        clickSoundUrl = "",
        showAudioIcon = false,
        valueAudioUrl = "",
        buttonType = "",
        status = 0,
        zIndex = 0
    }: Partial<ButtonRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'borderRadius', 'backgroundColor', 'fontColor', 'value', 'fontSize', 'onclickFunc',
            'shadowX', 'shadowY', 'shadowBlur', 'shadowSpread', 'shadowColor', 'borderWidth',
            'borderColor', 'bulletCircleRadius', 'bulletCircleColor', 'bulletLetterColor',
            'bulletLetterSize', 'bulletLetter', 'bulletEnabled', 'clickSoundUrl', 'showAudioIcon',
            'valueAudioUrl', 'buttonType', 'status'];
        this.borderRadius = borderRadius;
        this.backgroundColor = backgroundColor;
        this.fontColor = fontColor;
        this.value = value;
        this.fontSize = fontSize;
        this.onclickFunc = onclickFunc;
        this.shadowX = shadowX;
        this.shadowY = shadowY;
        this.shadowBlur = shadowBlur;
        this.shadowSpread = shadowSpread;
        this.shadowColor = shadowColor;
        this.borderWidth = borderWidth;
        this.borderColor = borderColor;
        this.bulletCircleRadius = bulletCircleRadius;
        this.bulletCircleColor = bulletCircleColor;
        this.bulletLetterColor = bulletLetterColor;
        this.bulletLetterSize = bulletLetterSize;
        this.bulletLetter = bulletLetter;
        this.bulletEnabled = bulletEnabled;
        this.clickSoundUrl = clickSoundUrl;
        this.showAudioIcon = showAudioIcon;
        this.valueAudioUrl = valueAudioUrl;
        this.buttonType = buttonType;
        this.status = status;
        this.zIndex = zIndex;
    }

    convertToText(): string {
        let text = '';
        if (this.bulletEnabled) {
            text = '选项（' + this.bulletLetter + '）：' + this.value;
        } else {
            text = '选项：' + this.value;
        }
        return text;
    }

    static duplicate(srcContent: ButtonRegionContent, positionX: number,
        positionY: number, maxZIndex: number): ButtonRegionContent {
        const newContent = cloneDeep(srcContent);
        newContent.positionX = positionX;
        newContent.positionY = positionY;
        return newContent;
    }
};

export default ButtonRegionContent;