import {ElementContent} from './ElementData';
import { ChatMessage } from '../ChatMessage';

class HiddenChatContent extends ElementContent {
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ChatMessage)
    chatList: ChatMessage[];
    
    constructor({
        positionX = 0,
        positionY = 0,
        width = 0,
        height = 0,
        chatList = []
    }: Partial<HiddenChatContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'chatList'];
        this.chatList = chatList;
    }
};

export default HiddenChatContent;