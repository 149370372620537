
import styles from '../common/element.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import useCommonFunction from '@/hooks/useCommonFunction';
import { containsVariablePattern, convertToCloudFrontUrl, rewriteText } from '@/utils/utils';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import ImageRegionContent from '@/base/ElementData/ImageRegionContent';

interface ImageRegionProps extends IntrinsicElementProps<ImageRegionContent> {
    index: number;
    isPlaying?: boolean;
}

const ImageRegion: React.FC<ImageRegionProps> = ({
    elementData, 
    isEditable, 
    handleFocusItem, 
    handleResize,
    handleDragStop, 
    handleDelete, 
    index, 
    isPlaying = false }) => {
    const { executeFunc } = useCommonFunction();
    const normalBorder = elementData.content.borderWidth !== undefined && elementData.content.borderColor
        ? elementData.content.borderWidth + 'px solid ' + elementData.content.borderColor
        : '10px solid black';
    const boxShadow = `${elementData.content?.shadowX || 0}px 
                        ${elementData.content?.shadowY || 0}px 
                        ${elementData.content?.shadowBlur || 0}px 
                        ${elementData.content?.shadowSpread || 0}px 
                        ${elementData.content?.shadowColor || '#000000'}`;
    const fourCornersRadius = {
        borderTopLeftRadius: `${elementData.content.radiusTL}px` ?? '20px',
        borderTopRightRadius: `${elementData.content.radiusTR}px` ?? '20px',
        borderBottomLeftRadius: `${elementData.content.radiusBL}px` ?? '20px',
        borderBottomRightRadius: `${elementData.content.radiusBR}px` ?? '20px',
    }

    const blankStyleProps = {
        width: "100%",
        height: "100%",
        zIndex: elementData.content.zIndex,
        backgroundColor: elementData.content.color || 'green',
        border: normalBorder,
        borderRadius: elementData.content.radiusTL ?? '',
        boxSizing: 'border-box',
        boxShadow: boxShadow,
        ...fourCornersRadius
    };
    const imgStyleProps = {
        width: "100%",
        height: "100%",
        zIndex: elementData.content.zIndex,
        opacity: elementData.content.alpha ?? 1,
        border: elementData.content.borderWidth !== undefined && elementData.content.borderColor
            ? elementData.content.borderWidth + 'px solid ' + elementData.content.borderColor
            : '10px solid black',
        borderRadius: `${elementData.content.radiusTL}px` ?? '',
        boxSizing: 'border-box',
        boxShadow: boxShadow,
        ...fourCornersRadius
    };

    const finalStaticUrl = containsVariablePattern(elementData.content.src) ? rewriteText(elementData.content.src) : elementData.content.src;
    const finalDynamicUrl = containsVariablePattern(elementData.content.dynamicSrc) ? rewriteText(elementData.content.dynamicSrc) : elementData.content.dynamicSrc;

    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}>
        <div
            style={{ ...commonStyle }}
            onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''}
             ${isEditable ? styles.element : ''}`}
        >
            {
                elementData.content.src ?
                    <img
                        draggable={false}
                        src={isPlaying && index === 0 ? 
                                convertToCloudFrontUrl(finalDynamicUrl) : convertToCloudFrontUrl(finalStaticUrl)}
                        style={imgStyleProps as React.CSSProperties}
                        className={styles.img}
                    />
                    :
                    <div style={blankStyleProps as React.CSSProperties}></div>
            }
        </div>
    </BaseDragableElement>)
};

export default ImageRegion;
