import { Modal } from "antd";

interface ModalWidgetProps {
    icon?: React.ReactNode;
    title: string;
    content: string;
    onOk: () => void;
}

export function modalConfirmWidget(props: ModalWidgetProps) {
    return Modal.confirm({
        width: 400,
        icon: null,
        centered: true,
        okButtonProps: {
            size: "middle",
        },
        cancelButtonProps: {
            size: "middle",
        },
        // title: '确定删除文本？',
        // onOk: (close) => {
        //     close();
        //     // deleteData(item.id);
        // },
        okText: "确定",
        cancelText: "取消",
        ...props,
    });
}

export function modalSuccessWidget(props: ModalWidgetProps) {
    return Modal.success({
        width: 400,
        icon: null,
        centered: true,
        okButtonProps: {
            size: "middle",
        },
        // title: '确定删除文本？',
        // onOk: (close) => {
        //     close();
        //     // deleteData(item.id);
        // },
        okText: "确定",
        ...props,
    });
}
