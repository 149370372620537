import { makeAutoObservable } from "mobx";

class UserAssetData {
    id: number;
    numCoins: number;
    numDiamonds: number;
    medals: Record<string, any>;

    constructor({
            id = 0,
            numCoins = 0, 
            numDiamonds = 0,
            medals = {}
        }: Partial<UserAssetData> = {}) {
        this.id = id;
        this.numCoins = numCoins;
        this.numDiamonds = numDiamonds;
        this.medals = medals;
        makeAutoObservable(this);
    }
};

export default UserAssetData;
