import { useState } from 'react';
import styles from './textToSpeech.module.less';
import MultimediaAPI from '@/api/multimedia';
import { message, Button, Select } from 'antd';

interface TextToSpeechProps {
    text: string;
    btnText: string;
    successCallback: (url: string) => void;
    ttsVoice?: string;
    ttsVoiceChangeCallback?: (voice: string) => void;
}


const voiceList = [
    { value: "BV700_streaming", name: "默认-灿灿" },
    { value: "BV705_streaming", name: "炀炀" },
    { value: "BV034_streaming", name: "知性姐姐" },
    { value: "BV102_streaming", name: "儒雅青年" },
    { value: "BV007_streaming", name: "亲切女生" },   
    { value: "BV113_streaming", name: "甜宠少御" },
    { value: "BV056_streaming", name: "阳光男生" },
    { value: "BV051_streaming", name: "奶气萌娃" },
    { value: "BV005_streaming", name: "活泼女生" },
    { value: "BV701_streaming", name: "擎苍-有声阅读" },
    { value: "BV033_streaming", name: "温柔小哥" },
    { value: "BV001_streaming", name: "通用女声" },
    { value: "BV002_streaming", name: "通用男声" }
];

const TextToSpeech: React.FC<TextToSpeechProps> = ({
    text, btnText, successCallback, ttsVoice, ttsVoiceChangeCallback
}) => {
    const ttsAPI = new MultimediaAPI();
    const [selectedVoice, setSelectedVoice] = useState(ttsVoice ? ttsVoice : 'BV700_streaming');
    const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);

    const genVoice = async () => {
        let res: any
        try {
            setIsGeneratingAudio(true);
            res = await ttsAPI.genVoiceAndUpload(
                {
                    text: text,
                    voice_type: selectedVoice || 'BV700_streaming'
                }, 'tts');
            successCallback && successCallback(res.file_url);
        } catch (error) {
            console.error('genVoiceAndUpload:', error);
            message.error('语音合成失败');
        } finally {
            setIsGeneratingAudio(false);
        }
    }

    return (
        <div className={styles.textToSpeech}>
            <div className={styles.voiceText}>音色:</div>
            <div className={styles.textToSpeechSelect}>
                <Select
                    defaultValue={selectedVoice}
                    style={{ width: 120 }}
                    onChange={(value) => {
                        setSelectedVoice(value);
                        ttsVoiceChangeCallback && ttsVoiceChangeCallback(value);
                    }}
                >
                    {voiceList.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div className={styles.textToSpeechBtn}>
                <Button
                    type="primary"
                    onClick={genVoice}
                    loading={isGeneratingAudio}
                >
                    {btnText || '生成语音'}
                </Button>
            </div>
        </div>
    )
}

export default TextToSpeech;