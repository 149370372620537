import { useState, useEffect } from 'react';
import { Drawer, Button} from 'antd';
import PropTypes from 'prop-types';
import service from '@/services/axios';
import styles from './resourceFileSelector.module.less';
import { cloneDeep } from 'lodash';

interface ResourceFileSelectorProps {
    fileSelectorOpen: boolean;
    setFileSelectorOpen: (open: boolean) => void;
    afterSelectionCallback: (obj: any) => void;
    resourceType: number;
}

const ResourceFileSelector: React.FC<ResourceFileSelectorProps> = ({
    fileSelectorOpen, 
    setFileSelectorOpen, 
    afterSelectionCallback, 
    resourceType }) => {

    interface ResourceFile {
        type: number;
        content: any;
    }
    
    const [resourceFileList, setResourceFileList] = useState<ResourceFile[]>([]);
  
    useEffect(() => {
        if (fileSelectorOpen && resourceType) {
            getResourceFileList();
        }
    }, [fileSelectorOpen, resourceType]);

    const getResourceFileList = async () => {
        try {
            // 拿到所有templates file
            console.log('call to get resource files');
            // 需要获取两个文件夹的文件
            let imageFiles = [];
            let filteredTemplateFiles = [];
            // part 1: images
            let res: any = await service.get('/fetch_file_urls', { params: { dir: 'images' } });
            if (res.status === 'success') {
                // 需要进一步做过滤
                console.log("image files", res);
                imageFiles = cloneDeep(res.data.files_info);
            } 

            // part 2: templates
            res = await service.get('/fetch_file_urls', { params: { dir: 'templates' } });
            if (res.status === 'success') {
                // 需要进一步做过滤
                const templateFiles = res.data.files_info;
                for (let i = 0; i < templateFiles.length; i++) {
                    const tType = parseInt(templateFiles[i].file_url.split('/').pop().split('-')[0], 10);

                    if (tType === 104) {
                        // 需要从文件名中提前出thumbnail
                        const segs = templateFiles[i].file_url.split('/').pop().split('-');
                        const thumbnail = 'https://agi4kids.s3.us-west-2.amazonaws.com/images/' + segs[1] + '.' + segs[2].split('.')[0];
                        const iProp = { fullName: templateFiles[i].file_url,
                                        thumbnail: thumbnail,
                                        mode: 1};

                        filteredTemplateFiles.push(iProp);
                    }
                }
            } 

            // combine the two
            // console.log('imageFiles', imageFiles, 'filteredTemplateFiles', filteredTemplateFiles);
            let combinedFiles: ResourceFile[] = [];
            filteredTemplateFiles.forEach((template) => {
                combinedFiles.push({type: 104, content: template});
            });
            imageFiles.forEach((img: any) => {
                combinedFiles.push({type: 102, content: img.file_url});
            });
      
            setResourceFileList(combinedFiles);


        } catch (err) {
            console.log(err);
        }
    }

    // mode 0 -- new
    // mode 1 -- load from library
    const handleClick = (itemProp: any) => {
        afterSelectionCallback(itemProp);
        setFileSelectorOpen(false);
        document.body.style.cursor = 'copy';
    };

    const renderResourceItem = (item: ResourceFile) => {
        
        if (item.type === 102) {
            return  <div onClick={() => handleClick({mode: 1, src: item.content })} key={item.content}>
                        <img src={item.content} style={{ width: '100px', height: '100px' }}/>
                        <div>img:{item.content.split('/').pop().substr(0, 12)}</div> 
                    </div>;
        } else if (item.type === 104) {
            return  <div key={item.content.thumbnail + Math.random()} onClick={() => handleClick({mode: 2, btnObj: item.content.fullName})}>
                        <img src={item.content.thumbnail} alt="thumbnail" style={{ width: '100px', height: '100px' }} />
                        <div>advBtn:{item.content.fullName.split('/').pop().substr(0, 9)}</div>                    
                    </div>;
        }

        throw "a resource type that can't be handled";
    };

    return (
        <Drawer
            title="选择按钮模板/图片"
            placement="right"
            closable={true}
            onClose={() => setFileSelectorOpen(false)}
            open={fileSelectorOpen}
            width={500}
            className={styles.templateFileSelector}
        >
            <div className={styles.divHorizontalLayout}>
                <div className={styles.uploadMedia}>
                    <Button onClick={() => handleClick({mode: 0})}>
                        新图片按钮组
                    </Button>
                </div>
            </div>
            <hr/>
            <br></br>
            <br></br>
            <div className={styles.fileList}>
                {resourceFileList.map(renderResourceItem)}
            </div>
        </Drawer>
    );
};

export default ResourceFileSelector;