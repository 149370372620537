import { useState } from 'react'
import magicGenIcon from '@/assets/img/magic_wand.svg';
import magicGenIconHover from '@/assets/img/magic_wand_hover.svg';
import PropTypes from 'prop-types';
import styles from './sunoGen.module.less'

const MagicWand = ({ onMagicGenerate }) => {
    const [magicGenBtnAnimate, setMagicGenBtnAnimate] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const imgClick = () => {
        setMagicGenBtnAnimate(true);
        setTimeout(() => {
            setMagicGenBtnAnimate(false);
        }, 1000);
        onMagicGenerate();
    }
    // 根据是否悬停来选择图像
    const imageSrc = isHovering ? magicGenIconHover : magicGenIcon;
    return (
        <img
            src={imageSrc}
            alt="magic wand"
            className={`${styles.magicGenBtn} ${magicGenBtnAnimate ? styles.magicGenBtnAnimate : ""}`}
            onClick={(e) => {
                e.stopPropagation();
                imgClick();
            }
            }
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        />
    )
}

export default MagicWand;

MagicWand.propTypes = {
    onMagicGenerate: PropTypes.func.isRequired
}