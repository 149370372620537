import { useState, useEffect, useRef, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import BaseElement from './BaseElement';
import ElementResizable from './ElementResizable';
import { ElementData, ElementContent } from '@/base/ElementData/ElementData';

interface IntrinsicElementProps<T> {
    elementData: ElementData<T>;
    isEditable: boolean;
    handleFocusItem: (elementData: ElementData<ElementContent>, event: React.MouseEvent | React.TouchEvent) => void;
    handleResize?: (elementData: ElementData<ElementContent>) => void | null;
    handleDragStop?: (elementId: number, newX: number, newY: number) => void | null;
    handleDelete?: (elementId: number) => void | null;
    index?: number;
}

interface BaseDragableElementProps<T> extends IntrinsicElementProps<T> {
    children: ReactNode;
}

const BaseDragableElement: React.FC<BaseDragableElementProps<ElementContent>> = ({
    children,
    elementData, 
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    index = 0
}) => {
    const { viewportStore } = useStores();
    const { scaleRatio } = viewportStore;

    const [keyDown, setKeyDown] = useState(false);
    const [currentPos, setCurrentPos] = useState({
        x: elementData.content.positionX,
        y: elementData.content.positionY
    });

    useEffect(() => {
        setCurrentPos({ x: elementData.content.positionX, y: elementData.content.positionY });
    }, [elementData.content.positionX, elementData.content.positionY]);

    let pos_data: { startX: number; startY: number; startLeft: number; startTop: number } = { startX: 0, startY: 0, startLeft: 0, startTop: 0 };
    let tempX: number | null = null;
    let tempY: number | null = null;
    const elementRef = useRef<HTMLDivElement | null>(null);

    const handleMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
        if (!isEditable) return;

        if (handleFocusItem) {
            handleFocusItem(elementData, e);
        }

        const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

        pos_data = {
            startX: clientX,
            startY: clientY,
            startLeft: elementData.content.positionX,
            startTop: elementData.content.positionY,
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("touchmove", handleMouseMove, { passive: false });
        document.addEventListener("touchend", handleMouseUp, { passive: false });
    };

    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
        const moveX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const moveY = 'touches' in e ? e.touches[0].clientY : e.clientY;

        const deltaX = Math.round(moveX - pos_data.startX);
        const deltaY = Math.round(moveY - pos_data.startY);
        const newLeft = pos_data.startLeft + deltaX;
        const newTop = pos_data.startTop + deltaY;

        if (elementRef.current) {
            elementRef.current.style.left = `${newLeft}px`;
            elementRef.current.style.top = `${newTop}px`;
            setCurrentPos({ x: newLeft, y: newTop });
        }
    };

    const handleMouseUp = (e: MouseEvent | TouchEvent) => {
        const moveX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const moveY = 'touches' in e ? e.touches[0].clientY : e.clientY;

        const deltaX = moveX - pos_data.startX;
        const deltaY = moveY - pos_data.startY;
        const newLeft = pos_data.startLeft + deltaX;
        const newTop = pos_data.startTop + deltaY;

        if (handleDragStop) {
            handleDragStop(elementData.id, newLeft, newTop);
        }

        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("touchend", handleMouseUp);
    };

    const isEditableElement = (item: HTMLElement): boolean => {
        return item.tagName === 'INPUT' || item.tagName === 'TEXTAREA'
            || item.tagName === 'SELECT' || item.isContentEditable;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (!isEditable) return;
        const activeElement = document.activeElement as HTMLElement;
        const isInInput = isEditableElement(activeElement);

        if (!isInInput && elementData.isFocus) {
            let adjustment = 1;
            let newPositionX = tempX !== null ? tempX : elementData.content.positionX;
            let newPositionY = tempY !== null ? tempY : elementData.content.positionY;

            switch (e.key) {
                case 'ArrowUp':
                    newPositionY -= adjustment;
                    break;
                case 'ArrowDown':
                    newPositionY += adjustment;
                    break;
                case 'ArrowLeft':
                    newPositionX -= adjustment;
                    break;
                case 'ArrowRight':
                    newPositionX += adjustment;
                    break;
                case 'Backspace':
                    if (!keyDown) {
                        setKeyDown(true);
                        if (handleDelete) handleDelete(elementData.id);
                    }
                    return;
                default:
                    return;
            }
            tempX = newPositionX;
            tempY = newPositionY;

            if (elementRef.current) {
                elementRef.current.style.left = `${newPositionX}px`;
                elementRef.current.style.top = `${newPositionY}px`;
                setCurrentPos({ x: newPositionX, y: newPositionY });
            }
        }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
        if (!isEditable) return;
        const activeElement = document.activeElement as HTMLElement;
        const isInInput = isEditableElement(activeElement);

        if (!isInInput && elementData.isFocus) {
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                if (tempX !== null && tempY !== null && handleDragStop) {
                    handleDragStop(elementData.id, tempX, tempY);
                    tempX = null;
                    tempY = null;
                }
            }
            setKeyDown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [elementData.isFocus]);

    const styleAbsolute = {
        width: `${scaleRatio * elementData.content.width}px`,
        height: `${scaleRatio * elementData.content.height}px`,
        position: "absolute" as const,
        left: `${scaleRatio * elementData.content.positionX}px`,
        top: `${scaleRatio * elementData.content.positionY}px`,
        zIndex: elementData.content.zIndex,
    };

    const styleRelative = {
        width: `${scaleRatio * elementData.content.width}px`,
        height: `${scaleRatio * elementData.content.height}px`,
        position: "relative" as const,
        zIndex: elementData.content.zIndex,
    };

    return (
        <BaseElement>
            <div
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                style={elementData.childrenLevel ? styleRelative : styleAbsolute}
                ref={elementRef}
            >
                {children}
                {isEditable && elementData.isFocus && <ElementResizable handleResize={handleResize!} elementData={elementData} />}
                {isEditable && elementData.isFocus && (
                    <div style={{ position: 'absolute', top: '-30px', left: '0', color: '#fff', backgroundColor: 'rgba(13,153,255)', padding: '2px 5px', fontSize: '14px' }}>
                        {Math.round(currentPos.x)}, {Math.round(currentPos.y)}
                    </div>
                )}
                {isEditable && elementData.isFocus && (
                    <div style={{ position: 'absolute', top: '-30px', right: '0', color: '#fff', backgroundColor: 'rgba(13,153,25)', padding: '2px 5px', fontSize: '14px' }}>
                        {elementData?.id.toString().substr(-4)}
                    </div>
                )}
            </div>
        </BaseElement>
    );
};

export type {BaseDragableElementProps, IntrinsicElementProps};
export default observer(BaseDragableElement);
