import styles from './sunoGen.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import MultimediaAPI from '@/api/multimedia';
import BoboMusicIcon from '@/assets/img/bobo_music.svg';
import { rewriteText, containsVariablePattern } from '@/utils/utils';
import MagicWand from './MagicWand';
import SunoGenLoading from '../SunoGenLoading/SunoGenLoading';
import YinFuIcon from '@/assets/img/yinfu.svg';
import { createSong, addSongToAlbum, getAlbum, createAlbum } from '@/api/playgroundMusic';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import mixpanel from 'mixpanel-browser';
import { cloneDeep } from 'lodash';
import service from '@/services/axios';
import SunoGenContent from '@/base/ElementData/SunoGenContent';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { lectureStore, LectureStore } from '@/store/lectureStore';
import { BlockTraceData } from '@/base/BlockData';
import LLMCallRequestData from '@/base/LLMCallRequestData';
import { requestLLMCallV2 } from '@/services/requestLLMCall';

interface SunoGenProps extends IntrinsicElementProps<SunoGenContent> {
    lectureStore: LectureStore;
}

const SunoGen: React.FC<SunoGenProps> = ({ 
	elementData, 
	isEditable, 
	handleFocusItem,
  	handleResize, 
	handleDragStop, 
	handleDelete, 
	lectureStore }) => {
  	const currentBlock = lectureStore?.learnedBlockList[lectureStore.currentBlockIndex];
  	const { userInfoStore, commonStatusStore } = useStores();
  	const [description, setDescription] = useState('');
  	//const [genre, setGenre] = useState(lectureStore ? lectureStore.getLectureVar("song_genre") : "");
  	const [songUrl, setSongUrl] = useState("");
 	const [apiStatus, setApiStatus] = useState(0); // 0: not started, 1: started, 2: completed
  	const [isMagicFetching, setIsMagicFetching] = useState(false);

  	const backgroundStyle = elementData?.content?.backgroundImage ? {
		backgroundImage: `url(${elementData.content.backgroundImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
  	} : {
    	backgroundColor: elementData.content.backgroundColor
  	};

	//更新后端数据blockTrace
	const updateBlockTrace = async (newBlockTraceData: BlockTraceData) => {
		try {
		await service.put('/block_trace', newBlockTraceData.toJSON());
		} catch (error) {
		console.log('error', error)
		}
	};

	const saveSong = async (name: string, 
						lyrics: string, 
						musicUrl: string, 
						coverUrl: string, 
						style: string) => {
		const albumResponse: any = await getAlbum(userInfoStore.userInfoData.id);
		if (albumResponse.status === 'error') {
		await createAlbum({
			id: 0,
			name: "string",
			author_id: userInfoStore.userInfoData.id,
			songs: [],
			update_time: 0
		});
		}
		const songData = {
			id: 0,
			name: name,
			author_id: userInfoStore.userInfoData.id,
			lyrics: lyrics,
			music_url: musicUrl,
			cover_url: coverUrl,
			tags: style,
			play_count: 0,
			like_count: 0,
			update_time: 0
		}
		createSong(songData).then((res: any) => {
		if (res.status === 'success') {
			lectureStore.setLectureVar("sono_song_id", res.data.id);
			const newBlockTraceData = cloneDeep(currentBlock);
			console.log('currentBlock -- createSong:', currentBlock);
			currentBlock.extras['sono_song_id'] = res.data.id;
			newBlockTraceData.extras['sono_song_id'] = res.data.id;
			updateBlockTrace(newBlockTraceData);

			addSongToAlbum(userInfoStore.userInfoData.id, res.data.id).then((res: any) => {
			if (res.status === 'success') {
				// setDescription('');
			} else {
				console.error('add song to album failed');
			}
			});
		} else {
			console.error('save song failed');
		}
		}).catch((error) => {
		console.error('save song error:', error);
		})
	}


	const pollForSongUrl = (songId: number) => {
		console.log('call pollForSongUrl:', songId);
		const api = new MultimediaAPI();
		const intervalId = setInterval(async () => {
		api.getSongById(songId).then((res: any) => {
			if (res && res[0].status === 'complete') {
			console.log('song url -- :', res[0].audio_url);
			setSongUrl(res[0].audio_url);
			saveSong(res[0].title, res[0].lyric.replace(/\[.*?\]/g, ' '), res[0].audio_url, res[0].image_url, res[0].tags);
			lectureStore.setLectureVar("sono_song", res[0].audio_url);
			lectureStore.setLectureVar("sono_song_title", res[0].title);
			lectureStore.setLectureVar("sono_song_lyrics", res[0].lyric);
			lectureStore.setLectureVar("sono_song_photo", res[0].image_url);
			console.log('var', "sono_song", lectureStore.getLectureVar("sono_song"));
			console.log('var', "sono_song_title", lectureStore.getLectureVar("sono_song_title"));
			console.log('var', "sono_song_lyrics", lectureStore.getLectureVar("sono_song_lyrics"));
			console.log('var', "sono_song_photo", lectureStore.getLectureVar("sono_song_photo"));
			const newBlockTraceData = cloneDeep(currentBlock);
			console.log('lectureStore', lectureStore);
			console.log('blockTraceData:', currentBlock);
			currentBlock.extras['sono_song'] = res[0].audio_url;
			currentBlock.extras['sono_song_title'] = res[0].title;
			currentBlock.extras['sono_song_lyrics'] = res[0].lyric;
			currentBlock.extras['sono_song_photo'] = res[0].image_url;
			newBlockTraceData.extras['sono_song'] = res[0].audio_url;
			newBlockTraceData.extras['sono_song_title'] = res[0].title;
			newBlockTraceData.extras['sono_song_lyrics'] = res[0].lyric;
			newBlockTraceData.extras['sono_song_photo'] = res[0].image_url;
			updateBlockTrace(newBlockTraceData);

			commonStatusStore.setGenSongFinished(true);
			commonStatusStore.setBtnLoading(false);
			clearInterval(intervalId);
			setApiStatus(2);
			} else {
			console.error('song url request error');
			}
		}).catch((error) => {
			console.error('getSongById:', error);
		});
		}, 3000); // 每3秒轮询一次
	};


	const handleGenerate = async () => {
		if (description.trim() === "") {
			message.warning('请输入对歌曲的描述~');
			return;
		}
		if (apiStatus === 1) {
			message.warning('正在生成中，请稍等');
			return;
		}
		if (import.meta.env.PROD) {
			mixpanel.track('generateSong', {
				'description': description
			});
		}
		setApiStatus(1);
		commonStatusStore.setBtnLoading(true);
		elementData.content.description = description;

		const api = new MultimediaAPI();
		const requestData = {
			request_id: "string",
			title: "",
			lyrics: "",
			genre: "",
			description: description,
		};

		setSongUrl("");

		api.sonoGenSong(requestData).then((res: any) => {
		if (res) {
			console.log('song id :', res[0].id);
			// 我们用了一个第三方的suno api proxy, response就是一个数组
			pollForSongUrl(res[0].id);
		} else {
			console.error('song request -- res is null');
		}
		}).catch((error) => {
			console.error('genSuno:', error);
		});
	};

	const handleMagicGen = (prompt: string, setHandler: any, lectureVarName: string) => {
		if (isMagicFetching) {
			return;
		}
		setIsMagicFetching(true);
		//需要做rewrite
		if (containsVariablePattern(prompt)) {
			prompt = rewriteText(prompt);
			console.log('prompt after rewrite:', prompt);
		}
		
		const requestParams = LLMCallRequestData.genLLMCallParams(
			prompt,
			userInfoStore.userInfoData.id,
			userInfoStore.userInfoData.name,
			false);

		requestLLMCallV2(requestParams).then((res) => {
		if (res) {
			console.log('res.content :', res.content);
			setHandler(res.content);
			lectureStore.setLectureVar(lectureVarName, res.content);
		} else {
			console.error('res is null');
		}
		}).catch((error) => {
			console.error('Error:', error);
			setIsMagicFetching(false);
		})
		setIsMagicFetching(false);

	};
	// 生成description
	useEffect(() => {
		if (lectureStore) {
		const desc = lectureStore.getLectureVar("song_description") ?
			lectureStore.getLectureVar("song_description") : "";
		console.log('song_description:', desc);
		setDescription(desc);
		}
	}, []);


	return (<BaseDragableElement elementData={elementData}
			isEditable={isEditable}
			handleFocusItem={handleFocusItem}
			handleResize={handleResize}
			handleDragStop={handleDragStop}
			handleDelete={handleDelete}>
		<div
			style={{ ...commonStyle }}
			onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
			className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
		>
		<div className={styles.sunoBox} style={{ ...backgroundStyle }}>
			<div className={styles.headerBox}>
			<div className={styles.sunoIcon}>
				<img src={BoboMusicIcon} />
			</div>
			<div className={styles.headerTitle}>Suno音乐制作</div>
			</div>
			<div className={styles.sunoBoxMain}>
			<textarea
				className={styles.input}
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				onKeyDown={() => { }}
				placeholder='请输入歌曲的描述~~'
			/>
			<div className={styles.magicGenBtn}>
				<MagicWand onMagicGenerate={() => {
				handleMagicGen(elementData.content.genrePrompt, setDescription, 'song_description');
				}} />
			</div>
			</div>
			<Button
				className={`${apiStatus === 2 ? styles.disableGenerateButton : styles.sunoGenerateButton}`}
				onClick={handleGenerate}
				loading={apiStatus === 1}
				icon={<img src={YinFuIcon} />}
				disabled={apiStatus === 2}
			>
				{["开始创作", "创作中", "创作完成"][apiStatus]}
			</Button>
			<SunoGenLoading apiStatus={apiStatus} />
		</div>
		</div>
	</BaseDragableElement>)
	};

export default observer(SunoGen);
