import { ReactNode, FC } from 'react';

interface BaseElementProps {
    children: ReactNode;
}

const BaseElement: FC<BaseElementProps> = ({ children }) => {
    return <>{children}</>;
};

export default BaseElement;