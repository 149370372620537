
import PropTypes from 'prop-types';
import {Button, Select, Switch } from 'antd';
import styles from './codeRegionModifier.module.less'
import { ElementData } from '@/base/ElementData/ElementData';
import CodeRegionContent from '@/base/ElementData/CodeRegionContent';

interface CodeRegionModifierProps {
    focusedItem: ElementData<CodeRegionContent>;
    onInputChange: (id: number, key: string,value: any) => void;
    changeZIndex: (id: number, type: 'front' | 'back') => void;
}
;
const CodeRegionModifier: React.FC<CodeRegionModifierProps>= ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {

    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>Source Code</div>
                <textarea
                    value={focusedItem.content.code}
                    onChange={e => onInputChange(focusedItem.id, 'code', e.target.value)}
                    className={styles.textarea} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>组件模式</div>
                <Select
                    value={focusedItem.content?.elementMode}
                    onChange={(value) => { onInputChange(focusedItem.id, "elementMode", value) }}
                    style={{ width: 120 }}
                >
                    <Select.Option value="normal">正常模式</Select.Option>
                    <Select.Option value="simple">精简模式</Select.Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示game按钮</div>
                <Switch
                    value={focusedItem.content?.showGameBtn}
                    onChange={(value) => { onInputChange(focusedItem.id, "showGameBtn", value) }}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示code按钮</div>
                <Switch
                    value={focusedItem.content?.showCodeBtn}
                    onChange={(value) => { onInputChange(focusedItem.id, "showCodeBtn", value) }}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示分享按钮</div>
                <Switch
                    value={focusedItem.content?.showShareBtn}
                    onChange={(value) => { onInputChange(focusedItem.id, "showShareBtn", value) }}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示下载按钮</div>
                <Switch
                    value={focusedItem.content?.showDownloadBtn}
                    onChange={(value) => { onInputChange(focusedItem.id, "showDownloadBtn", value) }}
                />
            </div>
            <div className={styles.modifyItem}>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
        </div>
    )
};

export default CodeRegionModifier;