import { useState, useEffect, useRef } from 'react';
import styles from './lineConnectorRegion.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import AdvancedButtonRegion from '../ButtonRegion/AdvancedButtonRegion';
import service from '@/services/axios';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { cloneDeep } from 'lodash';

import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { LineConnectorRegionContent, FirstGroupItem, SecondGroupItem } from '@/base/ElementData/LineConnectorRegionContent';
import { BlockTraceData } from '@/base/BlockData';
import CancelIcon from '@/assets/img/cuowu.svg';
import CorrectPromptSound from '@/assets/audio/correct_prompt_sound.mp3';
import WrongPromptSound from '@/assets/audio/wrong_prompt_sound.mp3';

interface LineConnectorRegionProps extends IntrinsicElementProps<LineConnectorRegionContent> {
    blockTraceData: BlockTraceData;
}

const LineConnectorRegion: React.FC<LineConnectorRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    blockTraceData,
}) => {
    const { lectureStore, userInfoStore } = useStores();
    const [connectionsResult, setConnectionsResult] = useState(elementData.content.connectionsResult || []); // 存储连接结果
    const [selectedItem, setSelectedItem] = useState<FirstGroupItem | SecondGroupItem | null>(null);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | null>(null);
    const isTeacher = userInfoStore.isTeacherView();
    const [isRefsReady, setIsRefsReady] = useState(false); // 用于检查是否所有的ref都已经准备好
    const { connectionMode } = elementData.content;

    const firstGroupRefs = useRef<(HTMLDivElement | null)[]>([]);
    const secondGroupRefs = useRef<(HTMLDivElement | null)[]>([]);

    // 计算所需连接的总数  
    const connectionsCount = Math.min(
        elementData.content.firstGroup.length,
        elementData.content.secondGroup.length
    );

    const updateConnectionsAndNotifyStore = (updatedConnections: Record<string, string>[]) => {
        if (!isTeacher) {
            const newContent = new LineConnectorRegionContent({
                ...elementData.content,
                connectionsResult: updatedConnections,
            });
            lectureStore.updateElement(blockTraceData.id, elementData.id, newContent);

            const newBlockTraceData = cloneDeep(blockTraceData);
            const currentElementData = newBlockTraceData.traceInfo.find(item => item.id === elementData.id);
            if (currentElementData && "connectionsResult" in currentElementData.content) {
                currentElementData.content.connectionsResult = updatedConnections;
            }
            service.put('/block_trace', newBlockTraceData);
        }
    };

    const handleGroupItemClick = async (item: FirstGroupItem | SecondGroupItem, groupIndex: number) => {
        const isAlreadyConnected = connectionsResult.some(conn =>
            conn.from === item.value || conn.to === item.value
        );

        if (!isAlreadyConnected || connectionMode === 'one_to_many') {
            if (selectedGroupIndex === null) {
                // 第一次选择  
                setSelectedItem(item);
                setSelectedGroupIndex(groupIndex);
            } else if (selectedGroupIndex !== groupIndex) {
                // 如果选择的是第二个组并且它属于不同的组  
                const from = selectedGroupIndex === 1 && selectedItem ? selectedItem.value : item.value;
                const to = selectedGroupIndex === 1 ? item.value : selectedItem ? selectedItem.value : '';

                let secondGroupItem = elementData.content.secondGroup.find(i => i.value === to);
                if (secondGroupItem) {
                    if (secondGroupItem.correctAnswer !== "") {
                        if (secondGroupItem.correctAnswer === from) {
                            const audio = new Audio(CorrectPromptSound);
                            audio.play();
                        } else {
                            const audio = new Audio(WrongPromptSound);
                            audio.play();
                            return;
                        }
                    }
                }
                // 添加连接  
                const newConnection = { from, to };
                //如果结果中已经存在这个连接，就不再添加
                if (connectionsResult.some(conn => conn.from === newConnection.from && conn.to === newConnection.to)) {
                    setSelectedItem(null);
                    setSelectedGroupIndex(null);
                    return;
                }
                const updatedConnections = [...connectionsResult, newConnection];
                setConnectionsResult(updatedConnections);

                updateConnectionsAndNotifyStore(updatedConnections);

                setSelectedItem(null); // 连接后重置选择  
                setSelectedGroupIndex(null); // 重置组索引   
            } else {
                // 再次点击同一组项目，重置选择  
                setSelectedItem(item);
            }
        }
    };

    //取消连接
    const handleCancelConnection = (connection: Record<string, string>) => {
        const backUpConnections = [...connectionsResult];
        const connectionIndex = backUpConnections.findIndex(conn =>
            conn.from === connection.from && conn.to === connection.to
        );
        const updatedConnections = backUpConnections.filter((_, index) => index !== connectionIndex);
        setConnectionsResult(updatedConnections);
        updateConnectionsAndNotifyStore(updatedConnections);
    };

    useEffect(() => {
        firstGroupRefs.current = firstGroupRefs.current.slice(0, elementData.content.firstGroup.length);
        secondGroupRefs.current = secondGroupRefs.current.slice(0, elementData.content.secondGroup.length);
    }, [elementData]);

    // 确保 refs 准备好  
    useEffect(() => {
        setIsRefsReady(
            firstGroupRefs.current.length === elementData.content.firstGroup.length &&
            secondGroupRefs.current.length === elementData.content.secondGroup.length
        );
    }, [firstGroupRefs.current.length, secondGroupRefs.current.length]);

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div
                    className={styles.lineConnectorBox}
                    style={{
                        width: "100%",
                        height: "100%",
                        position: 'relative',
                    }}
                >
                    <div className={styles.groupBox}>
                        {elementData.content.firstGroup.map((item, index) => {
                            const isConnected = connectionsResult.some(conn => conn.from === item.value || conn.to === item.value);

                            return (
                                <div
                                    key={index}
                                    className={styles.groupItemBg}
                                    onClick={() => handleGroupItemClick(item, 1)}
                                    ref={el => firstGroupRefs.current[index] = el}
                                    style={{
                                        pointerEvents: isConnected && connectionMode !== 'one_to_many' ? 'none' : 'auto',
                                        border: selectedItem?.value === item.value ? '3px solid #a3c001' : '3px solid transparent',
                                    }}
                                >
                                    <div className={styles.groupItem}>
                                        <AdvancedButtonRegion
                                            elementData={item.btnObj!}
                                            isEditable={isEditable}
                                            handleFocusItem={handleFocusItem}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.groupBox}>
                        {elementData.content.secondGroup.map((item, index) => {
                            const isConnected = connectionsResult.some(conn => conn.from === item.value || conn.to === item.value);

                            return (
                                <div
                                    key={index}
                                    className={styles.groupItemBg}
                                    onClick={() => handleGroupItemClick(item, 2)}
                                    ref={el => secondGroupRefs.current[index] = el}
                                    style={{
                                        pointerEvents: isConnected && connectionMode !== 'one_to_many' ? 'none' : 'auto',
                                        border: selectedItem?.value === item.value ? '3px solid #a3c001' : '3px solid transparent',
                                    }}
                                >
                                    <div className={styles.groupItem}>
                                        <AdvancedButtonRegion
                                            elementData={item.btnObj!}
                                            isEditable={isEditable}
                                            handleFocusItem={handleFocusItem}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* 绘制连接线的逻辑 */}
                    {isRefsReady && connectionsResult.map((connection, index) => {
                        const fromIndex = elementData.content.firstGroup.findIndex(i => i.value === connection.from);
                        const toIndex = elementData.content.secondGroup.findIndex(i => i.value === connection.to);

                        if (fromIndex !== -1 && toIndex !== -1) {
                            const fromEl = firstGroupRefs.current[fromIndex];
                            const toEl = secondGroupRefs.current[toIndex];

                            if (fromEl && toEl) {
                                const fromRect = fromEl.getBoundingClientRect();
                                const toRect = toEl.getBoundingClientRect();
                                const parentRect = fromEl.parentElement?.getBoundingClientRect();
                                if (!parentRect) return null;

                                const startX = fromRect.left - parentRect.left + fromRect.width;
                                const startY = fromRect.bottom - parentRect.top - fromRect.height / 2;
                                const endX = toRect.left - parentRect.left;
                                const endY = toRect.top - parentRect.top + toRect.height / 2;

                                const lineLength = Math.hypot(endX - startX, endY - startY);
                                const angle = Math.atan2(endY - startY, endX - startX) * 180 / Math.PI;
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            position: 'absolute',
                                            left: startX,
                                            top: startY,
                                            width: lineLength,
                                            height: '3px',
                                            borderRadius: '3px',
                                            backgroundColor: 'gray',
                                            transform: `rotate(${angle}deg)`,
                                            transformOrigin: '0 0',
                                        }}
                                        className={styles.lineConnector}
                                    >
                                        <div
                                            className={styles.revokeIcon}
                                            onClick={() => { handleCancelConnection(connection); }}
                                        >
                                            <img src={CancelIcon} alt="cancel" />
                                        </div>
                                    </div>
                                );
                            }
                        }
                        return null;
                    })}
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default observer(LineConnectorRegion);