import styles from './showCodeRegion.module.less';  
import { useRef, useState, useEffect } from 'react';  
import commonStyle from '../common/ComponentCommons';  
import BaseDragableElement from '../common/BaseDragableElement';  
import { Button } from 'antd';  
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';  
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';  
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import ShowCodeRegionContent from '@/base/ElementData/ShowCodeRegionContent';


const ShowCodeRegion: React.FC<IntrinsicElementProps<ShowCodeRegionContent>> = ({ 
    elementData, 
    isEditable, 
    handleFocusItem,
    handleResize,  
    handleDragStop, 
    handleDelete }) => {  
    const { code, language } = elementData.content;  
    const [activeRegion, setActiveRegion] = useState('');  
    const codeRegionRef = useRef<HTMLDivElement>(null);  

    const splitCodeByTag = (code: string, tag: string) => {  
        const regex = new RegExp(`(<${tag}[^>]*>.*?</${tag}>)`, 'gs');  
        const parts = code.split(regex);  
        return parts;  
    }  

    const RenderCode = () => {  
        const splitCode = (code: string) => {  
            switch (activeRegion) {  
                case 'style':  
                    return splitCodeByTag(code, 'style');  
                case 'script':  
                    return splitCodeByTag(code, 'script');  
                default:  
                    return [code];  
            }  
        };  

        const finalCodeList = splitCode(code);  

        return (  
            finalCodeList.map((part, idx) => {  
                if (activeRegion && part.startsWith(`<${activeRegion}`)) {  
                    return (  
                        <div className={styles.mark} key={idx}>  
                            <SyntaxHighlighter language={language} style={tomorrowNight}>  
                                {part}  
                            </SyntaxHighlighter>  
                        </div>  
                    );  
                }  
                return (  
                    <SyntaxHighlighter language={language} style={tomorrowNight} key={idx}>  
                        {part}  
                    </SyntaxHighlighter>  
                );  
            })  
        );  
    };  

    useEffect(() => {  
        if (activeRegion && codeRegionRef.current) {  
            const firstMarkElement = codeRegionRef.current.querySelector(`.${styles.mark}`);  
            if (firstMarkElement) {  
                firstMarkElement.scrollIntoView({ behavior: 'smooth', block: 'start' });  
            }  
        }  
    }, [activeRegion]);  

    return (  
        <BaseDragableElement  
            elementData={elementData}  
            isEditable={isEditable}  
            handleFocusItem={handleFocusItem}  
            handleResize={handleResize}  
            handleDragStop={handleDragStop}  
            handleDelete={handleDelete}  
        >  
            <div  
                style={{ ...commonStyle }}  
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}  
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}  
            >  
                <div className={styles.codeRegionBox}>  
                    <div className={styles.operation}>  
                        <Button onClick={() => setActiveRegion('style')}>css区域</Button>  
                        <Button onClick={() => setActiveRegion('script')}>js区域</Button>  
                        <Button onClick={() => setActiveRegion('')}>重置</Button>
                    </div>  
                    <div className={styles.code} ref={codeRegionRef}>  
                        <RenderCode />  
                    </div>  
                </div>  
            </div>  
        </BaseDragableElement>  
    );  
};  
 
export default ShowCodeRegion;