import PropTypes from 'prop-types';
import { InputNumber, Button, Input, Select } from 'antd';
import styles from './showCodeModifier.module.less';
import ShowCodeRegionContent from '@/base/ElementData/ShowCodeRegionContent';
import { ElementData } from '@/base/ElementData/ElementData';

const { TextArea } = Input;

interface ShowCodeModifierProps {
    focusedItem: ElementData<ShowCodeRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};


const ShowCodeModifier: React.FC<ShowCodeModifierProps>= ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {
    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>代码</div>
                <TextArea
                    value={focusedItem.content.code}
                    onChange={e => onInputChange(focusedItem.id, 'code', e.target.value)}
                    autoSize={{ minRows: 6, maxRows: 20 }}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>语言</div>
                <Select
                    value={focusedItem.content.language}
                    onChange={value => onInputChange(focusedItem.id, 'language', value)}
                    className={styles.languageSelect}
                >
                    <Select.Option value="html">html</Select.Option>
                    <Select.Option value="python">python</Select.Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
        </div>
    )
};

export default ShowCodeModifier;
