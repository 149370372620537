import { useState } from 'react';
import styles from './sunoGenLoading.module.less';
import AudioPlayer from '@/components/AudioPlayer/AudioPlayer';
import PropTypes from 'prop-types';

const MusicStyleItem = ({ musicStyleItem }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    return (
        <div className={styles.musicStyleItem}>
            <div className={styles.info}>
                <div className={styles.icon}>
                    <img
                        src={musicStyleItem.icon}
                        alt="icon"
                        style={{ animationDelay: musicStyleItem.delay }}
                    />
                </div>
                <div className={styles.text}>
                    <div className={styles.chinese}>{musicStyleItem.chinese}</div>
                    <div className={styles.english}>{musicStyleItem.english}</div>
                </div>
            </div>
            <div className={styles.play}>
                <AudioPlayer
                    audioSrc={musicStyleItem.audioSrc}
                    playerType="small"
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    currentAudioEleId={musicStyleItem.audioSrc}
                />
            </div>
        </div>
    )
}

export default MusicStyleItem;

MusicStyleItem.propTypes = {
    musicStyleItem: PropTypes.object.isRequired,
}