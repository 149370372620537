import { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Select, Switch } from 'antd';
import styles from './qRCodeRegionModifier.module.less';
import { ElementData } from '@/base/ElementData/ElementData';
import QRCodeRegionContent from '@/base/ElementData/QRCodeRegionContent';

const { TextArea } = Input;

interface QRCodeRegionModifierProps {
    focusedItem: ElementData<QRCodeRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, type: string) => void;
};


const CodeRegionModifier: React.FC<QRCodeRegionModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {

    //修改focusedItem.content.payload,这个payload是一个对象，包含了二维码的信息
    const handlePayloadChange = (key: string, value: any) => {
        const newPayload = { ...focusedItem.content.payload, [key]: value };
        onInputChange(focusedItem.id, 'payload', newPayload);
    }

    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽</div>
                <Input
                    type="number"
                    value={focusedItem.content.width}
                    onChange={e => onInputChange(focusedItem.id, 'width', e.target.value)}
                />
                <div className={styles.modifyItemTitle}>高</div>
                <Input
                    type="number"
                    value={focusedItem.content.height}
                    onChange={e => onInputChange(focusedItem.id, 'height', e.target.value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>生成二维码方式</div>
                <Select
                    value={focusedItem.content.qrCodeType}
                    onChange={value => onInputChange(focusedItem.id, 'qrCodeType', value)}
                >
                    <Select.Option value="preset">预设二维码链接</Select.Option>
                    <Select.Option value="generate">实时生成链接</Select.Option>
                </Select>
            </div>
            {focusedItem.content.qrCodeType === 'preset' && (
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>二维码链接</div>
                    <Input
                        type="text"
                        value={focusedItem.content.src}
                        onChange={e => onInputChange(focusedItem.id, 'src', e.target.value)}
                    />
                </div>
            )}
            {
                focusedItem.content.qrCodeType === 'generate' && (
                    <>
                        <div className={styles.modifyItem}>
                            <div className={styles.modifyItemTitle}>分享类型</div>
                            <Select
                                value={focusedItem.content.shareType}
                                onChange={value => onInputChange(focusedItem.id, 'shareType', value)}
                            >
                                <Select.Option value="music">音乐</Select.Option>
                                <Select.Option value="code">代码</Select.Option>
                                <Select.Option value="cyberAvatar">赛博头像</Select.Option>
                                <Select.Option value="basicPost">通用图文</Select.Option>
                            </Select>
                        </div>
                        {
                            focusedItem.content.shareType === 'code' && (
                                <>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>代码</div>
                                        <TextArea
                                            value={focusedItem.content.payload?.srcCode}
                                            onChange={e => handlePayloadChange('srcCode', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>语言</div>
                                        <Select
                                            value={focusedItem.content.payload?.language}
                                            onChange={value => handlePayloadChange('language', value)}
                                        >
                                            <Select.Option value="html">HTML</Select.Option>
                                            <Select.Option value="python">Python</Select.Option>
                                        </Select>
                                    </div>
                                </>
                            )
                        }
                        {
                            focusedItem.content.shareType === 'cyberAvatar' && (
                                <>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>头像url</div>
                                        <Input
                                            type="text"
                                            value={focusedItem.content.payload?.avatar_url}
                                            onChange={e => handlePayloadChange('avatar_url', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>name</div>
                                        <Input
                                            type="text"
                                            value={focusedItem.content.payload?.name}
                                            onChange={e => handlePayloadChange('name', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>tagline</div>
                                        <Input
                                            type="text"
                                            value={focusedItem.content.payload?.tagline}
                                            onChange={e => handlePayloadChange('tagline', e.target.value)}
                                        />
                                    </div>
                                </>
                            )
                        }
                        {
                            focusedItem.content.shareType === 'basicPost' && (
                                <>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>文字 Title</div>
                                        <TextArea
                                            value={focusedItem.content.payload?.title}
                                            onChange={e => handlePayloadChange('title', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>文字 body</div>
                                        <TextArea
                                            value={focusedItem.content.payload?.body}
                                            onChange={e => handlePayloadChange('body', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>图像 url</div>
                                        <TextArea
                                            value={focusedItem.content.payload?.imgUrl}
                                            onChange={e => handlePayloadChange('imgUrl', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.modifyItem}>
                                        <div className={styles.modifyItemTitle}>背景样式</div>
                                        <Select
                                            value={focusedItem.content.payload?.backgroundStyle}
                                            onChange={value => handlePayloadChange('backgroundStyle', value)}
                                            className={styles.largeSelect}
                                        >
                                            <Select.Option value="blue">蓝色</Select.Option>
                                            <Select.Option value="orange">橙色</Select.Option>
                                        </Select>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }
            <div className={styles.modifyItem}>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
        </div>
    )
};

export default CodeRegionModifier;