import {ElementContent} from './ElementData';

class RatingRegionContent extends ElementContent {
    borderRadius: number;
    starNum: number;
    rating: number;
    title: string;
    titleFontSize: number;
    titleColor: string;
    ratingDescFontSize: number;
    ratingDescHeight: number;
    ratingDescBorderRadius: number;
    ratingDescColor: string;
    ratingDescBackgroundColor: string;
    ratingDescriptions: string[];
    btnWidth: number;
    btnHeight: number;
    btnFontSize: number;
    btnBorderRadius: number;
    btnBackgroundColor: string;
    btnFontColor: string;
    btnBorderWidth: number;
    btnBorderColor: string;
    btnShadowX: number;
    btnShadowY: number;
    btnShadowBlur: number;
    btnShadowSpread: number;
    btnShadowColor: string;
    showSubmit: boolean;
    backgroundColor: string;
    backgroundImage: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 700,
        height = 40,
        borderRadius = 10,
        starNum = 5,
        rating = 0,
        title = "请对本节课程做出评价吧~~",
        titleFontSize = 18,
        titleColor = "#222222",
        ratingDescFontSize = 16,
        ratingDescHeight = 48,
        ratingDescBorderRadius = 90,
        ratingDescColor = "#FF7511",
        ratingDescBackgroundColor = "#E9EEF2",
        ratingDescriptions = [
            "非常不满意",
            "不满意",
            "一般",
            "~比较满意~",
            "~满意~",
            "~非常满意~"
        ],
        btnWidth = 228,
        btnHeight = 64,
        btnFontSize = 18,
        btnBorderRadius = 15,
        btnBackgroundColor = "#1890ff",
        btnFontColor = "#ffffff",
        btnBorderWidth = 0,
        btnBorderColor = "#000000",
        btnShadowX = 0,
        btnShadowY = 0,
        btnShadowBlur = 0,
        btnShadowSpread = 0,
        btnShadowColor = "#000000",
        showSubmit = true,
        backgroundColor = "#ffffff",
        backgroundImage = ""
    }: Partial<RatingRegionContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'borderRadius', 'starNum', 'rating', 'title', 'titleFontSize', 'titleColor',
            'ratingDescFontSize', 'ratingDescHeight', 'ratingDescBorderRadius', 'ratingDescColor',
            'ratingDescBackgroundColor', 'ratingDescriptions', 'btnWidth', 'btnHeight', 'btnFontSize',
            'btnBorderRadius', 'btnBackgroundColor', 'btnFontColor', 'btnBorderWidth', 'btnBorderColor',
            'btnShadowX', 'btnShadowY', 'btnShadowBlur', 'btnShadowSpread', 'btnShadowColor',
            'showSubmit', 'backgroundColor', 'backgroundImage'];
        this.borderRadius = borderRadius;
        this.starNum = starNum;
        this.rating = rating;
        this.title = title;
        this.titleFontSize = titleFontSize;
        this.titleColor = titleColor;
        this.ratingDescFontSize = ratingDescFontSize;
        this.ratingDescHeight = ratingDescHeight;
        this.ratingDescBorderRadius = ratingDescBorderRadius;
        this.ratingDescColor = ratingDescColor;
        this.ratingDescBackgroundColor = ratingDescBackgroundColor;
        this.ratingDescriptions = ratingDescriptions;
        this.btnWidth = btnWidth;
        this.btnHeight = btnHeight;
        this.btnFontSize = btnFontSize;
        this.btnBorderRadius = btnBorderRadius;
        this.btnBackgroundColor = btnBackgroundColor;
        this.btnFontColor = btnFontColor;
        this.btnBorderWidth = btnBorderWidth;
        this.btnBorderColor = btnBorderColor;
        this.btnShadowX = btnShadowX;
        this.btnShadowY = btnShadowY;
        this.btnShadowBlur = btnShadowBlur;
        this.btnShadowSpread = btnShadowSpread;
        this.btnShadowColor = btnShadowColor;
        this.showSubmit = showSubmit;
        this.backgroundColor = backgroundColor;
        this.backgroundImage = backgroundImage;
    }
};

export default RatingRegionContent;