import styles from './progressBar.module.less';
import { useState} from 'react';
import ProgressSegmentData from '@/base/ProgressSegmentData';

interface ProgressBarProps {
    segmentData: ProgressSegmentData;
};

const ProgressSegment: React.FC<ProgressBarProps> = ({ segmentData}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className={styles.progressSegContainer}
            style={{ width: `${segmentData.lengthPercent}%`, marginRight: '5px' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div
                className={styles.progressSegFill}
                style={{ width: `${segmentData.progress}%` }}
            >
            </div>
            { isHovered && 
                <div className={styles.progressSegTooltip} >
                    {segmentData.desc}
                </div>
            }
        </div>
    );
};

  
export default ProgressSegment;