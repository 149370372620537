import { InputNumber, Button } from 'antd';
import MediaFileSelectorModifier from '../MediaFileSelectorModifier/MediaFileSelectorModifier';
import styles from '../../elementModifier.module.less';
import URLModifier from '../URLModifier/URLModifier';
import { ElementData } from '@/base/ElementData/ElementData';
import ImageRegionContent from '@/base/ElementData/ImageRegionContent';

interface ImageRegionModifierProps {
    focusedItem: ElementData<ImageRegionContent>;
    onInputChange: (id: number, key: string,  value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};

const ImageRegionModifier: React.FC<ImageRegionModifierProps>= ({
    focusedItem,
    onInputChange,
    changeZIndex,
}) => {

    return (
        <div>
            <MediaFileSelectorModifier
                mediaType={'images'}
                afterSelectionCallback={url => onInputChange(focusedItem.id, 'src', url)}
                btnText={'更换图片'}
            />
            <URLModifier
                urlTitle='图片url'
                value={focusedItem.content.src}
                changeValue={value => onInputChange(focusedItem.id, 'src', value)}
            />
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>透明度（范围0-1）</div>
                <InputNumber
                    value={focusedItem.content.alpha}
                    onChange={value => onInputChange(focusedItem.id, 'alpha', value)}
                    className={styles.opacityInput}
                    //范围是0-1
                    min={0}
                    max={1}
                    step={0.1}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>背景颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.color}
                    onChange={e => onInputChange(focusedItem.id, 'color', e.target.value)}
                    className={styles.colorBoard}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>左上圆角</div>
                <InputNumber
                    value={focusedItem.content.radiusTL}
                    onChange={value => onInputChange(focusedItem.id, 'radiusTL', value)}
                    className={styles.radiusInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>右上圆角</div>
                <InputNumber
                    value={focusedItem.content.radiusTR}
                    onChange={value => onInputChange(focusedItem.id, 'radiusTR', value)}
                    className={styles.radiusInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>左下圆角</div>
                <InputNumber
                    value={focusedItem.content.radiusBL}
                    onChange={value => onInputChange(focusedItem.id, 'radiusBL', value)}
                    className={styles.radiusInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>右下圆角</div>
                <InputNumber
                    value={focusedItem.content.radiusBR}
                    onChange={value => onInputChange(focusedItem.id, 'radiusBR', value)}
                    className={styles.radiusInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>图片边框宽度</div>
                <InputNumber
                    value={focusedItem.content.borderWidth}
                    onChange={value => onInputChange(focusedItem.id, 'borderWidth', value)}
                    className={styles.borderWidthInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>图片边框颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.borderColor}
                    onChange={e => onInputChange(focusedItem.id, 'borderColor', e.target.value)}
                    className={styles.colorBoard}
                />
            </div>
            {/* 添加阴影设置 */}
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>阴影水平偏移(px)</div>
                <InputNumber
                    value={focusedItem.content.shadowX}
                    onChange={value => onInputChange(focusedItem.id, 'shadowX', value)}
                    className={styles.shadowInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>阴影垂直偏移(px)</div>
                <InputNumber
                    value={focusedItem.content.shadowY}
                    onChange={value => onInputChange(focusedItem.id, 'shadowY', value)}
                    className={styles.shadowInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>阴影模糊半径(px)</div>
                <InputNumber
                    value={focusedItem.content.shadowBlur}
                    onChange={value => onInputChange(focusedItem.id, 'shadowBlur', value)}
                    className={styles.shadowInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>阴影扩散半径(px)</div>
                <InputNumber
                    value={focusedItem.content.shadowSpread}
                    onChange={value => onInputChange(focusedItem.id, 'shadowSpread', value)}
                    className={styles.shadowInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>阴影颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.shadowColor}
                    onChange={e => onInputChange(focusedItem.id, 'shadowColor', e.target.value)}
                    className={styles.colorInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>

        </div>
    )
};

export default ImageRegionModifier;