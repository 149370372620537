import { makeObservable, observable, action } from "mobx";

class AudioStore {
    // -1 means not set
    currentAudioEleId: number = -1;

    constructor() {
        makeObservable(this, {
            currentAudioEleId: observable,
            setCurrentAudioEleId: action,
        });
    }

    setCurrentAudioEleId(elementId: number) {
        this.currentAudioEleId = elementId;
    }
}

const audioStore = new AudioStore();
export { audioStore, AudioStore };
