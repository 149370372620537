import service from '@/services/axios';
import UserInfoData from '@/base/UserInfoData';
import UserAssetData from '@/base/UserAssetData';

class UserManagementAPI {
    async generateNickName(): Promise<any> {
        try {
            const response = await service.get('/nickname');
            return response;
        } catch (error) {
            console.error('Error generating nickname:', error);
            throw error;
        }
    }

    async updateUser(data: UserInfoData): Promise<any> {
        try {
            const response = await service.put('/user', data);
            return response;
        } catch (error) {
            console.error('Error updating user:', error);
            throw error;
        }
    }

    async updateUserIncremental(data: UserInfoData): Promise<any> {
        try {
            const response = await service.put('/user_incremental', data);
            return response;
        } catch (error) {
            console.error('Error updating user incrementally:', error);
            throw error;
        }
    }

    async getUserInfo(userId: number): Promise<any> {
        try {
            const response = await service.get('/user/' + userId);
            return response;
        } catch (error) {
            console.error('Error getting user info:', error);
            throw error;
        }
    }

    async getUserAssets(userId: number): Promise<any> {
        try {
            const response = await service.get('/user_assets/' + userId);
            return response;
        } catch (error) {
            console.error('Error getting user assets:', error);
            throw error;
        }
    }

    async createUserAssets(data: UserAssetData): Promise<any> {
        try {
            const response = await service.post('/user_assets', data);
            return response;
        } catch (error) {
            console.error('Error creating user assets:', error);
            throw error;
        }
    }

    async UpdateUserAssetsExpress(data: UserAssetData): Promise<any> {
        try {
            const response = await service.put('/user_assets_express', data);
            return response;
        } catch (error) {
            console.error('Error updating user assets express:', error);
            throw error;
        }
    }
}

export default UserManagementAPI;
