import { ElementContent } from './ElementData';

class TextRegionContent extends ElementContent {
    font: string;
    size: number;
    color: string;
    text: string;
    fontWeight: number;
    lineHeight: number;
    borderWidth: number;
    borderColor: string;
    borderRadius: number;
    textAlign: string;
    verticalAlign: string;
    paddingLeft: number;
    paddingRight: number;
    paddingTop: number;
    paddingBottom: number;
    audioUrl: string;
    showAudioIcon: boolean;
    audioIconColor: string;
    autoPlay: boolean;
    showText: boolean;
    zIndex: number;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 200,
        height = 100,
        font = "楷体",
        size = 18,
        color = "#000000",
        text = "文本内容",
        fontWeight = 400,
        lineHeight = 1.2,
        borderWidth = 0,
        borderColor = "#000000",
        borderRadius = 0,
        textAlign = "left",
        verticalAlign = "flex-start",
        paddingLeft = 0,
        paddingRight = 0,
        paddingTop = 0,
        paddingBottom = 0,
        audioUrl = "",
        showAudioIcon = false,
        audioIconColor = "#1195db",
        autoPlay = false,
        showText = true,
        zIndex = 0
    }: Partial<TextRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'font', 'size', 'color', 'text', 'fontWeight', 'lineHeight', 'borderWidth', 'borderColor',
            'borderRadius', 'textAlign', 'verticalAlign', 'paddingLeft', 'paddingRight', 'paddingTop',
            'paddingBottom', 'audioUrl', 'showAudioIcon', 'audioIconColor', 'autoPlay', 'showText', 'zIndex'];
        this.font = font;
        this.size = size;
        this.color = color;
        this.text = text;
        this.fontWeight = fontWeight;
        this.lineHeight = lineHeight;
        this.borderWidth = borderWidth;
        this.borderColor = borderColor;
        this.borderRadius = borderRadius;
        this.textAlign = textAlign;
        this.verticalAlign = verticalAlign;
        this.paddingLeft = paddingLeft;
        this.paddingRight = paddingRight;
        this.paddingTop = paddingTop;
        this.paddingBottom = paddingBottom;
        this.audioUrl = audioUrl;
        this.showAudioIcon = showAudioIcon;
        this.audioIconColor = audioIconColor;
        this.autoPlay = autoPlay;
        this.showText = showText;
        this.zIndex = zIndex;
    }
};

export default TextRegionContent;