import { makeAutoObservable } from "mobx";

class UserInfoData {
    id: number;
    userRole: number; // 1 teacher, 2 student
    userView: string;
    name: string;
    phone: string;
    wechatID: string;
    avatarUrl: string;
    tagLine: string;
    age: number;
    gender: string;

    constructor({
            id = 0,
            userRole = 2,
            userView = 'student',
            name = '',
            phone = '',
            wechatID = '',
            avatarUrl = '',
            tagLine = '',
            age = 0,
            gender = 'male'
        }: Partial<UserInfoData> = {}) {
        this.id = id;
        this.userRole = userRole;
        this.userView = userView;
        this.name = name;
        this.phone = phone;
        this.wechatID = wechatID;
        this.avatarUrl = avatarUrl;
        this.tagLine = tagLine;
        this.age = age;
        this.gender = gender;

        // 自动将所有字段标记为 observable
        makeAutoObservable(this);
    }

};

export default UserInfoData;





