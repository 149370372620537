import { Button, InputNumber } from 'antd';
import styles from './videoRegionModifier.module.less';
import URLModifier from '../URLModifier/URLModifier';
import VideoRegionContent from '@/base/ElementData/VideoRegionContent';
import { ElementData } from '@/base/ElementData/ElementData';
import React from 'react';

interface VideoRegionModifierProps {
    focusedItem: ElementData<VideoRegionContent>;
    onInputChange: ( id: number, key: string,value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};

const VideoRegionModifier: React.FC<VideoRegionModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {

    return (
        <div>
            <URLModifier
                urlTitle='视频url'
                value={focusedItem.content.src}
                changeValue={value => onInputChange(focusedItem.id, 'src', value)}
            />
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>

        </div>
    )
};

export default VideoRegionModifier;