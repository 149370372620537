import { ElementContent, ElementData } from './ElementData';
import ButtonRegionContent from './ButtonRegionContent';
import { FunctionConfig } from './UtilTypes';
import { ButtonWrapper } from './UtilTypes';
import CorrectAnswerSound from '/assets/audio/correctAnswerSound.mp3';
import WrongAnswerSound from '/assets/audio/wrongAnswerSound.mp3';
import DataDefinition from '../DataDefinition';
import { genObjectIDbyTS } from '@/utils/id_generator';

class BtnTextGroupRegionContent extends ElementContent {
    selectionType: string;
    inputable: boolean;
    goNext: boolean;
    showSubmitBtn: boolean;
    isSubmitted: boolean;
    row: number;
    col: number;
    btnNum: number;
    visible: boolean;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ButtonWrapper)
    buttons: ButtonWrapper[];
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ButtonWrapper)
    showButtons?: ButtonWrapper[]; // 这个字段可以没有
    correctAnswer: number[];
    userAnswer: number[];
    correctAnswerSoundUrl: string;
    wrongAnswerSoundUrl: string;
    evalTextCorrect: string;
    evalTextWrong: string;
    evalTextCorrectAudio: string;
    evalTextWrongAudio: string;
    autoplayEvalText: boolean;
    evalTextColor: string;
    evalTextFontWeight: number;
    characterPerPage: number;
    isCorrect: boolean;
    lectureVar: string;
    rewardedCoinsNum: number;
    postPrompt: string;
    @Reflect.metadata('design:type', FunctionConfig)
    postFunctionCall: FunctionConfig | null;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 200,
        height = 100,
        selectionType = 'single',
        inputable = false,
        goNext = false,
        showSubmitBtn = false,
        isSubmitted = false,
        row = 1,
        col = 1,
        btnNum = 1,
        visible = true,
        buttons = [new ButtonWrapper(
            {
                btnObj: new ElementData<ButtonRegionContent>({
                    id: genObjectIDbyTS(),
                    type: 103,
                    content: new ButtonRegionContent()
                }),
                value: '按钮',
            }
        )],
        showButtons = [],
        correctAnswer = [],
        userAnswer = [],
        correctAnswerSoundUrl = CorrectAnswerSound,
        wrongAnswerSoundUrl = WrongAnswerSound,
        evalTextCorrect = "恭喜你，答对了",
        evalTextWrong = "",
        evalTextCorrectAudio = "",
        evalTextWrongAudio = "",
        autoplayEvalText = false,
        evalTextColor = "#000000",
        evalTextFontWeight = 400,
        characterPerPage = 75,
        isCorrect = false,
        lectureVar = "",
        rewardedCoinsNum = 0,
        postPrompt = "",
        postFunctionCall = null
    }: Partial<BtnTextGroupRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'selectionType', 'inputable', 'goNext', 'showSubmitBtn', 'isSubmitted',
            'row', 'col', 'btnNum', 'visible', 'buttons', 'showButtons', 'correctAnswer',
            'userAnswer', 'correctAnswerSoundUrl', 'wrongAnswerSoundUrl', 'evalTextCorrect',
            'evalTextWrong', 'evalTextCorrectAudio', 'evalTextWrongAudio', 'autoplayEvalText',
            'evalTextColor', 'evalTextFontWeight', 'characterPerPage', 'isCorrect',
            'lectureVar', 'rewardedCoinsNum', 'postPrompt', 'postFunctionCall'];
        this.selectionType = selectionType;
        this.inputable = inputable;
        this.goNext = goNext;
        this.showSubmitBtn = showSubmitBtn;
        this.isSubmitted = isSubmitted;
        this.row = row;
        this.col = col;
        this.btnNum = btnNum;
        this.visible = visible;
        this.buttons = buttons;
        this.showButtons = showButtons;
        this.correctAnswer = correctAnswer;
        this.userAnswer = userAnswer
        this.correctAnswerSoundUrl = correctAnswerSoundUrl;
        this.wrongAnswerSoundUrl = wrongAnswerSoundUrl;
        this.evalTextCorrect = evalTextCorrect;
        this.evalTextWrong = evalTextWrong;
        this.evalTextCorrectAudio = evalTextCorrectAudio;
        this.evalTextWrongAudio = evalTextWrongAudio;
        this.autoplayEvalText = autoplayEvalText;
        this.evalTextColor = evalTextColor;
        this.evalTextFontWeight = evalTextFontWeight;
        this.characterPerPage = characterPerPage;
        this.isCorrect = isCorrect;
        this.lectureVar = lectureVar;
        this.rewardedCoinsNum = rewardedCoinsNum;
        this.postPrompt = postPrompt;
        this.postFunctionCall = postFunctionCall;
    }

    getChildren(): ElementData<ElementContent>[] {
        let result: ElementData<ElementContent>[] = [];

        this.buttons.forEach(button => {
            // 需要把btnObj先加进去
            result.push(button.btnObj!);
            // 这个每个button应该没有children
        });

        return result;
    }

    // 获取用户实际选择的选项，将数组下标转换为潜在的A，B，C，D
    getPrettySelected(choices: number[]): string {
        console.log("getPrettySelected", choices, this);
        let results: string[] = [];
        choices.forEach((index) => {
            const btnContent = (this.buttons[index].btnObj?.content as ButtonRegionContent);
            if (btnContent.bulletEnabled) {
                results.push('(' + btnContent.bulletLetter + ')');
            } else {
                results.push('"' + btnContent.value + '"');
            }
        });
        return results.join(',');

    }

    convertToText(): string {
        let text = "这到选择题的选项如下：";
        this.buttons.forEach(button => {
            text += button.btnObj?.content?.convertToText() + '\n';
        });
        text += "用户选择了" + this.getPrettySelected(this.userAnswer) + "，总共选择了"
                + this.userAnswer.length.toString() + "个选项。";
        if(this.correctAnswer.length > 0) {
            text += this.isCorrect ? "用户这道题答对了。" : "用户这道题答错了。";
            text += "正确答案是：" + this.getPrettySelected(this.correctAnswer) + "。";
        }
        return text;
    }
};

export default BtnTextGroupRegionContent;