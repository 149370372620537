import { ElementContent } from './ElementData';
import AdvancedButtonRegionContent from './AdvancedButtonRegionContent';
import { ElementData } from './ElementData';

class FlashCardRegionContent extends ElementContent {
    backgroundColor: string;
    @Reflect.metadata('design:type', ElementData)
    cardFront: ElementData<AdvancedButtonRegionContent> | null;
    @Reflect.metadata('design:type', ElementData)
    cardBack: ElementData<AdvancedButtonRegionContent> | null;
    cardFrontTemplateUrl: string;
    cardBackTemplateUrl: string;
    visible: boolean;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 300,
        height = 400,
        backgroundColor = "#ffffff",
        cardFront = AdvancedButtonRegionContent.genDefaultAdvBtnAsBtnObj({
            text: "cardFront",
            width: 200,
            height: 300,
            textAlign: 'center'
        }),
        cardFrontTemplateUrl = "default_flash_card_front",
        cardBack = AdvancedButtonRegionContent.genDefaultAdvBtnAsBtnObj({
            text: "cardBack",
            width: 200,
            height: 300,
            textAlign: 'center'
        }),
        cardBackTemplateUrl = "default_flash_card_back",
        visible = true
    }: Partial<FlashCardRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'backgroundColor', 'cardFront', 'cardBack', 'visible', 'cardFrontTemplateUrl', 'cardBackTemplateUrl'];
        this.backgroundColor = backgroundColor;
        this.cardFront = cardFront;
        this.cardFrontTemplateUrl = cardFrontTemplateUrl;
        this.cardBack = cardBack;
        this.cardBackTemplateUrl = cardBackTemplateUrl;
        this.visible = visible;
    }

    getChildren(): ElementData<ElementContent>[] {
        let result: ElementData<ElementContent>[] = [];
        if (this.cardFront) {
            result.push(this.cardFront);
            result = result.concat(this.cardFront.content.getChildren());
        }
        if (this.cardBack) {
            result.push(this.cardBack);
            result = result.concat(this.cardBack.content.getChildren());
        }
        return result;
    }
};

export default FlashCardRegionContent;