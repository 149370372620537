import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores'
import cloneDeep from 'lodash/cloneDeep';
import MultimediaAPI from '@/api/multimedia';
import styles from './aiChatRegion.module.less';
import { Blank, ChatMessage } from '@/base/ChatMessage';
import { containsVariablePattern, rewriteText } from '@/utils/utils';
import FingerClickIcon from '@/assets/img/finger_click.svg';
import PlayTTSV2Icon from '@/assets/img/new_speaker.svg';
import CancelIcon from '@/assets/img/cuowu.svg';
import TeacherProfile from '/assets/img/teacherProfile.svg';
import UserProfile from '@/assets/img/user.png';


interface PresetQuestionProps {
    msg: ChatMessage;
    readPresets: boolean;
    userMessageAvatar?: string;
}

const PresetQuestion: React.FC<PresetQuestionProps> = ({
    msg,
    readPresets,
    userMessageAvatar,
}) => {
    const ttsAPI = new MultimediaAPI();
    const preQuestionsAudioRef = useRef<HTMLAudioElement | null>(null);
    const { commonStatusStore, audioStore, lectureStore, userInfoStore } = useStores();
    const [isGenPreQuestionAudio, setIsGenPreQuestionAudio] = useState(false);
    const [isPlayingPreset, setIsPlayingPreset] = useState(false);
    const isComponentMounted = useRef<boolean | null>(null);
    const [blanks, setBlanks] = useState<Blank[]>(cloneDeep(msg?.blanks) || []);
    const [selectedBlankIndex, setSelectedBlankIndex] = useState<number | null>((msg?.blanks && msg?.blanks.length > 0) ? 0 : null);
    const userAvatar = userInfoStore.userInfoData.avatarUrl || UserProfile;

    const handleClick = (index: number) => {
        setSelectedBlankIndex(index);
    };

    const handleOptionClick = (choice: string) => {
        console.log("handleOptionClick", choice, blanks);
        const newBlanks = [...blanks];
        newBlanks[selectedBlankIndex!].selectedChoice = choice;
        msg.blanks = newBlanks;
        setBlanks(newBlanks);
    };

    const cancelFillBlank = (index: number) => {
        const newBlanks = [...blanks];
        newBlanks[index].selectedChoice = null;
        msg.blanks = newBlanks;
        setBlanks(newBlanks);
    }

    const renderText = (msg: ChatMessage, msgContent: string) => {
        let renderedText = [];
        let lastIndex = 0;

        msg.blanks.forEach((blank, index) => {
            const { start, end, selectedChoice } = blank;
            const isCurrentBlankEmpty = selectedBlankIndex === index && !selectedChoice;

            renderedText.push(msg.rawContent.substring(lastIndex, start));
            renderedText.push(
                <span
                    key={index}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick(index);
                    }}
                    className={`${styles.blank} ${selectedBlankIndex === index ? styles.selectedBlank : ''}`}
                >
                    {blank.selectedChoice || (
                        <span className={isCurrentBlankEmpty ? styles.blink : ''}>
                            {msgContent.substring(start, end + 1)}
                        </span>
                    )}
                    {blank.selectedChoice && (
                        <img
                            src={CancelIcon} // 这里使用你实际的图片路径
                            alt="cancel"
                            className={styles.cancelIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                cancelFillBlank(index);
                            }}
                        />
                    )}
                </span>
            );
            lastIndex = end + 1;
        });

        renderedText.push(msgContent?.substring(lastIndex));
        return renderedText;
    };

    useEffect(() => {
        isComponentMounted.current = true;
        return () => {
            isComponentMounted.current = false;
            if (preQuestionsAudioRef.current) {
                preQuestionsAudioRef.current.pause();
                preQuestionsAudioRef.current = null;
            }
        }
    }, [lectureStore.currentBlockIndex])

    useEffect(() => {
        if (audioStore.currentAudioEleId !== msg?.id && preQuestionsAudioRef.current && isPlayingPreset) {
            preQuestionsAudioRef.current.pause();
            preQuestionsAudioRef.current = null;
            setIsPlayingPreset(false);
            setIsGenPreQuestionAudio(false);
        }
    }, [audioStore.currentAudioEleId])

    useEffect(() => {
        setBlanks(cloneDeep(msg?.blanks) || []);
    }, [(msg?.blanks)])
    // 其他函数和逻辑保持不变...

    return (
        <div className={styles.presetQuestionItemBox}>
            <div className={styles.presetQuestionItem}>
                <div
                    key={msg?.id}
                    className={`${styles.presetQuestion} ${commonStatusStore.buttonFlashing ? styles.borderFlashing : ''}`}
                >
                    <div>
                        {containsVariablePattern(msg?.rawContent)
                            ? renderText(msg, rewriteText(msg?.rawContent))
                            : renderText(msg, msg?.rawContent)}
                    </div>
                    {blanks.every(blank => blank.selectedChoice) && (
                        <img src={FingerClickIcon} className={styles.clickIcon} />
                    )}
                    {readPresets && (
                        <img
                            src={PlayTTSV2Icon}
                            className={styles.playPresetIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        />
                    )}
                </div>
                {userMessageAvatar && (
                    <div className={styles.presetMsgProfile}>
                        <img src={userMessageAvatar === "teacher" ? TeacherProfile : userAvatar} className={styles.userMessageAvatar} />
                    </div>
                )}
            </div>

            <div>
                {selectedBlankIndex != null && blanks[selectedBlankIndex].descriptionAboutBlank && (
                    <div className={styles.blankDescription}>
                        {blanks[selectedBlankIndex].descriptionAboutBlank}
                    </div>
                )}
                <div className={styles.choiceArea}>
                    {selectedBlankIndex !== null && (
                        blanks[selectedBlankIndex].choicesStr?.split(/\|/).map((option, index) => (
                            <div
                                key={index}
                                onClick={() => handleOptionClick(option.trim())}
                                className={styles.choiceItem}
                                style={option.trim() === blanks[selectedBlankIndex]?.selectedChoice ? { color: "#99CAF0" } : {}}
                            >
                                {option.trim()}
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default observer(PresetQuestion);
