import DataDefinition from '../DataDefinition';
import { ElementContent, ElementData } from './ElementData';
import { FunctionConfig } from './UtilTypes';
import TextRegionContent from './TextRegionContent';
import ImageRegionContent from './ImageRegionContent';
import ButtonRegionContent from './ButtonRegionContent';
import { cloneDeep } from 'lodash';
import { genObjectIDbyTS } from '@/utils/id_generator';

class ChildrenElementContent extends DataDefinition {
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ElementData)
    textRegions: ElementData<TextRegionContent>[];
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ElementData)
    imgRegions: ElementData<ImageRegionContent>[];
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ElementData)
    buttons: ElementData<ButtonRegionContent>[];
    numTextChildren: number;
    numImgChildren: number;
    numBtnChildren: number;

    constructor({
        textRegions = [],
        imgRegions = [],
        buttons = [],
        numTextChildren = 0,
        numImgChildren = 0,
        numBtnChildren = 0
    }: Partial<ChildrenElementContent> = {}) {
        super();
        this.allowedFields = ['textRegions', 'imgRegions', 'buttons', 'numTextChildren',
            'numImgChildren', 'numBtnChildren'];
        this.textRegions = textRegions;
        this.imgRegions = imgRegions;
        this.buttons = buttons;
        this.numTextChildren = numTextChildren;
        this.numImgChildren = numImgChildren;
        this.numBtnChildren = numBtnChildren;
    }
};

class AdvancedButtonRegionContent extends ElementContent {
    value: string | null;;
    @Reflect.metadata('design:type', FunctionConfig)
    onclickFunc: FunctionConfig | null;
    @Reflect.metadata('design:type', ChildrenElementContent)
    children: ChildrenElementContent;
    showAudioIcon: boolean;
    clickSoundUrl: string;
    src: string;
    alpha: number;
    // 这具体是哪个radius
    radius: number;
    visible: boolean;
    autoPlay: boolean;
    status: number;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 250,
        height = 100,
        value = "高级按钮",
        onclickFunc = null,
        children = new ChildrenElementContent(),
        showAudioIcon = false,
        clickSoundUrl = "",
        src = "",
        status = 0,
        alpha = 1,
        radius = 5,
        visible = true,
        autoPlay = false
    }: Partial<AdvancedButtonRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'value', 'onclickFunc', 'children', 'showAudioIcon', 'clickSoundUrl', 'src',
            'status', 'alpha', 'radius', 'visible', 'autoPlay'];
        this.value = value;
        this.onclickFunc = onclickFunc;
        this.children = children;
        this.showAudioIcon = showAudioIcon;
        this.clickSoundUrl = clickSoundUrl;
        this.src = src;
        this.status = status;
        this.alpha = alpha;
        this.radius = radius;
        this.visible = visible;
        this.autoPlay = autoPlay;
        this.value = value;
    }

    getChildren(): ElementData<ElementContent>[] {
        const result: ElementData<ElementContent>[] = [];
        this.children.textRegions.forEach(textItem => {
            result.push(textItem);
        });

        this.children.imgRegions.forEach(imgItem => {
            result.push(imgItem);
        });

        this.children.buttons.forEach(buttonItem => {
            result.push(buttonItem);
        });
        return result;
    }

    initChildren(numTextChildren: number, numImgChildren: number, numBtnChildren: number) {
        let initZIndex = this.zIndex;
        for (let i = 0; i < numTextChildren; i++) {
            const textChild = new ElementData<TextRegionContent>({
                id: genObjectIDbyTS(),
                type: 101,
                content: new TextRegionContent({ text: '文本内容' })
            });
            textChild.content.positionX = 20 + i * 5;
            textChild.content.positionY = 20 + i * 5;
            textChild.content.height = 30;
            textChild.content.width = 80;
            textChild.content.zIndex = initZIndex + 1;
            initZIndex++;
            this.children.textRegions.push(textChild);
        }

        for (let i = 0; i < numImgChildren; i++) {
            const imgChild = new ElementData<ImageRegionContent>({
                id: genObjectIDbyTS(),
                type: 102,
                content: new ImageRegionContent()
            });
            imgChild.content.positionX = 30 + i * 5;
            imgChild.content.positionY = 30 + i * 5;
            imgChild.content.height = 50;
            imgChild.content.width = 50;
            imgChild.content.zIndex = initZIndex + 1;
            initZIndex++;
            this.children.imgRegions.push(imgChild);
        }

        for (let i = 0; i < numBtnChildren; i++) {
            const btnChild = new ElementData<ButtonRegionContent>({
                id: genObjectIDbyTS(),
                type: 103,
                content: new ButtonRegionContent()
            });
            btnChild.content.positionX = 50 + i * 5;
            btnChild.content.positionY = 40 + i * 5;
            btnChild.content.height = 20;
            btnChild.content.width = 40;
            btnChild.content.zIndex = initZIndex + 1;
            initZIndex++;
            this.children.buttons.push(btnChild);
        }
    }

    updateChildrenZIndex(value: number) {
        this.children.textRegions.forEach(textItem => {
            textItem.content.zIndex += value;
        });

        this.children.imgRegions.forEach(imgItem => {
            imgItem.content.zIndex += value;
        });

        this.children.buttons.forEach(buttonItem => {
            buttonItem.content.zIndex += value;
        });
    }

    resetChildrenId() {
        this.children.textRegions.forEach(textItem => {
            textItem.id = genObjectIDbyTS();
        });

        this.children.imgRegions.forEach(imgItem => {
            imgItem.id = genObjectIDbyTS();
        });

        this.children.buttons.forEach(buttonItem => {
            buttonItem.id = genObjectIDbyTS();
        });
    }

    static duplicate(srcElment: AdvancedButtonRegionContent, positionX: number,
        positionY: number, maxZIndex: number): AdvancedButtonRegionContent {
        const newElement = cloneDeep(srcElment);
        newElement.positionX = positionX;
        newElement.positionY = positionY;
        newElement.updateChildrenZIndex(maxZIndex);
        return newElement;
    }

    static genDefaultAdvBtnAsBtnObj({
        text = "",
        width = 10,
        height = 10,
        textAlign = "center",
        paddingLeft = 0,
        paddingRight = 0,
        paddingTop = 0,
        paddingBottom = 0,
    }: Partial<TextRegionContent> = {}): ElementData<AdvancedButtonRegionContent> {
        return new ElementData<AdvancedButtonRegionContent>({
            id: genObjectIDbyTS(),
            type: 104,
            content: new AdvancedButtonRegionContent({
                width: width,
                height: height,
                children: new ChildrenElementContent({
                    textRegions: [new ElementData<TextRegionContent>({
                        id: genObjectIDbyTS(),
                        type: 101,
                        content: new TextRegionContent({
                            text: text,
                            width: width,
                            height: height,
                            textAlign: textAlign,
                            verticalAlign: "center",
                            paddingLeft: paddingLeft,
                            paddingRight: paddingRight,
                            paddingTop: paddingTop,
                            paddingBottom: paddingBottom,
                        })
                    })],
                    numTextChildren: 1,
                })
            }),
            childrenLevel: true
        })
    }

}

export default AdvancedButtonRegionContent;
export { ChildrenElementContent };