import PropTypes from 'prop-types';
import styles from './teacherPreviewBlock.module.less'
import { Button } from 'antd';
import RenderElement from '@/components/Element/common/RenderElement';
import { BlockData } from '@/base/BlockData';
import { lectureStore } from '@/store/lectureStore';

interface TeacherPreviewBlockProps {
    editBlock: (type: number, blockItem: BlockData) => void;
    duplicateBlock: (blockItem: BlockData, index: number) => void;
    moveBlock: (type: number, blockId: number, blkList: number[], index: number) => void;
    deleteBlockConfirmation: (blockId: number) => void;
    blockItem: BlockData;
    index: number;
}

const TeacherPreviewBlock: React.FC<TeacherPreviewBlockProps> = ({ 
    editBlock, 
    duplicateBlock, 
    moveBlock, 
    deleteBlockConfirmation,
    blockItem, 
    index }) => {
    const prevBlock: BlockData | null = index > 0 ? lectureStore.originBlockList[index - 1] : null;
    const nextBlock: BlockData | null  = index < lectureStore.originBlockList.length - 1 ? lectureStore.originBlockList[index + 1] : null;
    //console.log("prevBlock", prevBlock, "nextBlock", nextBlock);

    const blkList: number[] = [];
    lectureStore.originBlockList.forEach((blk) => {
        blkList.push(blk.id);
    });

    return (
        <>
            <div className={styles.block} style={{
                backgroundImage: `url(${blockItem?.extras?.background || ''})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
                <div className={styles.container}>
                    {blockItem.blockInfo.map((element, index) => {
                        return <RenderElement
                            key={index}
                            elementData={element}
                            isEditable={false}
                            index={index} />
                    })}
                </div>
                <div className={styles.btnArea}>
                    <Button type='primary' onClick={() => editBlock(2, blockItem)}>编辑block {index+1}</Button>
                    <Button type='primary' danger onClick={() => { deleteBlockConfirmation(blockItem.id) }}>删除block {index+1}</Button>
                    <Button type='primary' onClick={() => editBlock(3, prevBlock!)}>在block {index+1}上方添加block</Button>
                    <Button type='primary' onClick={() => editBlock(4, blockItem)}>在block {index+1}下方添加block</Button>
                    <Button type='primary' onClick={() => duplicateBlock(blockItem, index)}>Duplicate block {index+1}</Button>
                    { index > 0 && <Button type='primary' onClick={() => moveBlock(0, blockItem.id, blkList, index)}
                        style={{ backgroundColor: 'green', color: 'white' }}>上移</Button> }
                    { index < lectureStore.originBlockList.length - 1 && 
                        <Button type='primary' onClick={() => moveBlock(1, blockItem.id, blkList, index)}
                        style={{ backgroundColor: 'green', color: 'white' }}>下移</Button>}
                </div>
            </div>
        </>
    )
}
export default TeacherPreviewBlock;