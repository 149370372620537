import PropTypes from 'prop-types';
import styles from './sunoGenLoading.module.less'
import PopIcon from '@/assets/img/music_style_pop.svg';
import RockIcon from '@/assets/img/music_style_rock.svg';
import FolkIcon from '@/assets/img/music_style_folk.svg';
import ClassicalIcon from '@/assets/img/music_style_classical.svg';
import ElectronicIcon from '@/assets/img/music_style_electronic.svg';
import HipHopIcon from '@/assets/img/music_style_hiphop.svg';
import MusicStyleItem from './MusicStyleItem';

const musicStyleList = [
    {
        icon: PopIcon,
        chinese: '流行',
        english: 'Pop',
        audioSrc: 'https://agi4kids.s3.us-west-2.amazonaws.com/audios/pop_genre.mp3',
        delay: '0s'
    },
    {
        icon: RockIcon,
        chinese: '摇滚',
        english: 'Rock',
        audioSrc: 'https://agi4kids.s3.us-west-2.amazonaws.com/audios/rock_genre.mp3',
        delay: '0.2s'
    },
    {
        icon: FolkIcon,
        chinese: '民谣',
        english: 'Folk',
        audioSrc: 'https://agi4kids.s3.us-west-2.amazonaws.com/audios/folk_genre.mp3',
        delay: '0.4s'
    },
    {
        icon: ClassicalIcon,
        chinese: '古典',
        english: 'Classical',
        audioSrc: 'https://agi4kids.s3.us-west-2.amazonaws.com/audios/classical_genre.mp3',
        delay: '0.6s'
    },
    {
        icon: ElectronicIcon,
        chinese: '电子',
        english: 'Electronic',
        audioSrc: 'https://agi4kids.s3.us-west-2.amazonaws.com/audios/electric_genre.mp3',
        delay: '0.8s'
    },
    {
        icon: HipHopIcon,
        chinese: '嘻哈',
        english: 'Hip Hop',
        audioSrc: 'https://agi4kids.s3.us-west-2.amazonaws.com/audios/hiphop_genre.mp3',
        delay: '1s'
    },
]

const SunoGenLoading = ({ apiStatus }) => {
    return (
        <>
            {
                apiStatus === 1 && (
                    <div className={styles.sunoGenLoadingBox}>
                        <div className={styles.musicStyleBox}>
                            {
                                musicStyleList.map((item, index) => {
                                    return (
                                        <MusicStyleItem
                                            key={index}
                                            musicStyleItem={item}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div className={styles.loadingBox}>
                            <div className={styles.loadingIcon}></div>
                            <div className={styles.loadingText}>
                                音乐生成中，请耐心等待...
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SunoGenLoading;

SunoGenLoading.propTypes = {
    apiStatus: PropTypes.number.isRequired,
}