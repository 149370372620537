import {ElementContent} from './ElementData';

class ShowCodeRegionContent extends ElementContent {
    backgroundColor: string;
    code: string;
    language: string;
    visible: boolean;
    
    constructor({
        positionX = 0,
        positionY = 0,
        width = 800,
        height = 600,
        backgroundColor = "#ffffff",
        code = "",
        language = "html",
        visible = true
    }: Partial<ShowCodeRegionContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'backgroundColor', 'code', 'language', 'visible'];
        this.backgroundColor = backgroundColor;
        this.code = code;
        this.language = language;
        this.visible = visible;
    }
};

export default ShowCodeRegionContent;