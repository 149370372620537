import styles from '../common/element.module.less';
import PropTypes from 'prop-types';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import LineRegionContent from '@/base/ElementData/LineRegionContent';

const LineRegion: React.FC<IntrinsicElementProps<LineRegionContent>> = ({
    elementData, 
    isEditable, 
    handleFocusItem, 
    handleResize,
    handleDragStop, 
    handleDelete, 
 }) => {
    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}>
        <div
            style={{ ...commonStyle,backgroundColor: `${elementData.content.backgroundColor}`}}
            onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
            <div className={styles.line}>
            </div>
        </div>
    </BaseDragableElement>)
};

export default LineRegion;
