import DataDefinition from "./DataDefinition";

class ProgressSegmentData extends DataDefinition{
    endPos: number;
    lengthPercent: number; 
    progress: number;
    desc: string

    constructor({
        endPos = 0,
        lengthPercent = 0,
        progress = 0,
        desc = ''
        }: Partial<ProgressSegmentData> = {}) {
        super();
        this.allowedFields = ['endPos', 'lengthPercent', 'progress', 'desc'];
        this.endPos = endPos;
        this.lengthPercent = lengthPercent;
        this.progress = progress;
        this.desc = desc;
    }
};

export default ProgressSegmentData;
