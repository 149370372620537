import { AnyObject } from "antd/es/_util/type";
import { makeObservable, observable, action } from "mobx";

// TODO：目前AudioSegment的type设成Any，以后需要改成具体的类型
type AudioSegment = any;

class TTSStore {
    audioSegments: AudioSegment[] = [];
    isPlaying: boolean = false;
    userNeedPause: boolean = false;

    constructor() {
        makeObservable(this, {
            audioSegments: observable,
            isPlaying: observable,
            userNeedPause: observable,
            setAudioSegments: action,
            addAudioSegment: action,
            removeAudioSegment: action,
            setPlaying: action,
            clearAudioSegments: action,
            setUserNeedPause: action,
            resetTTSStore: action,
        });
    }

    setAudioSegments(segments: AudioSegment[]) {
        this.audioSegments = segments;
    }

    addAudioSegment(segment: AudioSegment) {
        this.audioSegments.push(segment);
    }

    // 从头部删除一个音频片段
    removeAudioSegment() {
        this.audioSegments.shift();
    }

    setPlaying(value: boolean) {
        this.isPlaying = value;
    }

    clearAudioSegments() {
        this.audioSegments = [];
    }

    setUserNeedPause(value: boolean) {
        this.userNeedPause = value;
    }

    resetTTSStore() {
        this.clearAudioSegments();
        this.setPlaying(false);
    }
}

const ttsStore = new TTSStore();
export { ttsStore, TTSStore };
