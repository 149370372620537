import styles from './ratingRegion.module.less';
import PropTypes from 'prop-types';
import { useState } from 'react';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import YellowStar from '@/assets/img/star_yellow.svg';
import GrayStar from '@/assets/img/star_gray.svg';
import service from '@/services/axios';
import { cloneDeep } from 'lodash';
import { message } from 'antd';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import mixpanel from 'mixpanel-browser';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { BlockTraceData } from '@/base/BlockData';
import RatingRegionContent from '@/base/ElementData/RatingRegionContent';

interface RatingRegionProps extends IntrinsicElementProps<RatingRegionContent> {
    blockTraceData: BlockTraceData;
}

const RatingRegion: React.FC<RatingRegionProps> = ({ 
    elementData, 
    isEditable, 
    handleFocusItem, 
    handleResize,
    handleDragStop, 
    handleDelete, 
    blockTraceData }) => {
    const { viewportStore } = useStores();
    const { scaleRatio } = viewportStore;
    const [rating, setRating] = useState(elementData.content.rating || 0);
    const ratingDescriptions = elementData.content.ratingDescriptions || [];

    const backgroundStyle = elementData?.content?.backgroundImage ? {
        backgroundImage: `url(${elementData.content.backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    } : {
        backgroundColor: elementData.content.backgroundColor
    };


    const handleRating = (index: number) => {
        if(import.meta.env.PROD) {
            mixpanel.track('handleRating', { index: index + 1 });
        }

        const newRating = (rating === index + 1) ? 0 : index + 1;
        setRating(newRating);

        if (!elementData.content.showSubmit) {
            submitRating(newRating);
        }
    };


    const submitRating = async (userRating: number) => {
        //在blockTraceData 的 trace_info数组中找到id和elementData.id相同的元素，修改对应元素的content的rating为rating
        const newBlockTraceData = cloneDeep(blockTraceData);
        newBlockTraceData.traceInfo.forEach((item) => {
            if (item.id === elementData.id) {
                ((item.content) as RatingRegionContent).rating = userRating;
            }
        });
        try {
            const res: any = await service.put('/block_trace', newBlockTraceData);
            if (res.status === 'success') {
                message.success('评价成功');
                elementData.content.rating = userRating;
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.ratingRegionBox} style={
                    {
                        width: "100%",
                        height: "100%",
                        borderRadius: `${scaleRatio * elementData.content.borderRadius}px`,
                        ...backgroundStyle
                    }
                }>
                    <div className={styles.ratingRegionTitle} style={
                        {
                            fontSize: `${scaleRatio * elementData.content.titleFontSize}px`,
                            color: `${elementData.content.titleColor}`
                        }
                    }>
                        {elementData.content.title}
                    </div>
                    <div className={styles.starBox}>
                        {[...Array(elementData.content.starNum)].map((star, index) => {
                            const isFilled = index < rating;
                            return (
                                <img
                                    key={index}
                                    src={isFilled ? YellowStar : GrayStar}
                                    alt={isFilled ? 'Filled Star' : 'Empty Star'}
                                    onClick={isEditable ? undefined : () => handleRating(index)}
                                    className={styles.star}
                                />
                            );
                        })}
                    </div>
                    <div className={styles.ratingDescription} style={
                        {
                            fontSize: `${scaleRatio * elementData.content.ratingDescFontSize}px`,
                            height: `${scaleRatio * elementData.content.ratingDescHeight}px`,
                            borderRadius: `${scaleRatio * elementData.content.ratingDescBorderRadius}px`,
                            color: `${elementData.content.ratingDescColor}`,
                            background: `${elementData.content.ratingDescBackgroundColor}`
                        }
                    } >
                        {ratingDescriptions[rating]}
                    </div>
                    {
                        elementData.content.showSubmit && (
                            <Button
                                style={
                                    {
                                        width: `${scaleRatio * elementData.content.btnWidth}px`,
                                        height: `${scaleRatio * elementData.content.btnHeight}px`,
                                        lineHeight: `${scaleRatio * 28}px`,
                                        cursor: 'pointer',
                                        fontSize: `${scaleRatio*elementData.content.btnFontSize}px`,
                                        borderRadius: `${scaleRatio*elementData.content.btnBorderRadius}px`,
                                        backgroundColor: `${elementData.content.btnBackgroundColor}`,
                                        color: `${elementData.content.btnFontColor}`,
                                        border: `${elementData.content.btnBorderWidth || 0}px solid ${elementData.content.btnBorderColor || '#000000'}`,
                                        boxShadow: `${elementData.content?.btnShadowX || 0}px 
                                                    ${elementData.content?.btnShadowY || 0}px 
                                                    ${elementData.content?.btnShadowBlur || 0}px 
                                                    ${elementData.content?.btnShadowSpread || 0}px 
                                                    ${elementData.content?.btnShadowColor || '#000000'}`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center', // 添加这一行使文字居中
                                        boxSizing: 'border-box',
                                        fontWeight: 'bold'
                                    }
                                }
                                onClick={() => submitRating(rating)}
                            >
                                提交
                            </Button>
                        )
                    }
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default observer(RatingRegion);
