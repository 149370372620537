import DataDefinition from '../DataDefinition';
import { ElementContent, ElementData } from './ElementData';
import { genObjectIDbyTS } from '../../utils/id_generator';
import AdvancedButtonRegionContent from './AdvancedButtonRegionContent';
import { FunctionConfig } from './UtilTypes';

class DragItem extends DataDefinition {
    id: number;
    value: string;
    correctCategory: string;
    @Reflect.metadata('design:type', ElementData)
    btnObj: ElementData<AdvancedButtonRegionContent> | null;
    elementRef: any;
    templateUrl: string;

    constructor({
        id = genObjectIDbyTS(),
        value = "item1",
        correctCategory = "",
        btnObj = AdvancedButtonRegionContent.genDefaultAdvBtnAsBtnObj({
            text: "item1",
            width: 100,
            height: 50,
            textAlign: 'center'
        }),
        elementRef = null,
        templateUrl = "default_thing_item"
    }: Partial<DragItem> = {}) {
        super();
        this.allowedFields = ['id', 'value', 'correctCategory', 'btnObj', 'elementRef', 'templateUrl'];
        this.id = id;
        this.value = value;
        this.correctCategory = correctCategory;
        this.btnObj = btnObj;
        this.elementRef = elementRef;
        this.templateUrl = templateUrl;
    }
}

class CategoryItem extends DataDefinition {
    id: number;
    value: string;
    @Reflect.metadata('design:type', ElementData)
    btnObj: ElementData<AdvancedButtonRegionContent> | null;
    templateUrl: string;

    constructor({
        id = genObjectIDbyTS(),
        value = "category1",
        btnObj = AdvancedButtonRegionContent.genDefaultAdvBtnAsBtnObj({
            text: "category1",
            width: 200,
            height: 40,
            textAlign: 'center',
        }),
        templateUrl = "default_category_item"
    }: Partial<CategoryItem> = {}) {
        super();
        this.allowedFields = ['id', 'value', 'btnObj', 'templateUrl'];
        this.id = id;
        this.value = value;
        this.btnObj = btnObj;
        this.templateUrl = templateUrl;
    }
}

class DragClassifyRegionContent extends ElementContent {
    backgroundColor: string;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', DragItem)
    things: DragItem[];
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', CategoryItem)
    categories: CategoryItem[];
    result: Record<string, DragItem[]>;
    shouldComplete: boolean;
    borderRadius: number;
    visible: boolean;
    postPrompt: string;
    @Reflect.metadata('design:type', FunctionConfig)
    postFunctionCall: FunctionConfig | null;
    classificationMode: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 900,
        height = 520,
        backgroundColor = "#ffffff",
        things = [new DragItem()],
        categories = [new CategoryItem()],
        result = {},
        shouldComplete = true,
        borderRadius = 10,
        visible = true,
        postPrompt = "",
        postFunctionCall = null,
        //两种模式，one_to_one  one_to_many
        classificationMode = "one_to_one",

    }: Partial<DragClassifyRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'backgroundColor', 'things', 'categories', 'result', 'shouldComplete',
            'borderRadius', 'visible', 'postPrompt', 'postFunctionCall', 'classificationMode'];
        this.backgroundColor = backgroundColor;
        this.things = things;
        this.categories = categories;
        this.result = result;
        this.shouldComplete = shouldComplete;
        this.borderRadius = borderRadius;
        this.visible = visible;
        this.postPrompt = postPrompt;
        this.postFunctionCall = postFunctionCall;
        this.classificationMode = classificationMode;
    }

    getChildren(): ElementData<ElementContent>[] {
        let result: ElementData<ElementContent>[] = [];
        this.things.forEach(item => {
            if (item.btnObj) {
                result.push(item.btnObj);
                result = result.concat(item.btnObj.content.getChildren());
            }
        });
        this.categories.forEach(item => {
            if (item.btnObj) {
                result.push(item.btnObj);
                result = result.concat(item.btnObj.content.getChildren());
            }
        });
        return result;
    }

    getPrettyCategoryInfo(): string {
        const categories: string[] = [];
        this.categories.forEach(category => {
            categories.push(category.value);
        });

        return "一共有" + categories.join("，") + "这" + categories.length.toString() + "个分类。";
    }

    getPrettyClassifyResult(): string {
        // iterate through the result field
        let resultString = "用户的分类结果是：";
        for (let key in this.result) {
            resultString += key + "分类中有" + this.result[key].length.toString() + "个Item。";
            // Item分别是
            let items = this.result[key];
            let itemValues: string[] = [];
            items.forEach(item => {
                itemValues.push(item.value);
            });
            resultString += "分别是" + itemValues.join("，") + "。";
        }
        return resultString;
    }

    convertToText(): string {
        let text = "用户需要用拖拽的方法对Items进行分类。" + this.getPrettyCategoryInfo();
        text += this.getPrettyClassifyResult();
        return text;
    }
};

export { DragClassifyRegionContent, DragItem, CategoryItem };