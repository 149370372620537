import { useRef, useState, useEffect } from 'react';
import PlayIcon from '@/assets/img/playAudio.svg';
import PauseIcon from '@/assets/img/pauseAudio.svg';
import styles from './audioPlayer.module.less';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import mixpanel from 'mixpanel-browser';

interface AudioPlayerProps {
    audioSrc: string;
    playerType: string;
    isPlaying: boolean;
    setIsPlaying: (isPlaying: boolean) => void;
    currentAudioEleId?: number;
}

const AudioPlayer = ({ audioSrc, playerType, isPlaying, setIsPlaying, currentAudioEleId }: AudioPlayerProps) => {
    const { audioStore } = useStores();
    const audioRef = useRef<HTMLAudioElement>(null); // 创建引用以方便访问audio元素
    const [progress, setProgress] = useState(0); // 管理进度条
    const [formattedCurrentTime, setFormattedCurrentTime] = useState("0:00");
    const [formattedDuration, setFormattedDuration] = useState("0:00");
    const togglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying && audio) {
            if(import.meta.env.PROD) {
                mixpanel.track('audio_pause', 
                    { 'audioSrc': audioSrc, 
                    'playerType': playerType });
            }
            audio.pause();
        } else {
            if(import.meta.env.PROD) {
                mixpanel.track('audio_play', 
                    { 'audioSrc': audioSrc, 
                    'playerType': playerType });
            }
            if(audio) {
                audio.play();
                audioStore.setCurrentAudioEleId(currentAudioEleId!);
            }
        }
        setIsPlaying(!isPlaying);
    };
    const handleProgressChange = (e: any) => {
        const value = e.target.value; // 获取当前的进度条值
        const audio = audioRef.current;
        if (audio) {
            const newTime = (audio.duration / 100) * value; // 根据进度条的百分比计算新时间
            audio.currentTime = newTime; // 更新音频当前时间
            setProgress(Math.round((newTime / audio.duration) * 100)); // 更新进度状态为整数
            setFormattedCurrentTime(formatTime(newTime));
        }
    };
    function formatTime(timeInSeconds: number) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${paddedSeconds}`;
    }

    useEffect(() => {
        const audio = audioRef.current;
        const updateProgress = () => {
            if (!audio) return;
            const { currentTime, duration } = audio;
            setProgress((currentTime / duration) * 100);
        }
        const handleEnded = () => {  
            setIsPlaying(false);  
        } 

        if (audio) {
            audio.addEventListener('timeupdate', updateProgress);
            audio.addEventListener('ended', handleEnded);  
        }
        // 清理监听器
        return () => {
            if(audio){
                audio.removeEventListener('timeupdate', updateProgress);
                audio.removeEventListener('ended', handleEnded);
            }
        };
    }, [isPlaying]);
    useEffect(() => {
        const audio = audioRef.current;

        const updateProgress = () => {
            if (!audio) return;
            const { currentTime, duration } = audio;
            setProgress((currentTime / duration) * 100);
            setFormattedCurrentTime(formatTime(currentTime)); // 确保这里更新了 formattedCurrentTime
        }

        if(audio){
            audio.addEventListener('timeupdate', updateProgress);
        }
        // 设置初始值
        const setAudioData = () => {
            if (!audio) return;
            setFormattedDuration(formatTime(audio.duration)); // 当音频的 duration 变得可用时设置总时长
        };

        if(audio){
            if (audio.readyState > 0) {
                // 如果audio元素在这个effect运行时已经加载好了（例如，快速切换组件），立即设置时长
                setAudioData();
            } else {
                audio.addEventListener('loadedmetadata', setAudioData);
            }
        }

        // 清理监听器
        return () => {
            if(audio){
                audio.removeEventListener('timeupdate', updateProgress);
                audio.removeEventListener('loadedmetadata', setAudioData);
            }
        };
    }, []); // 由于我们只监听事件，依赖列表为空数组

    useEffect(() => {
        if (audioStore.currentAudioEleId !== currentAudioEleId) {
            if(audioRef.current){
                audioRef.current.pause();
                setIsPlaying(false);
            }
        }
    }, [audioStore.currentAudioEleId])
    return (
        <>
            {
                playerType === 'small' && (
                    <div className={styles.smallAudioPlayer}>
                        <img
                            src={isPlaying ? PauseIcon : PlayIcon}
                            alt={isPlaying ? "pause" : "play"}
                            onClick={togglePlayPause} />
                    </div>
                )
            }
            {
                playerType === 'small_horizontal' && (
                    <div className={styles.smallAudioPlayer}>
                        <img
                            src={isPlaying ? PauseIcon : PlayIcon}
                            alt={isPlaying ? "pause" : "play"}
                            onClick={togglePlayPause} />
                    </div>
                )
            }
            {
                playerType === 'medium' && (
                    <div className={styles.mediumAudioPlayer}>
                        <div className={styles.playIcon}>
                            <img
                                src={isPlaying ? PauseIcon : PlayIcon}
                                alt={isPlaying ? "pause" : "play"}
                                onClick={togglePlayPause} />
                        </div>
                        <div className={styles.playArea}>
                            <div className={styles.progressBarContainer}>
                                <span className={styles.formattedCurrentTime}>{formattedCurrentTime}</span>
                                <input
                                    type="range"
                                    value={progress}
                                    onChange={handleProgressChange}
                                    min="0"
                                    max="100"
                                    className={styles.progressBar}
                                />
                                <span className={styles.formattedDuration}>{formattedDuration}</span>
                            </div>
                        </div>
                    </div>
                )
            }


            <audio ref={audioRef} className={styles.audio} >
                <source src={audioSrc} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </>
    )
}

export default observer(AudioPlayer);

AudioPlayer.propTypes = {
    audioSrc: PropTypes.string.isRequired,
    playerType: PropTypes.string.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    setIsPlaying: PropTypes.func.isRequired,
    currentAudioEleId: PropTypes.string.isRequired
}; 