import service from "@/services/axios";

const ApiPath = {
    CREATE_SONG: "/song",
    CREATE_ALBUM: "/album",
    UPDATE_ALBUM: "/album",
    GET_ALBUM: (userId) => `/album/${userId}`,
    ADD_SONG_TO_ALBUM: (userId, songId) => `/album/${userId}/add/${songId}`,
    MUSIC_GEN_CUSTOM: '/music_gen_custom_v2',
}

export const createSong = (data) => {
    return service.post(ApiPath.CREATE_SONG, data);
}

export const createAlbum = (data) => {
    return service.post(ApiPath.CREATE_ALBUM, data);
}

export const updateAlbum = (data) => {
    return service.put(ApiPath.UPDATE_ALBUM, data);
}

export const getAlbum = (userId) => {
    return service.get(ApiPath.GET_ALBUM(userId));
}

export const addSongToAlbum = (userId, songId) => {
    return service.post(ApiPath.ADD_SONG_TO_ALBUM(userId, songId));
}

export const musicGenCustom = (data) => {
    return service.post(ApiPath.MUSIC_GEN_CUSTOM, data);
} 