import DataDefinition from "./DataDefinition";
import { ChatMessage } from "./ChatMessage";
import UserInfoData from "./UserInfoData";
import { genUUIDv4 } from "@/utils/id_generator";

// 这基本是后端数据的一个mirror
class LLMConfig extends DataDefinition {
    vendor: string;
    model?: string;
    temperature?: number;

    constructor(props: Partial<LLMConfig> = {}) {
        super();
        this.vendor = props.vendor || "OpenAI";
        if(props.model) {
            this.model = props.model;
        }
        if(props.temperature) {
            this.temperature = props.temperature;
        }
    }
}

class LLMScenario extends DataDefinition {
    scenarioName?: string;
    systemSetting?: string;
    scenarioParameter?: Record<string, any>; 

    constructor(props: Partial<LLMScenario> = {}) {
        super();
        if(props.scenarioName) {
            this.scenarioName = props.scenarioName;
        }
        if(props.systemSetting) {
            this.systemSetting = props.systemSetting;
        }
        if(props.scenarioParameter) {
            this.scenarioParameter = props.scenarioParameter;
        }
    }
}

class UserCompactData extends DataDefinition {
    userId: number;
    userName: string;
    
    constructor(props: Partial<UserCompactData> = {}) {
        super();
        this.userId = props.userId || 0;
        this.userName = props.userName || 'default_user';
    }
}

class ChatMessageCompactData extends DataDefinition {
    msgContent: string;
    fromUser?: UserCompactData;
    timestamp?: number;

    constructor(props: Partial<ChatMessageCompactData> = {}) {
        super();
        this.msgContent = props.msgContent!
        if(props.fromUser) {
            this.fromUser = props.fromUser;
        }
        if(props.timestamp) {
            this.timestamp = props.timestamp;
        }
    }
}

class LLMContext extends DataDefinition {
    context: ChatMessageCompactData[];
    additionalInfo?: string;

    constructor(props: Partial<LLMContext> = {}) {
        super();
        this.context = props.context || [];
        if(props.additionalInfo) {
            this.additionalInfo = props.additionalInfo;
        }
    }

    static genLLMContextData(messages: ChatMessage[], 
                            userId: number,
                            userName: string,
                            additionalInfo = ''): LLMContext {
        //获取chat_list中的历史消息, 这个应该生成的是Json，发给后端的
        const ctx = new LLMContext();
        ctx.context =messages.map((item) => {
            return new ChatMessageCompactData({
                fromUser: new UserCompactData({
                    userId: (item.type === 1 || item.type === 4) ? -1 : userId,
                    userName: (item.type === 1 || item.type === 4) ? "assistant" : userName
                }),
                msgContent: item.type === 4 ? '我画了一幅画：' + item.rawContent : item.rawContent,
                timestamp: 0
            });
        });
        ctx.additionalInfo = additionalInfo;
        return ctx;
    }
}

class LLMFunctionParamItem extends DataDefinition {
    paramType: string;
    
    constructor({paramType}: Partial<LLMFunctionParamItem> = {}) {
        super();
        this.paramType =paramType!;
    }
}

class LLMFunctionParam extends DataDefinition {
    name: string;
    paramType: string;
    desc?: string;
    // 其实这里是item的类型
    items?: LLMFunctionParamItem;
    enums?: any[];
    required?: boolean;

    constructor(props: Partial<LLMFunctionParam> = {}) {
        super();
        this.name = props.name!;
        this.paramType = props.paramType!;
        if(props.desc) {
            this.desc = props.desc;
        }
        if(props.items) {
            this.items = props.items;
        }
        if(props.enums) {
            this.enums = props.enums;
        }
        if(props.required) {
            this.required = props.required;
        }
    }
}

// 这里的LLMFunction和后端python里的LLMFunction有区别
// 这里核心是把function/tool的name传回去
// 还可以传的是
class LLMFunction extends DataDefinition {
    name: string;
    desc?: string;
    params?: LLMFunctionParam[];
    templateParams?: Record<string, any>;

    constructor(props: Partial<LLMFunction> = {}) {
        super();
        this.name = props.name!;
        if(props.desc) {
            this.desc = props.desc;
        }
        if(props.params) {
            this.params = props.params;
        }
        if(props.templateParams) {
            this.templateParams = props.templateParams;
        }
    }

}


class LLMCallRequestData extends DataDefinition{
    requestId: string;
    context?: LLMContext;
    msg?: ChatMessageCompactData;
    stream?: boolean;
    responseFormat?: string;
    tools?: LLMFunction[]; //工具
    scenario?: LLMScenario;
    config: LLMConfig;

    constructor(props: Partial<LLMCallRequestData> = {}) {
        super();
        this.requestId = props.requestId || 'request' + genUUIDv4();
        if(props.context) {
            this.context = props.context;
        }
        if(props.msg) {
            this.msg = props.msg;
        }
        if(props.stream) {
            this.stream = props.stream;
        }
        if(props.responseFormat) {
            this.responseFormat = props.responseFormat;
        }
        if(props.tools) {
            this.tools = props.tools;
        }
        if(props.scenario) {
            this.scenario = props.scenario;
        }
        this.config = props.config!;
    }

    static genLLMCallParams(
        msgContent: string,
        userId: number,
        userName: string,
        stream: boolean,
        context: ChatMessage[] = [],
        systemPrompt: string = '',
        tools: string = ''
    ): LLMCallRequestData {   
        let props: any = {};
        if(context && context.length > 0) {    
            const ctx = LLMContext.genLLMContextData(context, userId, userName);
            props.context = ctx;
        }
        props.msg = new ChatMessageCompactData({msgContent});
        props.stream = stream;
        props.config = new LLMConfig();
        if (systemPrompt) {
            props.scenario = new LLMScenario({
                systemSetting: systemPrompt,
            });
        }
        if (tools) {
            let toolNames = tools.split(',').map(item => item.trim());
            // TODO: 未来这里要把templateParams传进去
            props.tools = toolNames.map((toolName) => {
                return new LLMFunction({name: toolName});
            });
        }
        return new LLMCallRequestData(props);
    }
};

export default LLMCallRequestData;
export {ChatMessageCompactData, LLMConfig};






