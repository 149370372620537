import {ElementContent} from './ElementData';

class LineRegionContent extends ElementContent {
    backgroundColor: string;
  
    constructor({
        positionX = 0,
        positionY = 0,
        width = 700,
        height = 40,
        backgroundColor = "#000000"
    }: Partial<LineRegionContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'backgroundColor'];
        this.backgroundColor = backgroundColor;
    }
};

export default LineRegionContent;