import { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import styles from '../common/element.module.less';
import btnGroupStyles from './btnTextGroupRegion.module.less';
import { strTrimLeft } from '@/utils/utils';
import BaseDragableElement from '../common/BaseDragableElement';
import { Button, Input } from 'antd';
import useCommonButtonLogic from '@/hooks/useCommonButtonLogic';
import ButtonRegion from '../ButtonRegion/ButtonRegion';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { throttle } from 'lodash';
import AnswerWrongIcon from '@/assets/img/answer_wrong.svg';
import AnswerCorrectIcon from '@/assets/img/answer_correct.svg';
import SoundPlayingIcon from '@/assets/img/sound_playing_icon.svg';
import PlayTTSV2Icon from '@/assets/img/new_speaker.svg';
import EvalNextPageIcon from '@/assets/img/eval_next_page.svg';
import EvalBackPageIcon from '@/assets/img/eval_back_page.svg';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { BlockTraceData } from '@/base/BlockData';
import BtnTextGroupRegionContent from '@/base/ElementData/BtnTextGroupRegionContent';
import ButtonRegionContent from '@/base/ElementData/ButtonRegionContent';
import { ElementData } from '@/base/ElementData/ElementData';
import RewardAnimation from '@/components/RewardAnimation/RewardAnimation';

interface BtnTextGroupRegionProps extends IntrinsicElementProps<BtnTextGroupRegionContent> {
    blockTraceData: BlockTraceData;
    elementRef: React.RefObject<any>;
}

const BtnTextGroupRegion = forwardRef<any, BtnTextGroupRegionProps>((props, ref) => {
    const correctAnswerSoundRef = useRef<HTMLAudioElement | null>(null);
    const wrongAnswerSoundRef = useRef<HTMLAudioElement | null>(null);
    const correctAnswerTextAudioRef = useRef<HTMLAudioElement | null>(null);
    const wrongAnswerTextAudioRef = useRef<HTMLAudioElement | null>(null);
    const playAudioRef = useRef<HTMLAudioElement | null>(null);
    const [isEvalTextPlaying, setIsEvalTextPlaying] = useState(false);
    const { commonStatusStore, userInfoStore } = useStores();
    const isTeacher = userInfoStore.isTeacherView();
    const { elementData, isEditable, handleFocusItem, handleResize,
        handleDragStop, handleDelete, blockTraceData, elementRef } = props;
    let { col, btnNum, correctAnswer, inputable, showSubmitBtn,
        isSubmitted, visible: initialVisible, autoplayEvalText,
        characterPerPage = 100, rewardedCoinsNum = 0 } = elementData.content;
    const [page, setPage] = useState(0);
    const [isResultCorrect, setIsResultCorrect] = useState(false);
    const [elementVisible, setElementVisible] = useState(initialVisible !== undefined ?
        initialVisible : true);
    //文字逐字显示变量
    const [displayedText, setDisplayedText] = useState('');
    const [textIntervalId, setTextIntervalId] = useState<number | null>(null);
    const sentenceIndexRef = useRef(0);
    const textIndexRef = useRef(0);
    const sentencesRef = useRef<string[]>([]);
    const currentSentenceRef = useRef('');
    let answerResultRef = useRef(elementData.content?.isCorrect || false);
    //不自动播放时，显示文本的变量
    const pagesRef = useRef<string[]>([]);
    const currentPageIndexRef = useRef(0);
    const currentPageContentRef = useRef('');
    const [showNextPageIcon, setShowNextPageIcon] = useState(false);
    const [showBackPageIcon, setShowBackPageIcon] = useState(false);
    //奖励动画
    const [showRewardAnimation, setShowRewardAnimation] = useState(false);

    useEffect(() => {
        //判断是否显示上一页下一页按钮
        if (autoplayEvalText) {
            setShowBackPageIcon(false);
            setShowNextPageIcon(false);
        } else {
            if (pagesRef.current.length > 1) {
                setShowNextPageIcon(currentPageIndexRef.current < pagesRef.current.length - 1);
                setShowBackPageIcon(currentPageIndexRef.current > 0);
            } else {
                setShowNextPageIcon(false);
                setShowBackPageIcon(false);
            }
        }
    }, [pagesRef.current.length, blockTraceData?.id, currentPageIndexRef.current]);

    useEffect(() => {
        // 如果是编辑状态，就把按钮组的值赋值给newButtons,否则就直接使用elementData.content.buttons
        resetBtnStatusAndUserSelect();
        resetNewButtons();
    }, [elementData.content.buttons, isEditable])

    useEffect(() => {
        setElementVisible(initialVisible !== undefined ? initialVisible : true);
    }, [initialVisible]);

    useEffect(() => {
        correctAnswerSoundRef.current = new Audio(elementData?.content?.correctAnswerSoundUrl);
    }, [elementData?.content?.correctAnswerSoundUrl]);

    useEffect(() => {
        wrongAnswerSoundRef.current = new Audio(elementData?.content?.wrongAnswerSoundUrl);
    }, [elementData?.content?.wrongAnswerSoundUrl]);

    useEffect(() => {
        correctAnswerTextAudioRef.current = new Audio(elementData?.content?.evalTextCorrectAudio);
    }, [elementData?.content?.evalTextCorrectAudio]);

    useEffect(() => {
        wrongAnswerTextAudioRef.current = new Audio(elementData?.content?.evalTextWrongAudio);
    }, [elementData?.content?.evalTextWrongAudio]);

    // 计算行数
    const rowCount = Math.ceil(btnNum / col);
    // CSS Grid布局样式
    const gridStyle = {
        display: isTeacher ? 'grid' : (elementVisible ? 'grid' : 'none'),
        gridTemplateColumns: `repeat(${col}, 1fr)`, // 列数动态设置
        gridTemplateRows: `repeat(${rowCount}, auto)`, // 根据计算出的行数来设置行
        gap: '10px',
        width: '100%',
        height: '100%',
        position: 'relative',
        opacity: !isTeacher ? 1 : (elementVisible ? 1 : 0.3),
    };
    const { newButtons, userSelect, userInput, handleSelect,
        handleSubmit, resetBtnStatusAndUserSelect,
        setUserInput, resetNewButtons } = useCommonButtonLogic(isEditable, elementData, blockTraceData);

    useEffect(() => {
        commonStatusStore.setIsSubmitted(isSubmitted);
        commonStatusStore.setIsSelected(userSelect.length > 0 ? true : false);
        commonStatusStore.setHasCorrectAnswer(correctAnswer.length > 0 ? true : false);
    }, [blockTraceData?.id, userSelect, isSubmitted])

    const playEvalTextAudio = () => {
        playAudioRef.current = (answerResultRef.current ? correctAnswerTextAudioRef.current : wrongAnswerTextAudioRef.current) as HTMLAudioElement;

        if (isEvalTextPlaying) {
            playAudioRef.current.pause();
            setIsEvalTextPlaying(false); // 暂停时立即设置为 false  
            stopDisplayingText();
        } else {
            playAudioRef.current.play().then(() => {
                startDisplayingText();
            }).catch(error => {
                console.error('播放音频时出错:', error);
            });
            // 添加事件监听器以在音频结束时重置播放状态  
            playAudioRef.current.onended = () => {
                setIsEvalTextPlaying(false);
            };

            setIsEvalTextPlaying(true);
        }
    };
    const startDisplayingText = () => {
        let sentencesChunk = '';
        // 使用一个计数器，预防特殊情况
        let cntCalled = 0;

        const intervalId = setInterval(() => {
            setDisplayedText(prev => {
                cntCalled++;
                // 先生成一个sentencesChunk
                if (sentencesChunk === '') {
                    let i = sentenceIndexRef.current;
                    console.log("sentenceIndexRef.current", i);
                    let totalCharacter = 0;
                    while (i < sentencesRef.current.length &&
                        totalCharacter + sentencesRef.current[i].length < characterPerPage) {
                        totalCharacter += sentencesRef.current[i].length;
                        sentencesChunk = sentencesChunk + sentencesRef.current[i];
                        i++;
                    }
                    if (i === sentenceIndexRef.current) {
                        console.log("我们可能碰到了很长的句子");
                    }
                    currentSentenceRef.current = strTrimLeft(sentencesChunk);
                    console.log("sentencesChunk", currentSentenceRef.current);
                    sentenceIndexRef.current = i;
                }

                textIndexRef.current = Math.min(textIndexRef.current + 3, currentSentenceRef.current.length);
                const text = currentSentenceRef.current.slice(0, textIndexRef.current);
                if (textIndexRef.current >= currentSentenceRef.current.length) {
                    textIndexRef.current = 0;
                    sentencesChunk = '';

                    if (sentenceIndexRef.current >= sentencesRef.current.length) {
                        sentenceIndexRef.current = 0;
                        textIndexRef.current = 0;
                        clearInterval(intervalId);
                    }
                }

                if (cntCalled >= 300) {
                    console.log("cntCalled >= 300, Exit");
                    sentenceIndexRef.current = 0;
                    textIndexRef.current = 0;
                    clearInterval(intervalId);
                }

                return text;
            });
        }, 500);
        setTextIntervalId(intervalId);
    };

    const stopDisplayingText = () => {
        if (textIntervalId) {
            clearInterval(textIntervalId);
            setTextIntervalId(null);
        }
    };

    const updateSentences = (result: any) => {
        if (result) {
            sentencesRef.current = elementData.content.evalTextCorrect.split(/(?<=[。？！，.?!,])|\n{2}/) ||
                [elementData.content.evalTextCorrect];
        } else {
            sentencesRef.current = elementData.content.evalTextWrong.split(/(?<=[。？！，.?!,])|\n{2}/) ||
                [elementData.content.evalTextWrong];
        }
        if (!autoplayEvalText) {
            let pageContent: string = '';
            let totalCharacter = 0;
            let i = 0;
            while (i < sentencesRef.current.length) {
                if (totalCharacter + sentencesRef.current[i].length < characterPerPage) {
                    totalCharacter += sentencesRef.current[i].length;
                    pageContent += sentencesRef.current[i];
                    i++;
                } else {
                    pagesRef.current.push(pageContent);
                    pageContent = '';
                    totalCharacter = 0;
                }
            }
            if (pageContent !== '') {
                pagesRef.current.push(pageContent);
            }
            currentPageContentRef.current = pagesRef.current[0];
            setDisplayedText(currentPageContentRef.current);
            console.log("pagesRef.current", pagesRef.current)
        }
    };

    useEffect(() => {
        answerResultRef.current = elementData.content?.isCorrect || false;
        if (isSubmitted) {
            setIsResultCorrect(elementData.content.isCorrect);
            if (correctAnswer.length > 0) {
                updateSentences(elementData.content.isCorrect);
                currentSentenceRef.current = sentencesRef.current[0];
            }
            if (autoplayEvalText) {
                setDisplayedText(currentSentenceRef.current);
            }
        }

        return () => {
            stopDisplayingText();
            pagesRef.current = [];
            currentPageIndexRef.current = 0;
            currentPageContentRef.current = '';
        };
    }, [blockTraceData?.id]);

    //组件卸载时停止音频播放
    useEffect(() => {
        return () => {
            if (playAudioRef.current) {
                playAudioRef.current.pause();
                setIsEvalTextPlaying(false);
            }
        }
    }, [blockTraceData?.id]);

    // 点击按钮时音效
    const playAnswerSound = (resultRef: { current: any; }) => {
        const audio = resultRef.current;
        audio.currentTime = 0;
        audio.play().then(() => {
            console.log('Audio played successfully');
        })
            .catch((error: any) => {
                console.error('Error playing audio:', error);
            });

        setTimeout(() => {
            if (audio.duration > 3) {
                audio.pause();
                audio.currentTime = 0;
            }
        }, 1000); // 最多播放两秒
    };

    // 节流处理，防止连续点击
    const throttledPlaySound = throttle((param) => playAnswerSound(param), 300);

    const exposedSubmit = async() => {
        const result = await handleSubmit();
        answerResultRef.current = result ?? false;
        //有正确答案时
        if (correctAnswer.length > 0) {
            updateSentences(result);
            throttledPlaySound(result ? correctAnswerSoundRef : wrongAnswerSoundRef);
            if (result && rewardedCoinsNum > 0) {
                setShowRewardAnimation(true);
                setTimeout(() => {
                    setShowRewardAnimation(false);
                }, 2000);
                userInfoStore.updateUserAssets({
                    user_id: userInfoStore.userInfoData.id,
                    points_to_change: rewardedCoinsNum,
                }).then(() => {
                    console.log('updateUserAssets success');
                }).catch((error: any) => {
                    console.log(error)
                })
            }
        }

        currentSentenceRef.current = sentencesRef.current[0];
        setIsResultCorrect(result ?? false);
        commonStatusStore.setIsSubmitted(true);

        if (autoplayEvalText) {
            playEvalTextAudio();
        }
    };
    const showElement = () => {
        setElementVisible(true);
    };

    useImperativeHandle(ref, () => ({
        // 现在，handleSubmit 可以通过 ref 被父组件调用
        handleSubmit: exposedSubmit,
    }));

    useImperativeHandle(elementRef, () => ({
        showElement: showElement,
    }));


    return (<BaseDragableElement elementData={elementData}
        isEditable={isEditable}
        handleFocusItem={handleFocusItem}
        handleResize={handleResize}
        handleDragStop={handleDragStop}
        handleDelete={handleDelete}>
        <div
            style={gridStyle as React.CSSProperties}
            className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
        >
            {newButtons.map((btn, index) => (
                <div
                    key={btn.btnObj!.id ? btn.btnObj!.id : index}
                    onClick={() => {
                        if (isSubmitted && correctAnswer.length > 0) return;
                        handleSelect(index);
                    }}
                    className={btnGroupStyles.btnItem}
                >
                    <ButtonRegion
                        key={btn.btnObj!.id}
                        elementData={btn.btnObj! as ElementData<ButtonRegionContent>}
                        isEditable={isEditable}
                        handleFocusItem={handleFocusItem}
                        handleResize={handleResize}
                        handleDragStop={handleDragStop}
                        handleDelete={handleDelete}
                        btnStatus={btn.status}
                    />
                </div>

            ))}
            {
                correctAnswer?.length == 0 && inputable && <Input
                    placeholder="请输入"
                    style={{ width: '100%', height: '100%' }}
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onClick={resetBtnStatusAndUserSelect}
                />
            }
            {
                commonStatusStore.isSubmitted && correctAnswer.length > 0 &&
                <div className={styles.resultEvalBox}>
                    <div className={styles.evalIcon}>
                        <img src={isResultCorrect ? AnswerCorrectIcon : AnswerWrongIcon} />
                    </div>
                    <div className={styles.evalTextBox}>
                        <div className={styles.backPage}>
                            {
                                showBackPageIcon && (
                                    <img
                                        src={EvalBackPageIcon}
                                        onClick={() => {
                                            currentPageIndexRef.current = Math.max(currentPageIndexRef.current - 1, 0);
                                            currentPageContentRef.current = pagesRef.current[currentPageIndexRef.current];
                                            setDisplayedText(currentPageContentRef.current);
                                            setShowNextPageIcon(true);
                                            if (currentPageIndexRef.current === 0) {
                                                setShowBackPageIcon(false);
                                            }
                                        }}
                                    />
                                )
                            }
                        </div>
                        <div
                            className={styles.textBox}
                            style={{
                                color: elementData.content.evalTextColor || "#000",
                                fontWeight: elementData.content.evalTextFontWeight || 500,
                            }}
                        >
                            <div className={styles.evalText}>
                                {displayedText}
                            </div>
                        </div>
                        <div className={styles.nextPage}>
                            {
                                showNextPageIcon && (
                                    <img
                                        src={EvalNextPageIcon}
                                        onClick={() => {
                                            currentPageIndexRef.current = Math.min(currentPageIndexRef.current + 1, pagesRef.current.length - 1);
                                            currentPageContentRef.current = pagesRef.current[currentPageIndexRef.current];
                                            setDisplayedText(currentPageContentRef.current);
                                            setShowBackPageIcon(true);
                                            if (currentPageIndexRef.current === pagesRef.current.length - 1) {
                                                setShowNextPageIcon(false);
                                            }
                                        }}
                                    />
                                )
                            }
                        </div>
                    </div>
                    {autoplayEvalText &&
                        <div className={styles.evalTextAudio}>
                            <img
                                src={isEvalTextPlaying ? SoundPlayingIcon : PlayTTSV2Icon}
                                onClick={playEvalTextAudio}
                            />
                        </div>
                    }
                </div>
            }
        </div>
        {
            showSubmitBtn && <div>
                <Button
                    onClick={async() => {
                        const result = await handleSubmit();
                        console.log('result', result);
                        commonStatusStore.setIsSubmitted(true);
                        setIsResultCorrect(result!);
                    }}
                    className={btnGroupStyles.btnGrpOperationBtn}
                >
                    提交
                </Button>
            </div>
        }
        {showRewardAnimation &&
            <RewardAnimation
                rewardType="coins"
                rewardNum={rewardedCoinsNum}
            />
        }
    </BaseDragableElement>)
});

BtnTextGroupRegion.displayName = 'BtnTextGroupRegion';



export default observer(BtnTextGroupRegion);
