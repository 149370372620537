
import { v4 as uuidv4 } from 'uuid';

let lastGeneratedTime = Date.now();
export function genObjectIDbyTS() {
    let currentTime = Date.now();
    if (currentTime === lastGeneratedTime) {
        // 等待直到 milliseconds 增加  
        while (currentTime === lastGeneratedTime) {
            currentTime = Date.now();
        }
    }
    lastGeneratedTime = currentTime;

    const randomNum = Math.floor(Math.random() * 1000);
    return Number(`${currentTime}${randomNum}`);
}


export function genUUIDv4() {
    return uuidv4();
}

