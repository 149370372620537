import { useState } from 'react';
import styles from './clearRecordModal.module.less';
import { Modal, Button, InputNumber, message } from 'antd';
import service from '@/services/axios';
import EmptyIcon from '@/assets/img/qingkong.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';


interface ClearRecordModalProps {
    userId: number;
    sectionId: number;
    currentPageInCourse?: number;
}

const ClearRecordModal: React.FC<ClearRecordModalProps> = ({ userId, sectionId, currentPageInCourse }) => {
    const [open, setOpen] = useState(false);
    const [btnLoading, setBtnLoading] = useState({ part: false, all: false });
    const [partValue, setPartValue] = useState(1);
    const { lectureStore } = useStores();

    const clearRecords = async (offset: number) => {
        const key = offset >= 0 ? 'part' : 'all';
        if (btnLoading) {
            setBtnLoading({ ...btnLoading, [key]: true });
        }

        let res: any = await service.post(`/learn_section/${userId}/${sectionId}`);
        if (res.status === "success") {
            let sectionTrace = res.data.section_trace;
            // 修改 section_trace 的 traces，根据 isPartialClear 决定清除方式  
            // console.log("offset", offset);
            sectionTrace.traces = offset >= 0 ?
                sectionTrace.traces.slice(0, offset) : [];
            res = await service.put('section_trace', sectionTrace);
            if (res.status === "success") {
                message.success(offset >= 0 ? `已清空${offset}页之后的记录` : "学习记录已清空");
                lectureStore.resetSpecifiedLearnedBlock(offset);
                if (btnLoading) {
                    setOpen(false);
                }
            } else {
                message.error("清除失败");
                console.error("Failed to clear chat record");
            }
        } else {
            message.error("清除失败");
            console.error("Failed to clear chat record");
        }
        if (btnLoading) {
            setBtnLoading({ ...btnLoading, [key]: false });
        }
    };

    return (
        <div>
            <Modal
                title="清除上课记录"
                open={open}
                onCancel={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(false);
                }}
                centered
                footer={null}
            >
                {
                    currentPageInCourse !== undefined ? <div>
                        <p>是否清空第{currentPageInCourse + 1}页之后的记录（包括当前页）？</p>
                        <Button
                            type="primary"
                            loading={btnLoading.part}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                clearRecords(currentPageInCourse + 1);
                            }}
                        >
                            确定
                        </Button>
                    </div> : (
                        <div>
                            <div className={styles.clearWay}>
                                <p>1、部分清除</p>
                                <InputNumber
                                    min={1}
                                    onChange={(value) => setPartValue(value ?? 1)}
                                    value={partValue}
                                />
                                <p>页之后的将被清除</p>
                                <Button
                                    type="primary"
                                    loading={btnLoading.part}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        clearRecords(partValue);
                                    }}
                                >
                                    确定
                                </Button>
                            </div>
                            <div className={styles.clearWay}>
                                <p>2、全部清除</p>
                                <Button
                                    type="primary"
                                    loading={btnLoading.all}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        clearRecords(-1);
                                    }}
                                >
                                    确定
                                </Button>
                            </div>
                        </div>
                    )
                }

            </Modal>
            <div
                className={styles.clearBtn}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                <img src={EmptyIcon} alt="" />
            </div>
        </div>
    );
};

export default observer(ClearRecordModal);