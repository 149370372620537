import { InputNumber, Button, Input, Radio, Switch, Select } from 'antd';
import styles from './lineConnectorModifier.module.less';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import TemplateFileSelector from '@/components/TemplateFileSelector/TemplateFileSelector';
import CMSAPI from '@/api/cms';
import { genObjectIDbyTS } from '@/utils/id_generator';
import AdvancedButtonRegionModifier from '../ButtonRegionModifier/AdvancedButtonRegionModifier';

import { ElementData } from '@/base/ElementData/ElementData';
import { LineConnectorRegionContent, FirstGroupItem, SecondGroupItem } from '@/base/ElementData/LineConnectorRegionContent';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import DataDefinition from '@/base/DataDefinition';
import { simplifyFileName } from '@/utils/utils';
import { initElementContentFromTemplate } from '@/base/DataObjectGenerator';

interface LineConnectorModifierProps {
    focusedItem: ElementData<LineConnectorRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    onOptionChange: (id: number, key: string, btnId: number, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};

const LineConnectorModifier: React.FC<LineConnectorModifierProps> = ({
    focusedItem,
    onInputChange,
    onOptionChange,
    changeZIndex
}) => {
    const [firstGroupFileSelectorOpen, setFirstGroupFileSelectorOpen] = useState(false);
    const [secondGroupFileSelectorOpen, setSecondGroupFileSelectorOpen] = useState(false);
    const [firstGroup, setFirstGroup] = useState((focusedItem.content.firstGroup));
    const [firstGroupItemNum, setFirstGroupItemNum] = useState(focusedItem.content.firstGroup.length);
    const [secondGroup, setSecondGroup] = useState((focusedItem.content.secondGroup));
    const [secondGroupItemNum, setSecondGroupItemNum] = useState(focusedItem.content.secondGroup.length);
    const [currentItemId, setCurrentItemId] = useState(0);

    const updateGroupItemNum = (items: (FirstGroupItem | SecondGroupItem)[], pairsNum: number, key: string) => {
        const newItems = cloneDeep(items);
        if (pairsNum > newItems.length) {
            const templateItem = newItems[0];
            const newItemsLength = newItems.length;
            for (let i = 0; i < pairsNum - newItemsLength; i++) {
                const newItem = cloneDeep(templateItem);
                newItem.id = genObjectIDbyTS();
                newItem.value = `${key === 'firstGroup' ? 'group1' : 'group2'}_item${newItemsLength + i + 1}`;
                if (newItem.btnObj && Object.keys(newItem.btnObj).length !== 0) {
                    newItem.btnObj.id = genObjectIDbyTS();
                    const validKeys = ['textRegions', 'imgRegions', 'buttons'] as const;
                    validKeys.forEach(key => {
                        if (newItem.btnObj !== null && Array.isArray(newItem.btnObj.content.children[key])) {
                            for (let i = 0; i < newItem.btnObj.content.children[key].length; i++) {
                                newItem.btnObj.content.children[key][i].id = genObjectIDbyTS();
                            }
                        }
                    });
                }
                newItems.push(newItem);
            }
        } else {
            newItems.splice(pairsNum, newItems.length - pairsNum);
        }
        onInputChange(focusedItem.id, key, newItems);
        return newItems;
    };

    const updateFirstAndSecondGroup = (pairsNum: number, key: string) => {
        if (pairsNum === 0) return;
        if (key === 'firstGroup') {
            setFirstGroupItemNum(pairsNum);
            const newFirstGroup = updateGroupItemNum(firstGroup, pairsNum, 'firstGroup') as FirstGroupItem[];
            setFirstGroup(newFirstGroup);
        } else if (key === 'secondGroup') {
            setSecondGroupItemNum(pairsNum);
            const newSecondGroup = updateGroupItemNum(secondGroup, pairsNum, 'secondGroup') as SecondGroupItem[];
            setSecondGroup(newSecondGroup);
        }
    }
    const handleItemChange = <T extends FirstGroupItem | SecondGroupItem>(
        items: T[],
        key: keyof T,
        value: any,
        id: number,
        fatherKey: string
    ) => {
        const newItems = cloneDeep(items);
        const index = newItems.findIndex(item => item.id === id);
        newItems[index][key] = value;
        onInputChange(focusedItem.id, fatherKey, newItems);
        if (fatherKey === 'firstGroup') {
            setFirstGroup(newItems as FirstGroupItem[]);
        } else if (fatherKey === 'secondGroup') {
            setSecondGroup(newItems as SecondGroupItem[]);
        }
    }

    const showItemFileSelector = (id: number, key: string) => {
        if (key === "firstGroup") {
            setFirstGroupFileSelectorOpen(true);
        } else if (key === "secondGroup") {
            setSecondGroupFileSelectorOpen(true);
        }
        setCurrentItemId(id);
    }

    const changeItemBtnObj = async (templateObj: any, items: (FirstGroupItem | SecondGroupItem)[], key: string) => {
        const api = new CMSAPI();
        const templateJson = await api.readJson(templateObj.fullName);
        //把templateJson中的template这个对象的position_x和position_y设置为0，

        const advContent = initElementContentFromTemplate(DataDefinition.toCamelCaseObj(templateJson.template) as AdvancedButtonRegionContent,
            AdvancedButtonRegionContent, 0, 0, 0);
        advContent.resetChildrenId();

        const obj = new ElementData<AdvancedButtonRegionContent>({
            id: genObjectIDbyTS(),
            type: 104,
            content: advContent,
            childrenLevel: true
        });
        const newItems = cloneDeep(items);
        // 找到newItems中id和currentItemId相同的那一项，将其btnObj属性设置为obj
        const index = newItems.findIndex(item => item.id === currentItemId);
        newItems[index].btnObj = obj;
        newItems[index].templateUrl = templateObj.fullName;
        onInputChange(focusedItem.id, key, newItems);
        if (key === 'firstGroup') {
            setFirstGroup(newItems as FirstGroupItem[]);
        } else if (key === 'secondGroup') {
            setSecondGroup(newItems as SecondGroupItem[]);
        }
    }

    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否必须全部连线完成才能进入下一页</div>
                <Switch
                    checked={focusedItem.content.shouldComplete}
                    onChange={value => onInputChange(focusedItem.id, 'shouldComplete', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>连接模式</div>
                <Select
                    value={focusedItem.content.connectionMode}
                    onChange={value => onInputChange(focusedItem.id, 'connectionMode', value)}
                >
                    <Select.Option value="one_to_one">一对一</Select.Option>
                    <Select.Option value="one_to_many">一对多</Select.Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>group1数量</div>
                <InputNumber
                    value={firstGroupItemNum}
                    onChange={value => setFirstGroupItemNum(value ?? 0)}
                />
                <Button onClick={() => updateFirstAndSecondGroup(firstGroupItemNum, 'firstGroup')}>确定</Button>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>group2数量</div>
                <InputNumber
                    value={secondGroupItemNum}
                    onChange={value => setSecondGroupItemNum(value ?? 0)}
                />
                <Button onClick={() => updateFirstAndSecondGroup(secondGroupItemNum, 'secondGroup')}>确定</Button>
            </div>

            <div>
                {firstGroup.map((item, index) => {
                    return (
                        <div key={index}>
                            <h2>第一列 item{index + 1}</h2>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>value(必填且每项不一样)</div>
                                <Input
                                    value={item.value}
                                    onChange={e => handleItemChange(firstGroup, 'value', e.target.value, item.id, 'firstGroup')}
                                />
                            </div>
                            <Button type="primary" onClick={() => showItemFileSelector(item.id, "firstGroup")}>
                                选择模板
                            </Button>
                            <div className={styles.modifyItem}>
                                <div className={styles.templateTitle}>模版名称</div>
                                <div className={styles.templateName}>
                                    {simplifyFileName(item.templateUrl)}
                                </div>
                            </div>
                            {item.btnObj && Object.keys(item.btnObj).length !== 0 && <AdvancedButtonRegionModifier
                                focusedItem={item.btnObj}
                                onInputChange={onInputChange}
                                onOptionChange={onOptionChange}
                                changeZIndex={changeZIndex}
                            >
                            </AdvancedButtonRegionModifier>}
                        </div>
                    )
                })}
            </div>

            <div>
                {secondGroup.map((item, index) => {
                    return (
                        <div key={index}>
                            <h2>第二列 item{index + 1}</h2>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>value(必填且每项不一样)</div>
                                <Input
                                    value={item.value}
                                    onChange={e => handleItemChange(secondGroup, 'value', e.target.value, item.id, 'secondGroup')}
                                />
                            </div>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>正确答案</div>
                                <Radio.Group
                                    value={item.correctAnswer}
                                    onChange={e => handleItemChange(secondGroup, 'correctAnswer', e.target.value, item.id, 'secondGroup')}
                                >
                                    {firstGroup.map((item, index) => {
                                        return (
                                            <Radio key={index} value={item.value}>{item.value}</Radio>
                                        )
                                    })}
                                </Radio.Group>
                            </div>
                            <Button type="primary" onClick={() => showItemFileSelector(item.id, "secondGroup")}>
                                选择模板
                            </Button>
                            <div className={styles.modifyItem}>
                                <div className={styles.templateTitle}>模版名称</div>
                                <div className={styles.templateName}>
                                    {simplifyFileName(item.templateUrl)}
                                </div>
                            </div>
                            {item.btnObj && Object.keys(item.btnObj).length !== 0 && <AdvancedButtonRegionModifier
                                focusedItem={item.btnObj}
                                onInputChange={onInputChange}
                                onOptionChange={onOptionChange}
                                changeZIndex={changeZIndex}
                            >
                            </AdvancedButtonRegionModifier>}
                        </div>
                    )
                })}
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
            <TemplateFileSelector
                fileSelectorOpen={firstGroupFileSelectorOpen}
                setFileSelectorOpen={setFirstGroupFileSelectorOpen}
                afterSelectionCallback={templateObj => changeItemBtnObj(templateObj, firstGroup, 'firstGroup')}
                templateType={104}
            />
            <TemplateFileSelector
                fileSelectorOpen={secondGroupFileSelectorOpen}
                setFileSelectorOpen={setSecondGroupFileSelectorOpen}
                afterSelectionCallback={templateObj => changeItemBtnObj(templateObj, secondGroup, 'secondGroup')}
                templateType={104}
            />
        </div>
    )
};

export default LineConnectorModifier;