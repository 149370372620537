import PropTypes from 'prop-types';
import { Button, InputNumber } from 'antd';
import styles from './sunoGenModifier.module.less';
import MediaFileSelectorModifier from '../MediaFileSelectorModifier/MediaFileSelectorModifier';
import URLModifier from '../URLModifier/URLModifier';
import { ElementData } from '@/base/ElementData/ElementData';
import SunoGenContent from '@/base/ElementData/SunoGenContent';

interface SunoGenModifierProps {
    focusedItem: ElementData<SunoGenContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};

const SunoGenModifier: React.FC<SunoGenModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {
    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>背景颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.backgroundColor}
                    onChange={e => {
                        onInputChange(focusedItem.id, 'backgroundColor', e.target.value);
                        onInputChange(focusedItem.id, 'backgroundImage', '');
                    }}
                    className={styles.colorBoard}
                />
            </div>
            <MediaFileSelectorModifier
                mediaType='images'
                afterSelectionCallback={(url) => onInputChange(focusedItem.id, 'backgroundImage', url)}
                btnText='背景图片'
            />
            <URLModifier
                urlTitle='背景图片url'
                value={focusedItem.content.backgroundImage}
                changeValue={(value) => onInputChange(focusedItem.id, 'backgroundImage', value)}
            />
            {/* <h4>Title MagicGen Prompt</h4>
            <textarea
                type="text"
                value={focusedItem.content.title_prompt}
                onChange={e => onInputChange('title_prompt', focusedItem.id, e.target.value)}
                className={styles.textarea} />
            <h4>Lyrics MagicGen Prompt</h4>
            <textarea
                type="text"
                value={focusedItem.content.lyrics_prompt}
                onChange={e => onInputChange('lyrics_prompt', focusedItem.id, e.target.value)}
                className={styles.textarea} /> */}
            <h4>Genre MagicGen Prompt</h4>
            <textarea
                value={focusedItem.content.genrePrompt}
                onChange={e => onInputChange(focusedItem.id, 'genrePrompt', e.target.value)}
                className={styles.textarea} />
            <div>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
        </div>
    )
};

export default SunoGenModifier;