import {ElementContent} from './ElementData';

class QRCodeRegionContent extends ElementContent {
    backgroundColor: string;
    src: string;
    qrCodeType: string;
    shareType: string;
    payload: any;
    visible: boolean;
    
    constructor({
        positionX = 0,
        positionY = 0,
        width = 160,
        height = 160,
        backgroundColor = "#000000",
        src = "",
        qrCodeType = "preset",
        shareType = "music",
        payload = {},
        visible = true
    }: Partial<QRCodeRegionContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'backgroundColor', 'src', 'qrCodeType', 'shareType', 'payload', 'visible'];
        this.backgroundColor = backgroundColor;
        this.src = src;
        this.qrCodeType = qrCodeType;
        this.shareType = shareType;
        this.payload = payload;
        this.visible = visible;
    }
};

export default QRCodeRegionContent;