import service from '@/services/axios';

// api for content management system

class CMSAPI {

    async storeTemplate(data) {
        try {
            const response = await service.post('/template', data);
            return response.data;
        } catch (error) {
            console.error('Error storing template:', error);
            throw error;
        }
    }

    async readJson(url)   {
        return await fetch(url).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json(); // 将响应解析为 JSON
        }).catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
    }
    
}

export default CMSAPI;