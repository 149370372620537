import React, { useState, useRef, useEffect } from 'react';
import styles from './dragClassifyRegion.module.less';
import PropTypes from 'prop-types';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import AdvancedButtonRegion from '../ButtonRegion/AdvancedButtonRegion';
import { cloneDeep, throttle } from 'lodash';
import service from '@/services/axios';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import DragCorrectSound from '@/assets/audio/correct_prompt_sound.mp3';
import DragWrongSound from '@/assets/audio/wrong_prompt_sound.mp3';

import { IntrinsicElementProps } from '../common/BaseDragableElement';
import { DragClassifyRegionContent, DragItem, CategoryItem } from '@/base/ElementData/DragClassifyRegionContent';
import { BlockTraceData } from '@/base/BlockData';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import { ElementData, ElementContent } from '@/base/ElementData/ElementData';
import RevokeIcon from '@/assets/img/revoke_icon.svg';
import { requestLLMCall } from '@/services/requestLLMCall';
import useCommonFunction from '@/hooks/useCommonFunction';
import LLMCallRequestData from '@/base/LLMCallRequestData';

interface DragClassifyRegionProps extends IntrinsicElementProps<DragClassifyRegionContent> {
    blockTraceData: BlockTraceData;
}

const DragClassifyRegion: React.FC<DragClassifyRegionProps> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete,
    blockTraceData }) => {
    const { lectureStore, commonStatusStore, userInfoStore, viewportStore } = useStores();
    const isTeacher = userInfoStore.isTeacherView();
    const thingBeingDraggedRef = useRef<DragItem | null>(null); // Store the current dragged thing  
    const [result, setResult] = useState(cloneDeep(elementData.content.result) || {});
    const [hasCorrectAnswer, setHasCorrectAnswer] = useState(false);//是否有正确答案
    let dragOffset = { x: 0, y: 0, x_offset: 0, y_offset: 0 };
    let previousCategoryElem: any = null;
    const dragCorrectSoundRef = useRef(new Audio(DragCorrectSound));//拖拽正确的音效
    const dragWrongSoundRef = useRef(new Audio(DragWrongSound));//拖拽错误的音效
    const isDraggedIntoCategoryRef = useRef(false);
    const { classificationMode } = elementData.content;
    const { executeFunc } = useCommonFunction();
    //是否已经调用LLM
    const [isLLMDone, setIsLLMDone] = useState(false);

    let categoriesNum = elementData.content.categories.length;
    let elementWidth = elementData.content.width * viewportStore.scaleRatio;
    let categoryBoxWidth = elementWidth * 0.9;
    let categoryWidth = categoryBoxWidth / categoriesNum;
    let categoryHeight = viewportStore.scaleRatio === 1 ? 160 : 96;

    useEffect(() => {
        //通过判断elementData.content.things的每一项的correctCategory是否全部不为“”来判断是否有正确答案
        const hasCorrectAnswer = elementData.content.things.every(item => item.correctCategory !== "");
        setHasCorrectAnswer(hasCorrectAnswer);
    }, [])

    const renderResult = (result: any) => {
        // 使用 Object.keys 遍历 result 对象的每一个键  
        Object.keys(result).forEach(key => {

            result[key] = result[key].map((newThing: DragItem) => {
                const updatedThing = { ...newThing, btnObj: { ...newThing.btnObj } };
                const btnObjContent = updatedThing.btnObj.content;

                if (btnObjContent) {
                    const originalWidth = btnObjContent.width;
                    const originalHeight = btnObjContent.height;

                    // 避免除以零的情况  
                    if (originalWidth === 0 || originalHeight === 0) {
                        return updatedThing;
                    }

                    // 计算缩放比例  
                    let scaleByWidth = 1;
                    let scaleByHeight = 1;

                    if (result[key].length <= 4 && result[key].length > 0) {
                        scaleByWidth = (categoryWidth / result[key].length) / originalWidth;
                        scaleByHeight = categoryHeight / originalHeight;
                    } else if (result[key].length > 4) {
                        scaleByWidth = (categoryWidth / 4) / originalWidth;
                        scaleByHeight = categoryHeight / 2 / originalHeight;
                    }

                    // 选取最小的缩放比例
                    const scale = Math.min(scaleByWidth, scaleByHeight);

                    // 更新 btnObj 的 content width 和 height  
                    btnObjContent.width *= scale;
                    btnObjContent.height *= scale;

                    // 更新 btnObj 的 children 中的子元素  
                    const updateChildElement = (child: ElementData<ElementContent>) => {
                        child.content.width *= scale;
                        child.content.height *= scale;
                        child.content.positionX *= scale;
                        child.content.positionY *= scale;
                        // TODO: 这个size好像还没处理
                        //child.content.size *= scale;
                    };

                    // 检查并更新 children 中的元素  
                    if (btnObjContent.children) {
                        if (Array.isArray(btnObjContent.children.buttons)) {
                            btnObjContent.children.buttons.forEach(updateChildElement);
                        }
                        if (Array.isArray(btnObjContent.children.imgRegions)) {
                            btnObjContent.children.imgRegions.forEach(updateChildElement);
                        }
                        if (Array.isArray(btnObjContent.children.textRegions)) {
                            btnObjContent.children.textRegions.forEach(updateChildElement);
                        }
                    }
                }
                // 返回更新后的 newThing  
                return updatedThing;
            });
        });

        // 返回更新后的 result 对象  
        return result;
    }

    const handleMouseDown = (thing: DragItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>, ref: React.RefObject<unknown>) => {
        initDrag(thing, e.clientX, e.clientY, ref);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleTouchStart = (thing: DragItem, e: React.TouchEvent<HTMLDivElement>, ref: React.RefObject<unknown>) => {
        const touch = e.touches[0];
        initDrag(thing, touch.clientX, touch.clientY, ref);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
    };

    const initDrag = (thing: DragItem, clientX: number, clientY: number, ref: React.RefObject<unknown>) => {
        if ((hasCorrectAnswer || classificationMode === "one_to_one") && isThingClassified(thing.id)) return;
        const newThing = cloneDeep(thing);

        newThing.elementRef = ref.current;

        // 添加透明度和禁用指针事件，让被拖动元素不可被点击到  
        newThing.elementRef.style.pointerEvents = 'none'; // 禁用指针事件  
        newThing.elementRef.style.border = '2px solid #51ADFF'; // 添加虚线边框  

        thingBeingDraggedRef.current = newThing;
        dragOffset = { x: clientX, y: clientY, x_offset: 0, y_offset: 0 };

        // 用于调试：打印被拖拽的对象  
        console.log('Start Drag:', newThing);
    }

    const handleMouseMove = (e: any) => {
        handleMove(e.clientX, e.clientY);
    };

    const handleTouchMove = (e: any) => {
        e.preventDefault();
        const touch = e.touches[0];
        handleMove(touch.clientX, touch.clientY);
    };

    const handleMove = (clientX: number, clientY: number) => {
        const thingBeingDragged = thingBeingDraggedRef.current; // 使用 ref  
        if (thingBeingDragged && thingBeingDragged.elementRef) {
            const newOffsetX = clientX - dragOffset.x;
            const newOffsetY = clientY - dragOffset.y;
            dragOffset = { x: clientX, y: clientY, x_offset: dragOffset.x_offset + newOffsetX, y_offset: dragOffset.y_offset + newOffsetY };

            thingBeingDragged.elementRef.style.transform = `translate(${dragOffset.x_offset}px, ${dragOffset.y_offset}px)`;

            const dropTarget = document.elementFromPoint(clientX, clientY);
            if (dropTarget) {
                const categoryElem: any = dropTarget.closest(`.${styles.category}`);
                if (categoryElem) {
                    //const categoryId = parseInt(categoryElem.getAttribute('data-category-id') || '0', 10);
                    const categoryId = categoryElem.getAttribute('data-category-id');
                    const category = elementData.content.categories.find(item => item.id == categoryId);

                    if (previousCategoryElem && previousCategoryElem !== categoryElem) {
                        // 恢复前一个类别的样式  
                        previousCategoryElem.style.backgroundColor = "";
                        previousCategoryElem.style.border = "";
                        // 重置状态变量  
                        isDraggedIntoCategoryRef.current = false;
                    }

                    // 如果拖拽的元素的正确分类和当前分类不一致，就改变背景和边框颜色  
                    if (thingBeingDragged.correctCategory !== category?.value && hasCorrectAnswer) {
                        categoryElem.style.backgroundColor = "#FFD5D7";
                        categoryElem.style.border = '4px solid #FF626C'; // 添加边框  
                        previousCategoryElem = categoryElem; // 更新前一个类别  
                        if (!isDraggedIntoCategoryRef.current) {
                            playDragWrongSoundDebounce();
                            isDraggedIntoCategoryRef.current = true;
                        }
                    } else {
                        // 如果正确，恢复背景和边框颜色  
                        categoryElem.style.backgroundColor = "";
                        categoryElem.style.border = "";
                        previousCategoryElem = null; // 清除前一个类别  
                        if (!isDraggedIntoCategoryRef.current) {
                            playDragCorrectSoundDebounce();
                            isDraggedIntoCategoryRef.current = true;
                        }
                    }
                } else {
                    if (previousCategoryElem) {
                        previousCategoryElem.style.backgroundColor = ""; // 清除错误背景颜色  
                        previousCategoryElem.style.border = ""; // 清除边框  
                    }
                    isDraggedIntoCategoryRef.current = false; // 重置状态变量
                }
            }
        }
    };

    const handleMouseUp = (e: any) => {
        handleDropEnd(e.clientX, e.clientY);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleTouchEnd = (e: any) => {
        e.preventDefault();
        const touch = e.changedTouches[0];
        handleDropEnd(touch.clientX, touch.clientY);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
    };

    const resetThingDivStyle = (divRef: any) => {
        divRef.style.transform = 'translate(0px, 0px)';
        divRef.style.border = '2px solid transparent';
    }

    const handleDropEnd = (clientX: number, clientY: number) => {
        const thingBeingDragged = thingBeingDraggedRef.current;
        if (thingBeingDragged) {
            const dropTarget = document.elementFromPoint(clientX, clientY);
            if (dropTarget) {
                const categoryElem: any = dropTarget.closest(`.${styles.category}`);
                if (categoryElem) {
                    const categoryId = categoryElem.getAttribute('data-category-id');
                    const category = elementData.content.categories.find(item => item.id == categoryId);
                    if ((thingBeingDragged.correctCategory === category?.value && hasCorrectAnswer) || !hasCorrectAnswer) {
                        if (category) {
                            handleDrop(category, clientX, clientY);
                        } else {
                            resetThingDivStyle(thingBeingDragged.elementRef);
                        }
                    } else {
                        resetThingDivStyle(thingBeingDragged.elementRef);
                    }
                } else {
                    resetThingDivStyle(thingBeingDragged.elementRef);
                }
            } else {
                resetThingDivStyle(thingBeingDragged.elementRef);
            }

            thingBeingDragged.elementRef.style.opacity = 1; // 恢复不透明  
            thingBeingDragged.elementRef.style.pointerEvents = 'auto'; // 恢复指针事件  
            if (previousCategoryElem) {
                previousCategoryElem.style.backgroundColor = ""; // 清除错误背景颜色  
                previousCategoryElem.style.border = ""; // 清除边框  
            }
        }

        thingBeingDraggedRef.current = null;
        dragOffset = { x: 0, y: 0, x_offset: 0, y_offset: 0 };
        isDraggedIntoCategoryRef.current = false;//重置状态变量
    };

    const handleDrop = async (category: CategoryItem, clientX: number, clientY: number) => {
        const thingBeingDragged = thingBeingDraggedRef.current;
        if (thingBeingDragged) {
            // 判断分类模式  
            if (!result[category.value]) {
                result[category.value] = [];
            }
            const isAlreadyClassified = result[category.value].some((thing: DragItem) => thing.id === thingBeingDragged.id);
            if (!isAlreadyClassified) {
                result[category.value].push(thingBeingDragged);
                setResult({ ...result }); // 更新状态以触发重新渲染  
            }

            // 重置位置并设置透明度为0  
            resetThingDivStyle(thingBeingDragged.elementRef);
            thingBeingDragged.elementRef.style.opacity = 0;
            thingBeingDraggedRef.current = null;

            if (!isTeacher) {
                let newResult = cloneDeep(result);
                updateResultAndNotifyStore(newResult, elementData, blockTraceData);
                // 如果全部分类完成,并且有postPrompt,则调用LLM
                if (isClassifyFinished(newResult)) {
                    if (elementData.content.postPrompt && elementData.content.postPrompt !== "" && !isLLMDone) {
                        let prompt = elementData.content.convertToText() + elementData.content.postPrompt;
                        const request = LLMCallRequestData.genLLMCallParams(prompt,
                            userInfoStore.userInfoData.id, userInfoStore.userInfoData.name, false);
                        console.log(request);
                        setIsLLMDone(true);
                        const response: any = await requestLLMCall(request);
                        const res = await response.json();
                        if (elementData.content.postFunctionCall?.funcName) {
                            if (elementData.content.postFunctionCall && elementData.content.postFunctionCall.funcParams) {
                                elementData.content.postFunctionCall.funcParams['newWords'] = res.data.content;
                            }
                            executeFunc(elementData.content.postFunctionCall?.funcName, elementData.content.postFunctionCall?.funcParams);
                        }
                    }
                }
            }
        }
    };

    const updateResultAndNotifyStore = (
        newResult: Record<string, DragItem[]>,
        elementData: ElementData<DragClassifyRegionContent>,
        blockTraceData: BlockTraceData
    ) => {
        // 删除result中每一个元素的elementRef属性  
        for (const categoryValue in newResult) {
            newResult[categoryValue].forEach((thing: { elementRef: any; }) => {
                delete thing.elementRef;
            });
        }

        const newContent = new DragClassifyRegionContent({ ...elementData.content });
        newContent.result = newResult;

        lectureStore.updateElement(blockTraceData.id, elementData.id, newContent);
        const newBlockTraceData = cloneDeep(blockTraceData);
        const currentElementData = newBlockTraceData.traceInfo.find(item => item.id === elementData.id);
        if (currentElementData && 'result' in currentElementData.content) {
            (currentElementData.content as DragClassifyRegionContent).result = newResult;
        }
        service.put('/block_trace', newBlockTraceData);
    }

    //取消一个元素的分类
    const cancelThingClassification = (thing: DragItem, category: CategoryItem) => {
        const newResult = cloneDeep(result);
        newResult[category.value] = newResult[category.value].filter((thingInResult: DragItem) => thingInResult.id !== thing.id);
        setResult(newResult);

        if (elementData.content.shouldComplete) {
            commonStatusStore.setIsClassifyFinished(false);
        }

        if (!isTeacher) {
            updateResultAndNotifyStore(newResult, elementData, blockTraceData);
        }
    };


    const isThingClassified = (thingId: number) => {
        for (const categoryValue in result) {
            if (result[categoryValue].some((thing: { id: number; }) => thing.id === thingId)) {
                return true;
            }
        }
        return false;
    };

    // 函数用于播放音频并限制其播放时间
    const playDragCorrectSound = () => {
        const audio = dragCorrectSoundRef.current;
        audio.currentTime = 0;
        audio.play();

        setTimeout(() => {
            if (audio.duration > 2) {
                audio.pause();
                audio.currentTime = 0;
            }
        }, 1000); // 最多播放1秒
    };

    const playDragWrongSound = () => {
        const audio = dragWrongSoundRef.current;
        audio.currentTime = 0;
        audio.play();

        setTimeout(() => {
            if (audio.duration > 2) {
                audio.pause();
                audio.currentTime = 0;
            }
        }, 1000); // 最多播放1秒
    };

    // 防抖处理，防止连续播放
    const playDragCorrectSoundDebounce = throttle(playDragCorrectSound, 1000, { leading: true, trailing: false });
    const playDragWrongSoundDebounce = throttle(playDragWrongSound, 1000, { leading: true, trailing: false });

    //在有正确答案的情况下，判断是否分类完成
    const isClassifyFinished = (result: Record<string, DragItem[]>) => {
        // 如果有正确答案，判断是否所有元素都被分类  
        if (hasCorrectAnswer) {
            let draggedThings = 0;
            for (const categoryValue in result) {
                draggedThings += result[categoryValue].length;
            }

            return elementData.content.things.length === draggedThings;
        } else {
            // 如果没有正确答案，判断是否所有元素都被至少分过一次  
            // 通过判断things的每一个是否都在result中出现过  
            return elementData.content.things.every((thing: DragItem) => {
                // 检查每个thing的id是否在result中  
                return Object.values(result).some((categoryItems: DragItem[]) =>
                    categoryItems.some((item: DragItem) => item.id === thing.id)
                );
            });
        }
    };
    useEffect(() => {
        //如果全部分类完成，设置isClassifyFinished为true，
        if (!elementData.content.shouldComplete || classificationMode === "one_to_many") {
            commonStatusStore.setIsClassifyFinished(true);
        } else {
            commonStatusStore.setIsClassifyFinished(isClassifyFinished(result));
        }

        return () => {
            commonStatusStore.setIsClassifyFinished(false);
        }

    }, [cloneDeep(result)]);

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) handleFocusItem(elementData, e); }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={styles.dragClassifyBox} style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: `${elementData.content.borderRadius}px`,
                    touchAction: "none",
                }}
                >
                    <div className={styles.thingsBox}>
                        {elementData.content.things.map((item, index) => {
                            const itemRef = React.createRef<HTMLDivElement>();
                            return (
                                <div key={index} className={styles.thingBackground}>
                                    <div
                                        className={styles.thing}
                                        ref={itemRef}
                                        onMouseDown={(e) => handleMouseDown(item, e, itemRef)}
                                        onTouchStart={(e) => handleTouchStart(item, e, itemRef)}
                                        onTouchEnd={handleTouchEnd}
                                        style={{ opacity: isThingClassified(item.id) && classificationMode !== "one_to_many" ? 0 : 1 }}
                                    >
                                        <AdvancedButtonRegion
                                            elementData={item.btnObj!}
                                            isEditable={isEditable}
                                            handleFocusItem={handleFocusItem}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.categoryBox}>
                        {elementData.content.categories.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={styles.categoryItem}
                                >
                                    <div
                                        className={styles.category}
                                        data-category-id={item.id}
                                        style={{
                                            width: `${categoryWidth}px`,
                                            height: `${categoryHeight}px`,
                                        }}
                                    >
                                        {renderResult(result)[item.value] && renderResult(result)[item.value].map((thing: DragItem, index: number) => {
                                            return (
                                                <div key={index} className={styles.resultItem}>
                                                    <AdvancedButtonRegion
                                                        elementData={thing.btnObj!}
                                                        isEditable={isEditable}
                                                        handleFocusItem={handleFocusItem}
                                                    />
                                                    <div
                                                        className={styles.cancelIcon}
                                                        onClick={() => cancelThingClassification(thing, item)}
                                                    >
                                                        <img src={RevokeIcon} alt="cancel" />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.categoryName}>
                                        <AdvancedButtonRegion
                                            elementData={item.btnObj!}
                                            isEditable={isEditable}
                                            handleFocusItem={handleFocusItem}
                                        />
                                    </div>
                                </div>

                            );
                        })}
                    </div>
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default observer(DragClassifyRegion);