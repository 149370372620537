import { useState, useEffect } from 'react';
import styles from './mediaFileSelector.module.less';
import { Drawer, Button, Space, Select } from 'antd';
import service from '@/services/axios';
import UploadIcon from '@/assets/img/shangchuan.svg';
import UploadMedia from '../UploadMedia/UploadMedia';
import MediaFileItem from './MediaFileItem';
import { Input } from 'antd';

const { Search } = Input;

interface MediaFileSelectorProps {
    fileSelectorOpen: boolean;
    setFileSelectorOpen: (open: boolean) => void;
    afterSelectionCallback: (obj: any) => void;
    mediaType: string;
}

interface MediaFile {
    file_url: string;
    last_modified: Date;
}

const MediaFileSelector: React.FC<MediaFileSelectorProps> = ({
    fileSelectorOpen,
    setFileSelectorOpen,
    afterSelectionCallback,
    mediaType }) => {
    const [mediaFileList, setMediaFileList] = useState<MediaFile[]>([]);//保存当前展示的素材文件
    const [allMediaFileList, setAllMediaFileList] = useState<MediaFile[]>([]);//保存所有的素材文件，用于搜索功能
    const [sortMethod, setSortMethod] = useState('time_desc');//保存当前的排序方式

    const accept_str = () => {
        switch (mediaType) {
            case 'images': return 'image/png,image/jpg,image/jpeg,image/gif,image/svg+xml';
            case 'videos': return 'video/mp4';
            case 'audios': return 'audio/mpeg';
            default: return '';
        }
    };

    useEffect(() => {
        if (fileSelectorOpen && mediaType) {
            getMediaFileList();
        }
        return () => {
            setMediaFileList([]);
            setAllMediaFileList([]);
        }
    }, [fileSelectorOpen, mediaType]);

    const getMediaFileList = async () => {
        try {
            const res: any = await service.get('/fetch_file_urls', { params: { dir: mediaType } });
            if (res.status === 'success') {
                //按照时间降序排列
                let sortedMediaFileList = res.data.files_info.sort((a: any, b: any) => {
                    return new Date(b.last_modified).getTime() - new Date(a.last_modified).getTime();
                });
                setMediaFileList(sortedMediaFileList);
                setAllMediaFileList(res.data.files_info);
            } else {
                setMediaFileList([]);
                setAllMediaFileList([]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleClick = (item: any) => {
        console.log("handleClick", item);
        afterSelectionCallback(item);
        setFileSelectorOpen(false);
        document.body.style.cursor = 'copy';
    };

    //按照输入的关键字搜索素材，把搜索结果展示出来
    const onSearch = (value: string) => {
        if (value === '') {
            setMediaFileList([...allMediaFileList]);
        } else {
            const searchResult = allMediaFileList.filter((item) => item.file_url.includes(value));
            setMediaFileList(searchResult);
        }
    };

    //排序函数，按照时间或者文件名排序
    const handleSort = (value: string) => {
        setSortMethod(value);
        let sortedMediaFileList: MediaFile[] = [...mediaFileList];
        switch (value) {
            case 'time_desc':
                sortedMediaFileList = mediaFileList.sort((a, b) => {
                    return new Date(b.last_modified).getTime() - new Date(a.last_modified).getTime();
                });
                break;
            case 'time_asc':
                sortedMediaFileList = mediaFileList.sort((a, b) => {
                    return new Date(a.last_modified).getTime() - new Date(b.last_modified).getTime();
                });
                break;
            case 'name_desc':
                sortedMediaFileList = mediaFileList.sort((a, b) => {
                    return b.file_url.localeCompare(a.file_url);
                });
                break;
            case 'name_asc':
                sortedMediaFileList = mediaFileList.sort((a, b) => {
                    return a.file_url.localeCompare(b.file_url);
                });
                break;
            default:
                break;
        }
        setMediaFileList(sortedMediaFileList);
    }

    return (
        <Drawer
            title=""
            placement="right"
            closable={true}
            onClose={() => setFileSelectorOpen(false)}
            open={fileSelectorOpen}
            width={580}
            className={styles.mediaFileSelector}
            extra={
                <Space>
                    <Search placeholder="input search text" onSearch={onSearch} enterButton style={{ width: 180 }} />
                    <Select defaultValue={sortMethod} style={{ width: 100 }} onChange={(value) => handleSort(value)}>
                        <Select.Option value="time_desc">时间降序</Select.Option>
                        <Select.Option value="time_asc">时间升序</Select.Option>
                        <Select.Option value="name_desc">名称降序</Select.Option>
                        <Select.Option value="name_asc">名称升序</Select.Option>
                    </Select>
                    <div className={styles.uploadMedia}>
                        <UploadMedia
                            successCallBack={(fileUrl, itemID) => setMediaFileList([{
                                file_url: fileUrl,
                                last_modified: new Date()
                            }, ...mediaFileList])}
                            icon={UploadIcon}
                            btn_text={null}
                            accept_str={accept_str()}
                            dir={mediaType}
                            isMultiple={false}
                        />
                    </div>
                    <Button onClick={() => handleClick("")}>
                        Reset
                    </Button>
                </Space>
            }
        >
            <div className={styles.fileList}>
                {mediaFileList.map((item, index) => {
                    return (
                        <MediaFileItem
                            key={index + item.file_url}
                            fileData={{ url: item.file_url, mediaType: mediaType }}
                            handleClick={handleClick}
                            getMediaFileList={getMediaFileList}
                        />
                    );
                })}
            </div>
        </Drawer>
    );
};

export default MediaFileSelector;