import {ElementContent} from './ElementData';

class SunoGenContent extends ElementContent {
    backgroundColor: string;
    backgroundImage: string;
    titlePrompt: string;
    lyricsPrompt: string;
    genrePrompt: string;
    description: string;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 960,
        height = 580,
        backgroundColor = "#ffffff",
        backgroundImage = "",
        titlePrompt = "",
        lyricsPrompt = "",
        genrePrompt = "",
        description = ""
    }: Partial<SunoGenContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'backgroundColor', 'backgroundImage', 'titlePrompt', 'lyricsPrompt', 
            'genrePrompt', 'description'];
        this.backgroundColor = backgroundColor;
        this.backgroundImage = backgroundImage;
        this.titlePrompt = titlePrompt;
        this.lyricsPrompt = lyricsPrompt;
        this.genrePrompt = genrePrompt;
        this.description = description;
    }
};

export default SunoGenContent;