// componentList.js
import { useState } from 'react';
import PropTypes from 'prop-types';
import TextIcon from '@/assets/img/wenzi.svg';
import ImgIcon from '@/assets/img/tupian.svg';
import BtnIcon from '@/assets/img/anniu.svg';
import AdvancedBtnIcon from '@/assets/img/advancedBtn.svg';
import BtnTextGroupIcon from '@/assets/img/anniuzu.svg';
import BtnImgGroupIcon from '@/assets/img/tupianzu.svg';
import VideoIcon from '@/assets/img/video.svg'
import AudioIcon from '@/assets/img/yinpin.svg';
import MediaFileSelector from '@/components/MediaFileSelector/MediaFileSelector';
import TemplateFileSelector from '../TemplateFileSelector/TemplateFileSelector';
import ResourceFileSelector from '../ResourceFileSelector/ResourceFileSelector';
import BtnTextGroupSelector from '../BtnTextGroupSelector/BtnTextGroupSelector';
import SunoIcon from '@/assets/img/suno-song.svg';
import ChatIcon from '@/assets/img/chat.svg';
import TeacherWordsIcon from '@/assets/img/teacher_speak.svg';
import LineIcon from '@/assets/img/line.svg';
import MagicInputIcon from '@/assets/img/magic_input.svg';
import StarIcon from '@/assets/img/star.svg';
import CodeIcon from '@/assets/img/code.svg';
import TianKongIcon from '@/assets/img/fill_in_the_blank.svg';
import QRCodeIcon from '@/assets/img/qrcode.svg';
import DragClassifyIcon from '@/assets/img/drag_classify.svg';
import ShowCodeIcon from '@/assets/img/html_code.svg';
import FlashCardIcon from '@/assets/img/flash_card.svg';
import LineConnectorIcon from '@/assets/img/line_connector.svg';

interface ToolItem {
    key: number;
    content?: object;
    needSetSrcUrl?: boolean;
    needSetTemplateUrl?: boolean;
    needSetBtnTextGroup?: boolean;
    needSetResourceUrl?: boolean;
    preview?: () => JSX.Element;
}

const toolList = (): ToolItem[] => ([
    {
        preview: () => <img src={TextIcon} alt="Text" />,
        key: 101,
        content: {},
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={ImgIcon} alt="Image" />,
        key: 102,
        needSetSrcUrl: true,
    },
    {
        preview: () => <img src={BtnIcon} alt="Button" />,
        key: 103,
        needSetSrcUrl: false,
        needSetTemplateUrl: true,
    },
    {
        preview: () => <img src={AdvancedBtnIcon} alt="AdvancedButton" />,
        key: 104,
        needSetSrcUrl: false,
        needSetTemplateUrl: true,
    },
    {
        preview: () => <img src={BtnTextGroupIcon} alt="BtnTextGroup" />,
        key: 105,
        needSetSrcUrl: false,
        needSetTemplateUrl: true,
    },
    {
        preview: () => <img src={BtnImgGroupIcon} alt="BtnImgGroup" />,
        key: 106,
        needSetSrcUrl: false,
        needSetTemplateUrl: true,
    },
    {
        preview: () => <img src={VideoIcon} alt="Video" />,
        key: 107,
        needSetSrcUrl: true,
    },
    {
        preview: () => <img src={AudioIcon} alt="Audio" />,
        key: 108,
        needSetSrcUrl: true,
    },
    {
        preview: () => <img src={SunoIcon} alt="Suno" />,
        key: 109,
        content: {},
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={ChatIcon} alt="Chat" />,
        key: 110,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={TeacherWordsIcon} alt="TeacherWords" />,
        key: 111,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={MagicInputIcon} alt="MagicInput" />,
        key: 112,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={LineIcon} alt="Line" />,
        key: 113,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={StarIcon} alt="Star" />,
        key: 114,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={CodeIcon} alt="Code" />,
        key: 115,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={TianKongIcon} alt="TianKong" />,
        key: 116,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={QRCodeIcon} alt="QRCode" />,
        key: 117,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={DragClassifyIcon} alt="DragClassify" />,
        key: 118,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={ShowCodeIcon} alt="ShowCode" />,
        key: 119,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={FlashCardIcon} alt="FlashCard" />,
        key: 120,
        needSetSrcUrl: false,
    },
    {
        preview: () => <img src={LineConnectorIcon} alt="LineConnector" />,
        key: 121,
        needSetSrcUrl: false,
    },
]);

interface BlockEditorToolsPanelProps {
    selectedItem: ToolItem;
    handleSelectItem: (item: ToolItem) => void;
    setSrcUrlMap: { [key: number]: React.Dispatch<React.SetStateAction<any>> };
}

const BlockEditorToolsPanel: React.FC<BlockEditorToolsPanelProps> = ({
    selectedItem,
    handleSelectItem,
    setSrcUrlMap }) => {
    const [fileSelectorOpen, setFileSelectorOpen] = useState(false);
    const [templateFileSelectorOpen, setTemplateFileSelectorOpen] = useState(false);
    const [resourceFileSelectorOpen, setResourceFileSelectorOpen] = useState(false);
    const [btnTextGroupSelectorOpen, setBtnTextGroupSelectorOpen] = useState(false);
    // 根据当前选中的tool项的key来动态确定setSrcUrl
    const setSrcUrl = (selectedItem?.needSetSrcUrl || selectedItem?.needSetTemplateUrl || selectedItem?.needSetResourceUrl || selectedItem?.needSetBtnTextGroup) ? setSrcUrlMap[selectedItem.key] : null;

    const mediaTypeMap = {
        102: 'images',
        107: 'videos',
        108: 'audios',
    };
    // 根据选中的tool项来动态决定mediaType
    const mediaType = selectedItem?.needSetSrcUrl ? mediaTypeMap[selectedItem.key as keyof typeof mediaTypeMap] : null;
    const itemType = selectedItem?.key;
    return (
        <>
            {
                toolList().map((item, index) => {
                    // const setSrcUrl = item.needSetSrcUrl ? setSrcUrlMap[item.key] : null;
                    return (
                        <div
                            key={index}
                            className='editor-top-item'
                            draggable
                            onClick={() => {
                                handleSelectItem(item);
                                if (item.needSetSrcUrl) {
                                    setFileSelectorOpen(true);
                                }

                                if (item.needSetTemplateUrl) {
                                    setTemplateFileSelectorOpen(true);
                                }
                                if (item.needSetResourceUrl) {
                                    setResourceFileSelectorOpen(true);
                                }
                                if (item.needSetBtnTextGroup) {
                                    setBtnTextGroupSelectorOpen(true);
                                }
                            }
                            }
                        >
                            {item.preview!()}
                        </div>
                    )
                })
            }
            <MediaFileSelector
                fileSelectorOpen={fileSelectorOpen}
                setFileSelectorOpen={setFileSelectorOpen}
                afterSelectionCallback={setSrcUrl!}
                mediaType={mediaType!}
            />
            <TemplateFileSelector
                fileSelectorOpen={templateFileSelectorOpen}
                setFileSelectorOpen={setTemplateFileSelectorOpen}
                afterSelectionCallback={setSrcUrl!}
                templateType={itemType}
            />
            <ResourceFileSelector
                fileSelectorOpen={resourceFileSelectorOpen}
                setFileSelectorOpen={setResourceFileSelectorOpen}
                afterSelectionCallback={setSrcUrl!}
                resourceType={itemType}
            />
            <BtnTextGroupSelector
                fileSelectorOpen={btnTextGroupSelectorOpen}
                setFileSelectorOpen={setBtnTextGroupSelectorOpen}
                clickCallback={setSrcUrl!}
                resourceType={itemType}
            />

        </>
    );
};

export default BlockEditorToolsPanel;
export type { ToolItem };
