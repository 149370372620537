import { makeObservable, observable, action } from "mobx";

// 如果你知道 Pyodide 的类型，可以替换 `any` 为具体类型
type PyodideType = any;

class PyodideStore {
    pyodide: PyodideType | null = null;

    constructor() {
        makeObservable(this, {
            pyodide: observable.ref, // 使用 ref 使得 pyodide 不会被深度观察
            setPyodide: action
        });
    }

    setPyodide(pyodide: PyodideType) {
        this.pyodide = pyodide;
    }
}

const pyodideStore = new PyodideStore();
export { pyodideStore, PyodideStore };
