import { InputNumber, Button } from 'antd';
import styles from './flashCardModifier.module.less';
import TemplateFileSelector from '@/components/TemplateFileSelector/TemplateFileSelector';
import CMSAPI from '@/api/cms';
import AdvancedButtonRegionModifier from '../ButtonRegionModifier/AdvancedButtonRegionModifier';
import { useState } from 'react';
import { genObjectIDbyTS } from '@/utils/id_generator';
import { ElementContent, ElementData } from '@/base/ElementData/ElementData';
import FlashCardRegionContent from '@/base/ElementData/FlashCardRegionContent';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import DataDefinition from '@/base/DataDefinition';
import { simplifyFileName } from '@/utils/utils';
import { initElementContentFromTemplate } from '@/base/DataObjectGenerator';

interface FlashCardModifierProps {
    focusedItem: ElementData<FlashCardRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    onOptionChange: (id: number, key: string, btnId: number, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};

const FlashCardModifier: React.FC<FlashCardModifierProps> = ({
    focusedItem,
    onInputChange,
    onOptionChange,
    changeZIndex,
}) => {
    const [frontFileSelectorOpen, setFrontFileSelectorOpen] = useState(false);
    const [backFileSelectorOpen, setBackFileSelectorOpen] = useState(false);

    const showFileSelector = (key: string) => {
        if (key === 'card_front') {
            setFrontFileSelectorOpen(true);
        } else if (key === 'card_back') {
            setBackFileSelectorOpen(true);
        }
    }

    const updateIdsInArray = (arr: ElementData<ElementContent>[]) => {
        return arr.map(item => ({
            ...item,
            id: genObjectIDbyTS()
        }));
    };

    const changeBtnObj = async (templateObj: any, key: string) => {
        const api = new CMSAPI();
        const templateJson = await api.readJson(templateObj.fullName);

        const advContent = initElementContentFromTemplate(DataDefinition.toCamelCaseObj(templateJson.template) as AdvancedButtonRegionContent,
            AdvancedButtonRegionContent, 0, 0, 0);
        advContent.resetChildrenId();

        const obj = new ElementData<AdvancedButtonRegionContent>({
            id: genObjectIDbyTS(),
            type: 104,
            content: advContent,
            childrenLevel: true
        });
        onInputChange(focusedItem.id, key, obj);
        if (key === 'cardFront') {
            onInputChange(focusedItem.id, 'cardFrontTemplateUrl', templateObj.fullName);
        } else if (key === 'cardBack') {
            onInputChange(focusedItem.id, 'cardBackTemplateUrl', templateObj.fullName);
        }
    }
    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <Button type="primary" onClick={() => showFileSelector("card_front")}>
                选择卡片正面模板
            </Button>
            <div className={styles.modifyItem}>
                <div className={styles.templateTitle}>模版名称</div>
                <div className={styles.templateName}>
                    {simplifyFileName(focusedItem.content.cardFrontTemplateUrl)}
                </div>
            </div>
            {focusedItem.content.cardFront && Object.keys(focusedItem.content.cardFront).length !== 0 && <AdvancedButtonRegionModifier
                focusedItem={focusedItem.content.cardFront}
                onInputChange={onInputChange}
                onOptionChange={onOptionChange}
                changeZIndex={changeZIndex}
            >
            </AdvancedButtonRegionModifier>}
            <Button type="primary" onClick={() => showFileSelector("card_back")}>
                选择卡片背面模板
            </Button>
            <div className={styles.modifyItem}>
                <div className={styles.templateTitle}>模版名称</div>
                <div className={styles.templateName}>
                    {simplifyFileName(focusedItem.content.cardBackTemplateUrl)}
                </div>
            </div>
            {focusedItem.content.cardBack && Object.keys(focusedItem.content.cardBack).length !== 0 && <AdvancedButtonRegionModifier
                focusedItem={focusedItem.content.cardBack}
                onInputChange={onInputChange}
                onOptionChange={onOptionChange}
                changeZIndex={changeZIndex}
            >
            </AdvancedButtonRegionModifier>}
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
            <TemplateFileSelector
                fileSelectorOpen={frontFileSelectorOpen}
                setFileSelectorOpen={setFrontFileSelectorOpen}
                afterSelectionCallback={templateObj => changeBtnObj(templateObj, 'cardFront')}
                templateType={104}
            />
            <TemplateFileSelector
                fileSelectorOpen={backFileSelectorOpen}
                setFileSelectorOpen={setBackFileSelectorOpen}
                afterSelectionCallback={templateObj => changeBtnObj(templateObj, 'cardBack')}
                templateType={104}
            />
        </div>
    )
};

export default FlashCardModifier;
