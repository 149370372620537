import { cloneDeep } from 'lodash';
import { useState, useEffect } from 'react';
import service from '@/services/axios';
import { useStores } from '@/store/useStores';
import { containsVariablePattern } from '@/utils/utils';
import mixpanel from 'mixpanel-browser';
import { ElementData } from '@/base/ElementData/ElementData';
import { BlockTraceData } from '@/base/BlockData';
import BtnTextGroupRegionContent from '@/base/ElementData/BtnTextGroupRegionContent';
import BtnGenericGroupRegionContent from '@/base/ElementData/BtnGenericGroupRegionContent';
import { ButtonWrapper } from '@/base/ElementData/UtilTypes';
import {requestLLMCall} from '@/services/requestLLMCall';
import useCommonFunction from '@/hooks/useCommonFunction';
import LLMCallRequestData from '@/base/LLMCallRequestData';


const useCommonButtonLogic = (isEditable: boolean, 
                            elementData: ElementData<BtnTextGroupRegionContent | BtnGenericGroupRegionContent>, 
                            blockTraceData: BlockTraceData) => {
    //console.log('elementData', elementData);
    const { lectureStore, userInfoStore } = useStores();
    const isTeacher = userInfoStore.isTeacherView();
    const { buttons, selectionType, correctAnswer, showButtons } = elementData.content;
    const { executeFunc } = useCommonFunction();
    //console.log('buttons', buttons);
    // 按钮的类型目前有3种，
    //   对于BtnTextGrp：button是ButtonRegion
    //   对于BtnGenericGrp：button是AdvancedButtonRegion或ImageRegion
    const [newButtons, setNewButtons] = useState<ButtonWrapper[]>([]);
    //根据buttons的每一项的status来判断按钮的状态，0表示未选中，1表示选中，2表示选中且正确，3表示选中但错误
    //把选中的按钮的下标存储在userSelect中
    const [userSelect, setUserSelect] = useState(buttons?.map((btn, index) => btn.status === 1 ? index : -1).filter(index => index !== -1));
    const [userInput, setUserInput] = useState('');
    const [lastAction, setLastAction] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    useEffect(() => {
        if (isEditable) {
            setNewButtons(buttons);
        } else if (isTeacher) {
            setNewButtons(buttons);
        } else if (elementData.content instanceof BtnGenericGroupRegionContent){
            if(containsVariablePattern((elementData.content as BtnGenericGroupRegionContent).bindKey) && elementData.content.bindResourceObj) {
                setNewButtons(cloneDeep(showButtons!));
            }
        } else {
            setNewButtons(cloneDeep(buttons));
        }
    }, [isEditable, elementData, buttons, showButtons]);

    const handleSelect = (index: number) => {
        if(import.meta.env.PROD) {
            mixpanel.track('handleSelect', { 'index': index });
        }

        if (selectionType === 'single') {
            setUserSelect([index]);
            updateButtonStatusSingleChoice(index);
        } else {
            let newUserSelect = userSelect.includes(index) ? userSelect.filter(item => item !== index) : [...userSelect, index];
            setUserSelect(newUserSelect);
            updateButtonStatusMultipleChoice(newUserSelect);
        }
        setLastAction('select');
    };

    const handleSubmit = async () => {
        //console.log('handleSubmit -- userSelect', userSelect);
        if (isTeacher) return;
        let isCorrect = false;
        let userAnswer: number[] = [...userSelect];
        console.log('userSelect', userSelect);
        if (correctAnswer.length > 0) {
            if (userSelect.length > 0) {
                if (userSelect.sort().toString() === correctAnswer.sort().toString()) {
                    isCorrect = true;
                } else {
                    isCorrect = false;
                }
            } else {
                alert('请选择答案');
            }
        } else {
            if (selectionType === 'single') {
                if (lastAction === 'select') {
                   // do nothing
                } else if (lastAction === 'input') {
                    // user input 必然是最后一个选项
                    // user input 应该只存在于单选
                    userAnswer = [buttons.length];
                } else {
                    alert('请选择');
                }
            } else {
                // multiple
                if (userSelect.length > 0) {
                    // 根据用户选择的按钮的下标，获取对应的值,用户有输入的情况下，也要把输入的值加入到选择的值中
                    if (userInput.trim() !== '') {
                        userAnswer = userAnswer.concat(buttons.length);
                    }
                } else {
                    alert('请选择或输入');
                }
            }
        }
        let finalButtons: ButtonWrapper[] = [];
        if (correctAnswer.length > 0) {
            finalButtons = updateButtonStatus(userSelect, newButtons);
        } else {
            finalButtons = newButtons;
        }
        //console.log("finalButtons", finalButtons);
        //除了写入数据库，我们还需要写入lectureStore
        //console.log('elementData.lectureVar -- 准备写入', elementData.content.lectureVar);
        const extrasAdded: any = {};
        if (elementData.content.lectureVar && elementData.content.lectureVar !== '') {          
            //目前userAnswer是一个数组，我们需要把它转换成字符串
            console.log('elementData.content', elementData.content);
            const selectedChoices = userAnswer.map((index) => {
                // TODO: 这里做一个临时的fix，因为我们有时候得去showButtons里面找
                // 不过之后需要把showButtons这个字段去掉，这个字段是多余的
                let btnWrapper: ButtonWrapper;
                if (elementData.content.showButtons && elementData.content.showButtons?.length > elementData.content.buttons.length) {
                    btnWrapper = (elementData.content.showButtons[index] as ButtonWrapper);
                } else {
                    btnWrapper = (elementData.content.buttons[index] as ButtonWrapper);
                }
                return btnWrapper.value;
            });

            extrasAdded[elementData.content.lectureVar] = selectedChoices.join(",");
            lectureStore.setLectureVar(elementData.content.lectureVar, selectedChoices.join(","));
                 
            const currentBlock = lectureStore.learnedBlockList[lectureStore.currentBlockIndex];
            currentBlock.extras[elementData.content.lectureVar] = selectedChoices.join(",");
            //console.log('isCorrect', isCorrect);
            if(import.meta.env.PROD) {
                mixpanel.track('handleSubmit', 
                    { 'isCorrect': isCorrect,
                    'userAnswer': selectedChoices,
                    'var': elementData.content.lectureVar
                    });
            }
        }

        updateBlockTrace(finalButtons, userAnswer, isCorrect, extrasAdded, true);

        // 这里处理类似postPrompt的逻辑，这里用scenario解决，用nonstream
        // 如果elementData.content的类型是BtnTextGroupRegionContent，
        if (elementData.content instanceof BtnTextGroupRegionContent ||
            elementData.content instanceof BtnGenericGroupRegionContent) {
            // 只有postPrompt不为“”时才会执行
            if(elementData.content.postPrompt && elementData.content.postPrompt !== "") {
                let prompt = elementData.content.convertToText() + elementData.content.postPrompt;
                const request = LLMCallRequestData.genLLMCallParams(prompt, 
                    userInfoStore.userInfoData.id, userInfoStore.userInfoData.name, false);
                const response: any = await requestLLMCall(request);
                const res = await response.json();
                // 这里我们只期待一个文本回复
                console.log('res', res.data.content);
                if (elementData.content.postFunctionCall?.funcName){
                    // 我们要人为改一个参数
                    if (elementData.content.postFunctionCall && elementData.content.postFunctionCall.funcParams) {
                        elementData.content.postFunctionCall.funcParams['newWords'] = res.data.content;
                    }
                    console.log("exec post function", elementData.content.postFunctionCall);
                    executeFunc(elementData.content.postFunctionCall?.funcName, elementData.content.postFunctionCall?.funcParams);
                }
                // TODO: 目前这个是个dirty hack
                // 这里可能需要等几秒
                function sleep(ms: number) {
                    return new Promise(resolve => setTimeout(resolve, ms));
                }

                await sleep(4000); 
            }
        }

        setUserSelect([]);
        return isCorrect;
    }

    const singleAndGoNextSubmit = (index: number) => {
        if (isTeacher) return;
        let isCorrect = false;
        let userAnswer: number[] = [];
        

        let newBtns: ButtonWrapper[] = [];
        for(let i = 0; i < newButtons.length; i++) {
            const newBtn = cloneDeep(newButtons[i]);
            if(i === index) {
                newBtn.status = 1;
            } else {
                newBtn.status = 0;
            }
            newBtns.push(newBtn);
        }

        if (correctAnswer.length > 0) {
            if ([index].sort().toString() === correctAnswer.sort().toString()) {
                isCorrect = true;
            } else {
                isCorrect = false;
            }
            // 根据用户选择的按钮的下标，获取对应的值
            userAnswer = [index];
        } else {
            if (selectionType === 'single') {
                // 根据用户选择的按钮的下标，获取对应的值
                userAnswer = [index];
                //console.log('userAnswer', userAnswer)
            } else {
                // 根据用户选择的按钮的下标，获取对应的值,用户有输入的情况下，也要把输入的值加入到选择的值中
                userAnswer = [index];
                if (userInput.trim() !== '') {
                    userAnswer.push(buttons.length);
                }
            }
        }
        let finalButtons = [];
        if (correctAnswer.length > 0) {
            finalButtons = updateButtonStatus([index], newButtons);
        } else {
            finalButtons = newBtns;
        }
        //除了写入数据库，我们还需要写入lectureStore
        //console.log('elementData.lectureVar -- 准备写入', elementData.content.lectureVar);
        if (elementData.content.lectureVar && elementData.content.lectureVar !== '') {
            //目前userAnswer是一个数组，我们需要把它转换成字符串
            const selectedChoices = userAnswer.map((index) => {
                const btnWrapper = (elementData.content.buttons[index] as ButtonWrapper);
                    return btnWrapper.value;
                
            });
            lectureStore.setLectureVar(elementData.content.lectureVar, selectedChoices.join(","));
        }
        updateBlockTrace(finalButtons, userAnswer, isCorrect, null, true);
    }


    const updateButtonStatusSingleChoice = (selectedIndex: number) => {
        let newBtns: ButtonWrapper[] = [];
        for(let i = 0; i < newButtons.length; i++) {
            const newBtn = cloneDeep(newButtons[i]);
            if(i === selectedIndex) {
                newBtn.status = 1;
            } else {
                newBtn.status = 0;
            }
            newBtns.push(newBtn);
        }
        setNewButtons(newBtns);
    };

    const updateButtonStatusMultipleChoice = (selectedIndexes: number[]) => {
        let newBtns: ButtonWrapper[] = [];
        for(let i = 0; i < newButtons.length; i++) {
            const newBtn = cloneDeep(newButtons[i]);
            if(selectedIndexes.includes(i)) {
                newBtn.status = 1;
            } else {
                newBtn.status = 0;
            }
            newBtns.push(newBtn);
        }
        setNewButtons(newBtns);
    };

    //提交时根据userSelect和correct_answer判断这个选项是否正确，正确的status为2，错误的status为3
    const updateButtonStatus = (userSelect: number[], 
                                buttons: ButtonWrapper[] ) => {
        let newBtns: ButtonWrapper[] = [];
        for (let i = 0; i < buttons.length; i++) {
            const newBtn = cloneDeep(buttons[i]);
            if (correctAnswer.includes(i)) {
                newBtn.status = userSelect.includes(i) ? 2 : 0;
            } else {
                newBtn.status = userSelect.includes(i) ? 3 : 0;
            }
            newBtns.push(newBtn);
        }
        return newBtns;
    }

    const resetAllButtonStatus = () => {
        let newBtns: ButtonWrapper[] = [];
        for (let i = 0; i < newButtons.length; i++) {
            const newBtn = cloneDeep(newButtons[i]);
            newBtn.status = 0;
            newBtns.push(newBtn);
        }
        setNewButtons(newBtns);
    };

    const resetBtnStatusAndUserSelect = () => {
        resetAllButtonStatus();
        if (isTeacher) {
            setUserSelect([]);
            setUserInput('');
        }
        setLastAction('input');
    };
    const resetNewButtons = () => {
        if (isEditable) {
            setNewButtons(buttons);
        } else if (isTeacher) {
            setNewButtons(buttons);
        } else if (elementData.content instanceof BtnGenericGroupRegionContent &&
            containsVariablePattern(elementData.content.bindKey) && elementData.content.bindResourceObj) {
            setNewButtons(cloneDeep(showButtons!));
        } else {
            setNewButtons(cloneDeep(buttons));
        }
    };

    //修改block Trace
    const updateBlockTrace = async (newButtons: ButtonWrapper[], 
                                    userAnswer: any, 
                                    isCorrect: boolean, 
                                    blkExtras: any, 
                                    isSubmitted: boolean) => {
        //修改block Trace
        //在blockTraceData的trace_info中找到elementData.id对应的元素，修改对应元素的content的buttons为newButtons和isSubmitted和isCorrect
        const newBlockTraceData = cloneDeep(blockTraceData);
        // let elementData = {};
        newBlockTraceData.traceInfo.forEach((item) => {
            if (item.id === elementData.id) {
                const localItem = item as ElementData<BtnGenericGroupRegionContent>; 
                const newShowButtons = newButtons;
                if (containsVariablePattern(localItem.content.bindKey) && localItem.content.bindResourceObj) {
                    localItem.content.showButtons = newShowButtons;
                } else {
                    localItem.content.buttons = newShowButtons;
                }
                localItem.content.userAnswer = userAnswer;
                localItem.content.isCorrect = isCorrect;
                localItem.content.isSubmitted = isSubmitted;
                elementData = localItem;
            }
        });

        console.log('elementData @ line 324', elementData);

        newBlockTraceData.extras = { ...newBlockTraceData.extras, ...blkExtras };
        try {
            const res: any = await service.put('/block_trace', newBlockTraceData);
            console.log('res', res)
            if (res.status === "success") {
                lectureStore.updateElement(blockTraceData.id, elementData.id, elementData.content);
            }
        } catch (error) {
            console.log('error', error)
        }
    }


    // 返回 Hook 中所有的状态和方法，让组件可以直接使用
    return { newButtons, userSelect, userInput, handleSelect, handleSubmit, singleAndGoNextSubmit, resetBtnStatusAndUserSelect, setUserInput, resetAllButtonStatus, resetNewButtons };
};

export default useCommonButtonLogic;