import { Input } from 'antd';
import styles from './urlModifier.module.less';
import PropTypes from 'prop-types';
import { intercept } from 'mobx';

const { TextArea } = Input;

interface URLModifierProps {
    urlTitle: string;
    value: string;
    changeValue: (value: string) => void;
};

const URLModifier: React.FC<URLModifierProps>= ({ urlTitle, value, changeValue }) => {
    return (
        <div className={styles.modifyItem}>
            <div className={styles.modifyItemTitle}>
                {urlTitle}
            </div>
            <TextArea
                value={value}
                onChange={e => changeValue(e.target.value)}
                className={styles.urlArea}
                placeholder={`请输入${urlTitle}`}
            />
        </div>
    )
}

export default URLModifier;