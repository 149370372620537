class DataDefinition {

    allowedFields: string[] = [];

    static getAllFieldsFromClassName(constructor: any): string[] {
        let fields: string[] = [];
      
        let currentPrototype = constructor.prototype;
        while (currentPrototype && currentPrototype !== Object.prototype) {
          // 获取当前原型上的属性（不包括方法）
          const currentFields = Object.getOwnPropertyNames(currentPrototype)
            .filter((prop) => typeof currentPrototype[prop] !== 'function' && prop !== 'constructor');
      
          fields = fields.concat(currentFields);
      
          // 移动到父类的原型
          currentPrototype = Object.getPrototypeOf(currentPrototype);
        }
      
        // 去除重复字段（防止子类覆盖父类的字段）
        return [...new Set(fields)];
    }

    static toSnakeCase (str: string)  {
        return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    };

    getFieldType<T extends keyof this>(fieldName: T): this[T] {
        return this[fieldName];
    }

    setField<T extends keyof this>(fieldName: T, value: this[T]): void {
        this[fieldName] = value;
    }

    toJSON(): Record<string, any> {
        const snakeCaseObj: Record<string, any> = {};
        for (const key in this) {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                const snakeKey = DataDefinition.toSnakeCase(key);
                const value = (this as any)[key];

                // 如果值是继承自 DataDefinition 的类实例，则递归调用其 toJSON
                if (value instanceof DataDefinition) {
                    snakeCaseObj[snakeKey] = value.toJSON();
                } else if (Array.isArray(value)) {
                    snakeCaseObj[snakeKey] = value.map(item =>
                        item instanceof DataDefinition ? item.toJSON() : item
                    );
                } else {
                    snakeCaseObj[snakeKey] = value;
                }
            }
        }

        // JSON是给后端用的，需要去掉allowed_fields
        if(snakeCaseObj['allowed_fields']) {
            delete snakeCaseObj['allowed_fields'];
        }
        return snakeCaseObj;
    };

    static toCamelCase (str: string) {
        return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    };

    static toCamelCaseObj (data: any): any {
        if (Array.isArray(data)) {
            // 如果是数组，递归处理每个元素
            return data.map(item => DataDefinition.toCamelCaseObj(item));
        } else if (data !== null && typeof data === 'object') {
            // 如果是对象，递归处理每个字段
            const result: Record<string, any> = {};
            for (const key in data) {
                //console.log('key:', key);
                if (data.hasOwnProperty(key)) {
                    const camelKey = DataDefinition.toCamelCase(key);
                    result[camelKey] = DataDefinition.toCamelCaseObj(data[key]);
                }
            }
            return result;
        }
        // 如果是基本类型，目前抛出错误
        return data;
    };


    validityCheck() {
        for (const key in this) {
            if(key == 'allowedFields') {
                // Whitelist this field
                continue;
            }
            if (this.hasOwnProperty(key)) {
                if (!this.allowedFields.includes(key)) {
                    //throw new Error(`Invalid field: ${key} in ${this}`);
                    console.error(`Invalid field: ${key} in ${this}`);
                    console.error(this);
                }
            }
        }
    }

};

export default DataDefinition;