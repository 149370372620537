import { useContext } from 'react';
import { storeContext } from './StoreContext';
import { RootStore } from './rootStore';

export const useStores = () => {
    const stores: RootStore = useContext(storeContext);
    if (!stores) {
        throw new Error('useStores must be used within a StoreProvider.');
    }
    return stores;
};