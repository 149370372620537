
import styles from './progressBar.module.less';
import ProgressSegmentData from '@/base/ProgressSegmentData';
import ProgressSegment from './ProgressSegment';

interface ProgressBarProps {
    partSegments: ProgressSegmentData[];
    currentPos: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
    partSegments,
    currentPos }) => {

    if (currentPos < 0) currentPos = 0;

    const calculatePartProgress = (
        parts: ProgressSegmentData[],
        currentPos: number) => {
        if (currentPos < 0 || parts.length === 0) {
            //console.log('currentPos < 0 or no parts defined', currentPos, parts);
            return;
        }

        //percentagesWidths.push(parts[0].endPos);
        parts[0].lengthPercent = parts[0].endPos;

        for (let i = 1; i < parts.length; i++) {
            //percentagesWidths.push(parts[i].endPos - parts[i-1].endPos);   
            parts[i].lengthPercent = parts[i].endPos - parts[i - 1].endPos;
        }

        let thePart = 0;
        for (; thePart < parts.length; thePart++) {
            if (currentPos <= parts[thePart].endPos) {
                break;
            }
        }

        if (thePart === parts.length) {
            thePart = thePart - 1;
        }

        //let partPercentages = [];

        for (let i = 0; i < parts.length; i++) {
            if (i < thePart) {
                //partPercentages.push(100);
                parts[i].progress = 100;
            } else if (i === thePart) {
                if (i === 0) {
                    //partPercentages.push(currentPos / parts[i].lengthPercent * 100);
                    parts[i].progress = (currentPos + 1) / parts[i].lengthPercent * 100;
                } else {
                    //partPercentages.push((currentPos - parts[i-1].endPos) / parts[i].lengthPercent * 100);
                    parts[i].progress = (currentPos - parts[i - 1].endPos + 1) / parts[i].lengthPercent * 100;
                }
            } else {
                //partPercentages.push(0);
                parts[i].progress = 0;
            }
        }

        // get total length
        const totalLength = parts[parts.length - 1].endPos;
        for (let i = 0; i < parts.length; i++) {
            parts[i].lengthPercent = (parts[i].lengthPercent / (totalLength * 1.05)) * 100;
        }

        //return [partPercentages, percentagesWidths];
    };


    calculatePartProgress(partSegments, currentPos);
    const newPartSegments = partSegments.map((partSeg) => {
        return { endPos: partSeg.endPos, lengthPercent: partSeg.lengthPercent, progress: partSeg.progress };
    });
    localStorage.setItem('partSegments', JSON.stringify(newPartSegments));

    return (
        <div className={styles.progressBarContainer}>
            {partSegments.map((partSeg, index) => {
                return <ProgressSegment key={index}
                    segmentData={partSeg} />

            })
            }
        </div>
    );
};

export default ProgressBar;