import React, { useState } from 'react';
import styles from './flashCardRegion.module.less';
import commonStyle from '../common/ComponentCommons';
import BaseDragableElement from '../common/BaseDragableElement';
import AdvancedButtonRegion from '../ButtonRegion/AdvancedButtonRegion';
import { IntrinsicElementProps } from '../common/BaseDragableElement';
import FlashCardRegionContent from '@/base/ElementData/FlashCardRegionContent';


const FlashCardRegion: React.FC<IntrinsicElementProps<FlashCardRegionContent>> = ({
    elementData,
    isEditable,
    handleFocusItem,
    handleResize,
    handleDragStop,
    handleDelete }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <BaseDragableElement
            elementData={elementData}
            isEditable={isEditable}
            handleFocusItem={handleFocusItem}
            handleResize={handleResize}
            handleDragStop={handleDragStop}
            handleDelete={handleDelete}
        >
            <div
                style={{ ...commonStyle }}
                onClick={e => { if (isEditable) { handleFocusItem(elementData, e); } else { handleFlip(); } }}
                className={`${elementData.isFocus && isEditable ? styles.elementFocused : ''} ${isEditable ? styles.element : ''}`}
            >
                <div className={`${styles.flashCardBox} ${isFlipped ? styles.flipped : ''}`}>
                    <div className={styles.flashCardFront}>
                        <div className={styles.flashCardFrontContent}>
                            <AdvancedButtonRegion
                                elementData={elementData.content.cardFront!}
                                isEditable={isEditable}
                                handleFocusItem={handleFocusItem}
                                handleResize={handleResize}
                                handleDragStop={handleDragStop}
                                handleDelete={handleDelete}
                            />
                        </div>
                    </div>
                    <div className={styles.flashCardBack}>
                        <div className={styles.flashCardBackContent}>
                            <AdvancedButtonRegion
                                elementData={elementData.content.cardBack!}
                                isEditable={isEditable}
                                handleFocusItem={handleFocusItem}
                                handleResize={handleResize}
                                handleDragStop={handleDragStop}
                                handleDelete={handleDelete}
                            />
                        </div>
                    </div>
                    {isEditable && <button className={styles.flipButton} onClick={handleFlip}>Flip</button>}
                </div>
            </div>
        </BaseDragableElement>
    );
};

export default FlashCardRegion;