import { ElementContent } from './ElementData';

class ImageRegionContent extends ElementContent {
    src: string;
    dynamicSrc: string;
    color: string;
    alpha: number;
    radiusTL: number;
    radiusTR: number;
    radiusBL: number;
    radiusBR: number;
    borderWidth: number;
    borderColor: string;
    shadowX: number;
    shadowY: number;
    shadowBlur: number;
    shadowSpread: number;
    shadowColor: string;
    zIndex: number;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 200,
        height = 200,
        src = "",
        dynamicSrc = "",
        color = "#008000",
        alpha = 1,
        radiusTL = 0,
        radiusTR = 0,
        radiusBL = 0,
        radiusBR = 0,
        borderWidth = 0,
        borderColor = "#FFFFFF",
        shadowX = 0,
        shadowY = 0,
        shadowBlur = 0,
        shadowSpread = 0,
        shadowColor = "#000000",
        zIndex = 0
    }: Partial<ImageRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'src', 'dynamicSrc',
            'color', 'alpha', 'radiusTL', 'radiusTR', 'radiusBL', 'radiusBR', 'borderWidth',
            'borderColor', 'shadowX', 'shadowY', 'shadowBlur', 'shadowSpread', 'shadowColor', 'zIndex'];
        this.src = src;
        this.dynamicSrc = dynamicSrc;
        this.color = color;
        this.alpha = alpha;
        this.radiusTL = radiusTL;
        this.radiusTR = radiusTR;
        this.radiusBL = radiusBL;
        this.radiusBR = radiusBR;
        this.borderWidth = borderWidth;
        this.borderColor = borderColor;
        this.shadowX = shadowX;
        this.shadowY = shadowY;
        this.shadowBlur = shadowBlur;
        this.shadowSpread = shadowSpread;
        this.shadowColor = shadowColor;
        this.zIndex = zIndex;
    }
};

export default ImageRegionContent;