import { InputNumber, Button, Input, Radio, Switch, Select } from 'antd';
import styles from './dragClassifyModifier.module.less';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import TemplateFileSelector from '@/components/TemplateFileSelector/TemplateFileSelector';
import CMSAPI from '@/api/cms';
import { genObjectIDbyTS } from '@/utils/id_generator';
import AdvancedButtonRegionModifier from '../ButtonRegionModifier/AdvancedButtonRegionModifier';
import { ElementData } from '@/base/ElementData/ElementData';
import { DragClassifyRegionContent } from '@/base/ElementData/DragClassifyRegionContent';
import AdvancedButtonRegionContent from '@/base/ElementData/AdvancedButtonRegionContent';
import { DragItem, CategoryItem } from '@/base/ElementData/DragClassifyRegionContent';
import useCommonFunction from '@/hooks/useCommonFunction';
import TextToSpeech from '@/components/TextToSpeech/TextToSpeech';
import { simplifyFileName } from '@/utils/utils';
import { initElementContentFromTemplate } from '@/base/DataObjectGenerator';
import DataDefinition from '@/base/DataDefinition';

type ElementIdOption = { value: any, label: any };

interface DragClassifyModifierProps {
    focusedItem: ElementData<DragClassifyRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    onOptionChange: (id: number, key: string, btnId: number, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};

const DragClassifyModifier: React.FC<DragClassifyModifierProps> = ({
    focusedItem,
    onInputChange,
    onOptionChange,
    changeZIndex
}) => {
    const [thingsFileSelectorOpen, setThingsFileSelectorOpen] = useState(false);
    const [categoriesTemSelectorOpen, setCategoriesTemSelectorOpen] = useState(false);
    const [thingsNum, setThingsNum] = useState<number>(focusedItem.content.things.length);
    const [things, setThings] = useState<DragItem[]>((focusedItem.content.things));
    const [currentItemId, setCurrentItemId] = useState(0);
    const [categoriesNum, setCategoriesNum] = useState(focusedItem.content.categories.length);
    const [categories, setCategories] = useState<CategoryItem[]>((focusedItem.content.categories));
    const { generateSelectOptionsData, generateElementIdOptions } = useCommonFunction();
    const optionsData = generateSelectOptionsData();
    const elementIdOptions: ElementIdOption[] = generateElementIdOptions();

    const handleThingItemsNum = (items: DragItem[], itemsNum: number) => {
        const newItems = cloneDeep(items);
        if (itemsNum > newItems.length) {
            const templateItem = newItems[0];
            for (let i = 0; i < itemsNum - items.length; i++) {
                const newItem = cloneDeep(templateItem);
                newItem.id = genObjectIDbyTS();
                if (newItem.btnObj && Object.keys(newItem.btnObj).length !== 0) {
                    newItem.btnObj.id = genObjectIDbyTS();
                    // TODO：这里太nasty, 需要重构
                    for (let i = 0; i < newItem.btnObj.content.children.textRegions.length; i++) {
                        newItem.btnObj.content.children.textRegions[i].id = genObjectIDbyTS();
                    }
                    for (let i = 0; i < newItem.btnObj.content.children.imgRegions.length; i++) {
                        newItem.btnObj.content.children.imgRegions[i].id = genObjectIDbyTS();
                    }
                    for (let i = 0; i < newItem.btnObj.content.children.buttons.length; i++) {
                        newItem.btnObj.content.children.buttons[i].id = genObjectIDbyTS();
                    }
                }
                newItems.push(newItem);
            }
        } else {
            newItems.splice(itemsNum, newItems.length - itemsNum);
        }
        //onInputChange(`${key}Num`, focusedItem.id, itemsNum);
        onInputChange(focusedItem.id, 'things', newItems);
        return newItems;
    };

    const handleCategoryItemsNum = (items: CategoryItem[], itemsNum: number) => {
        const newItems = cloneDeep(items);
        if (itemsNum > newItems.length) {
            const templateItem = newItems[0];
            for (let i = 0; i < itemsNum - items.length; i++) {
                const newItem = cloneDeep(templateItem);
                newItem.id = genObjectIDbyTS();
                if (newItem.btnObj && Object.keys(newItem.btnObj).length !== 0) {
                    newItem.btnObj.id = genObjectIDbyTS();
                    // TODO：这里太nasty, 需要重构
                    for (let i = 0; i < newItem.btnObj.content.children.textRegions.length; i++) {
                        newItem.btnObj.content.children.textRegions[i].id = genObjectIDbyTS();
                    }
                    for (let i = 0; i < newItem.btnObj.content.children.imgRegions.length; i++) {
                        newItem.btnObj.content.children.imgRegions[i].id = genObjectIDbyTS();
                    }
                    for (let i = 0; i < newItem.btnObj.content.children.buttons.length; i++) {
                        newItem.btnObj.content.children.buttons[i].id = genObjectIDbyTS();
                    }
                }
                newItems.push(newItem);
            }
        } else {
            newItems.splice(itemsNum, newItems.length - itemsNum);
        }
        //onInputChange(`${key}Num`, focusedItem.id, itemsNum);
        onInputChange(focusedItem.id, 'categories', newItems);
        return newItems;
    };

    const handleThingChange = (items: DragItem[],
        key: keyof DragItem, value: any, id: number) => {
        const newItems = cloneDeep(items);
        const index = newItems.findIndex(item => item.id === id);
        newItems[index].setField(key, value);
        focusedItem.content.things = newItems;
        setThings(newItems);
    };

    const handleCategoryChange = (items: CategoryItem[],
        key: keyof CategoryItem, value: any, id: number) => {
        const newItems = cloneDeep(items);
        const index = newItems.findIndex(item => item.id === id);
        newItems[index].setField(key, value);
        focusedItem.content.categories = newItems;
        setCategories(newItems);
    };

    const showItemFileSelector = (id: number, key: string) => {
        if (key === "things") {
            setThingsFileSelectorOpen(true);
        } else if (key === "categories") {
            setCategoriesTemSelectorOpen(true);
        }
        setCurrentItemId(id);
    }

    const changeFuncName = (value: string) => {
        const newFunc = { ...focusedItem.content.postFunctionCall, funcName: value };
        onInputChange(focusedItem.id, 'postFunctionCall', newFunc);
    }

    const updateDoneCallFuncParams = (key: string, value: any) => {
        const newDoneCallFunc = cloneDeep(focusedItem?.content?.postFunctionCall || null);
        if (newDoneCallFunc !== null) {
            const funcParams = newDoneCallFunc.funcParams || {};
            const newFuncParams = { ...funcParams, [key]: value };
            newDoneCallFunc.funcParams = newFuncParams;
        }
        onInputChange(focusedItem.id, 'postFunctionCall', newDoneCallFunc);

    }

    const changeThingItemBtnObj = async (templateObj: any,
        items: DragItem[], key: string) => {
        const api = new CMSAPI();
        const templateJson = await api.readJson(templateObj.fullName);

        const advContent = initElementContentFromTemplate(DataDefinition.toCamelCaseObj(templateJson.template) as AdvancedButtonRegionContent,
            AdvancedButtonRegionContent, 0, 0, 0);

        advContent.resetChildrenId();

        const obj = new ElementData<AdvancedButtonRegionContent>({
            id: genObjectIDbyTS(),
            type: 104,
            content: advContent,
            childrenLevel: true
        });

        const newItems = cloneDeep(items);
        // 找到newItems中id和currentItemId相同的那一项，将其btnObj属性设置为obj
        const index = newItems.findIndex(item => item.id === currentItemId);
        newItems[index].btnObj = obj;
        newItems[index].templateUrl = templateObj.fullName;
        onInputChange(focusedItem.id, 'things', newItems);
        setThings(newItems);
    };

    const changeCategoryItemBtnObj = async (templateObj: any,
        items: CategoryItem[], key: string) => {
        const api = new CMSAPI();
        const templateJson = await api.readJson(templateObj.fullName);

        const advContent = initElementContentFromTemplate(DataDefinition.toCamelCaseObj(templateJson.template) as AdvancedButtonRegionContent,
            AdvancedButtonRegionContent, 0, 0, 0);

        advContent.resetChildrenId();
        const obj = new ElementData<AdvancedButtonRegionContent>({
            id: genObjectIDbyTS(),
            type: 104,
            content: advContent,
            childrenLevel: true
        });

        const newItems = cloneDeep(items);
        // 找到newItems中id和currentItemId相同的那一项，将其btnObj属性设置为obj
        const index = newItems.findIndex(item => item.id === currentItemId);
        newItems[index].btnObj = obj;
        newItems[index].templateUrl = templateObj.fullName;
        onInputChange(focusedItem.id, 'categories', newItems);
        setCategories(newItems);
    };

    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否必须全部分类完成才能进入下一页</div>
                <Switch
                    checked={focusedItem.content.shouldComplete}
                    onChange={value => onInputChange(focusedItem.id, 'shouldComplete', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>分类模式(是否可以分到多个类别)</div>
                <Select
                    value={focusedItem.content.classificationMode}
                    onChange={value => onInputChange(focusedItem.id, 'classificationMode', value)}
                >
                    <Select.Option value="one_to_one"> 一对一</Select.Option>
                    <Select.Option value="one_to_many">一对多</Select.Option>
                </Select>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>待分类thing数量</div>
                <InputNumber
                    value={focusedItem.content.things.length}
                    onChange={value => setThingsNum(value!)}
                />
                <Button onClick={
                    () => {
                        const newItems = handleThingItemsNum(things, thingsNum);
                        setThings(newItems);
                    }
                }>确定</Button>
            </div>
            <div>
                {things.map((item, index) => {
                    return (
                        <div key={index}>
                            <h2>thing{index + 1}</h2>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>value(必填且每项不一样)</div>
                                <Input
                                    value={item.value}
                                    onChange={e => handleThingChange(things, 'value', e.target.value, item.id)}
                                />
                            </div>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>所属正确分类</div>
                                <Radio.Group
                                    value={item.correctCategory}
                                    onChange={e => handleThingChange(things, 'correctCategory', e.target.value, item.id)}
                                >
                                    {categories.map((category, index) => {
                                        return (
                                            <Radio key={index} value={category.value}>{category.value}</Radio>
                                        )
                                    })}
                                </Radio.Group>
                            </div>
                            <Button type="primary" onClick={() => showItemFileSelector(item.id, 'things')}>
                                选择模板
                            </Button>
                            <div className={styles.modifyItem}>
                                <div className={styles.templateTitle}>模版名称</div>
                                <div className={styles.templateName}>
                                    {simplifyFileName(item.templateUrl)}
                                </div>
                            </div>
                            {item.btnObj && Object.keys(item.btnObj).length !== 0 && <AdvancedButtonRegionModifier
                                focusedItem={item.btnObj}
                                onInputChange={onInputChange}
                                onOptionChange={onOptionChange}
                                changeZIndex={changeZIndex}>
                            </AdvancedButtonRegionModifier>}
                        </div>
                    )
                })}
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>类别数量</div>
                <InputNumber
                    value={focusedItem.content.categories.length}
                    onChange={value => setCategoriesNum(value!)}
                />
                <Button onClick={
                    () => {
                        const newItems = handleCategoryItemsNum(categories, categoriesNum);
                        setCategories(newItems)
                    }
                }>确定</Button>
            </div>
            <div>
                {categories.map((item, index) => {
                    return (
                        <div key={index}>
                            <h2>类别{index + 1}</h2>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>value(必填且每项不一样)</div>
                                <Input
                                    value={item.value}
                                    onChange={e => handleCategoryChange(categories, 'value', e.target.value, item.id)}
                                />
                            </div>
                            <Button type="primary" onClick={() => showItemFileSelector(item.id, "categories")}>
                                选择模板
                            </Button>
                            <div className={styles.modifyItem}>
                                <div className={styles.templateTitle}>模版名称</div>
                                <div className={styles.templateName}>
                                    {simplifyFileName(item.templateUrl)}
                                </div>
                            </div>
                            {item.btnObj && Object.keys(item.btnObj).length !== 0 && <AdvancedButtonRegionModifier
                                focusedItem={item.btnObj}
                                onInputChange={onInputChange}
                                onOptionChange={onOptionChange}
                                changeZIndex={changeZIndex}>
                            </AdvancedButtonRegionModifier>}
                        </div>
                    )
                })}
            </div>
            <div className={styles.modifyItem}>
                <span>Post Prompt</span>
                <textarea
                    style={{ height: '200px' }}
                    value={focusedItem.content.postPrompt || ''}
                    onChange={e => onInputChange(focusedItem.id, 'postPrompt', e.target.value)}
                    className={styles.textareaLarge} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>绑定函数</div>
                <Select
                    value={focusedItem.content.postFunctionCall?.funcName}
                    onChange={value => changeFuncName(value)}
                    className={styles.functionSelect}
                >
                    {optionsData.map(option => (
                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
            </div>
            {(focusedItem.content.postFunctionCall?.funcName === 'showSpecifiedElement' ||
                focusedItem.content.postFunctionCall?.funcName === 'showSpecifiedElementAndHide' ||
                focusedItem.content.postFunctionCall?.funcName === 'changeTeacherWords') && (
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>elementId</div>
                        <Select
                            value={focusedItem.content.postFunctionCall?.funcParams?.elementId}
                            onChange={value => updateDoneCallFuncParams('elementId', value)}
                            className={styles.functionSelect}
                        >
                            {elementIdOptions.map(option => (
                                <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                            ))}
                        </Select>
                    </div>
                )}
            {focusedItem.content.postFunctionCall?.funcName === 'changeTeacherWords' && (
                <>
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>参数:new words</div>
                        <textarea
                            value={focusedItem.content.postFunctionCall?.funcParams?.newWords}
                            onChange={e => updateDoneCallFuncParams('newWords', e.target.value)}
                            className={styles.textareaSmall}
                        />
                    </div>
                    <div className={styles.modifyItemCol}>
                        <div className={styles.modifyItemTitle}>参数:audio url</div>
                        <TextToSpeech
                            text={focusedItem.content.postFunctionCall?.funcParams?.newWords}
                            btnText='生成音频'
                            successCallback={url => updateDoneCallFuncParams('audioUrl', url)}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>当前是否已有音频链接:</div>
                        {focusedItem.content.postFunctionCall?.funcParams?.audioUrl ? "是" : "否"}
                    </div>
                </>
            )}
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>层级</div>
                <div>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                    <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
                </div>
            </div>
            <TemplateFileSelector
                fileSelectorOpen={thingsFileSelectorOpen}
                setFileSelectorOpen={setThingsFileSelectorOpen}
                afterSelectionCallback={templateObj => changeThingItemBtnObj(templateObj, things, 'things')}
                templateType={104}
            />
            <TemplateFileSelector
                fileSelectorOpen={categoriesTemSelectorOpen}
                setFileSelectorOpen={setCategoriesTemSelectorOpen}
                afterSelectionCallback={templateObj => changeCategoryItemBtnObj(templateObj, categories, 'categories')}
                templateType={104}
            />
        </div>
    )
};

export default DragClassifyModifier;