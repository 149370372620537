import { ElementContent, ElementData } from './ElementData';
import { PromptSetVarConfig, FunctionConfig } from './UtilTypes';
import AdvancedButtonRegionContent from './AdvancedButtonRegionContent';
import { genObjectIDbyTS } from "@/utils/id_generator";
import { cloneDeep } from 'lodash';
import { ButtonWrapper } from './UtilTypes';
import CorrectAnswerSound from '/assets/audio/correctAnswerSound.mp3';
import WrongAnswerSound from '/assets/audio/wrongAnswerSound.mp3';
import ImageRegionContent from './ImageRegionContent';

class BtnGenericGroupRegionContent extends ElementContent {
    selectionType: string;
    inputable: boolean;
    goNext: boolean;
    showSubmitBtn: boolean;
    row: number;
    col: number;
    btnNum: number;
    visible: boolean;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ButtonWrapper)
    // 目前allow 2种类型的button，每种也都是一个ElementData
    buttons: ButtonWrapper[];
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', ButtonWrapper)
    showButtons?: ButtonWrapper[];
    bindKey: string;
    bindResourceObj: Record<string, any> | null;
    correctAnswer: number[];
    userAnswer: number[];
    isCorrect: boolean;
    isSubmitted: boolean;
    correctAnswerSoundUrl: string;
    wrongAnswerSoundUrl: string;
    evalTextCorrect: string;
    evalTextWrong: string;
    evalTextCorrectAudio: string;
    evalTextWrongAudio: string;
    autoplayEvalText: boolean;
    evalTextColor: string;
    evalTextFontWeight: number;
    characterPerPage: number;
    lectureVar: string;
    rewardedCoinsNum: number;
    @Reflect.metadata('design:type', Array)
    @Reflect.metadata('array_elment', PromptSetVarConfig)
    postProcessHandlers: PromptSetVarConfig[];
    postPrompt: string;
    @Reflect.metadata('design:type', FunctionConfig)
    postFunctionCall: FunctionConfig | null;

    constructor({
        positionX = 0,
        positionY = 0,
        width = 200,
        height = 100,
        selectionType = 'single',
        inputable = false,
        goNext = false,
        showSubmitBtn = false,
        row = 1,
        col = 1,
        btnNum = 1,
        visible = true,
        buttons = [new ButtonWrapper(
            {
                btnObj: new ElementData<ImageRegionContent>({
                    id: genObjectIDbyTS(),
                    type: 102,
                    content: new ImageRegionContent()
                }),
                value: '按钮',
            }
        )],
        showButtons = [],
        bindKey = "",
        bindResourceObj = null,
        correctAnswer = [],
        userAnswer = [],
        isCorrect = false,
        isSubmitted = false,
        correctAnswerSoundUrl = CorrectAnswerSound,
        wrongAnswerSoundUrl = WrongAnswerSound,
        evalTextCorrect = "恭喜你，答对了",
        evalTextWrong = "",
        evalTextCorrectAudio = "",
        evalTextWrongAudio = "",
        autoplayEvalText = false,
        evalTextColor = "#000000",
        evalTextFontWeight = 400,
        characterPerPage = 75,
        lectureVar = "",
        rewardedCoinsNum = 0,
        postProcessHandlers = [],
        postPrompt = "",
        postFunctionCall = null
        
    }: Partial<BtnGenericGroupRegionContent> = {}) {
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex',
            'selectionType', 'inputable', 'goNext', 'showSubmitBtn', 'row', 'col',
            'btnNum', 'visible', 'buttons', 'showButtons', 'bindKey', 'bindResourceObj',
            'correctAnswer', 'userAnswer', 'isCorrect', 'isSubmitted', 'correctAnswerSoundUrl',
            'wrongAnswerSoundUrl', 'evalTextCorrect', 'evalTextWrong', 'evalTextCorrectAudio',
            'evalTextWrongAudio', 'autoplayEvalText', 'evalTextColor', 'evalTextFontWeight',
            'characterPerPage', 'lectureVar', 'rewardedCoinsNum', 'postProcessHandlers',
            'postPrompt', 'postFunctionCall'];
        this.selectionType = selectionType;
        this.inputable = inputable;
        this.goNext = goNext;
        this.showSubmitBtn = showSubmitBtn;
        this.row = row;
        this.col = col;
        this.btnNum = btnNum;
        this.visible = visible;
        this.buttons = buttons;
        this.showButtons = showButtons;
        this.bindKey = bindKey;
        this.bindResourceObj = bindResourceObj;
        this.correctAnswer = correctAnswer;
        this.userAnswer = userAnswer;
        this.isCorrect = isCorrect;
        this.isSubmitted = isSubmitted;
        this.correctAnswerSoundUrl = correctAnswerSoundUrl;
        this.wrongAnswerSoundUrl = wrongAnswerSoundUrl;
        this.evalTextCorrect = evalTextCorrect;
        this.evalTextWrong = evalTextWrong;
        this.evalTextCorrectAudio = evalTextCorrectAudio;
        this.evalTextWrongAudio = evalTextWrongAudio;
        this.autoplayEvalText = autoplayEvalText;
        this.evalTextColor = evalTextColor;
        this.evalTextFontWeight = evalTextFontWeight;
        this.characterPerPage = characterPerPage;
        this.lectureVar = lectureVar;
        this.rewardedCoinsNum = rewardedCoinsNum;
        this.postProcessHandlers = postProcessHandlers;
        this.postPrompt = postPrompt;
        this.postFunctionCall = postFunctionCall;
    }

    getChildren(): ElementData<ElementContent>[] {
        let result: ElementData<ElementContent>[] = [];

        this.buttons.forEach(button => {
            // 需要把btnObj先加进去
            result.push(button.btnObj!);
            result = result.concat(button.btnObj!.content.getChildren());
        });

        return result;
    }

    initFromData(data: Record<string, string[]>) {
        console.log('initFromData', data);
        // 这个只适合1个img，2个text的情况
        if (this.buttons.length === 1) {
            const keys: string[] = Object.keys(data);
            if (keys.length === 0) {
                throw new Error(`data is empty:  ${data}  ${this}`);
            }


            let btnTemplateObj: ElementData<AdvancedButtonRegionContent> = (this.buttons[0].btnObj as ElementData<AdvancedButtonRegionContent>);
            console.log('btnTemplateObj:', btnTemplateObj);
            let curBtn = btnTemplateObj;
            for (let i = 0; i < keys.length; i++) {
                console.log('i=', i, data[keys[i]]);
                console.log('curBtn', curBtn);
                if (i >= 1) {
                    curBtn = cloneDeep(btnTemplateObj);
                    curBtn.id = genObjectIDbyTS();
                }

                // 选第一个的图
                curBtn.content.children.imgRegions[0].content.src = data[keys[i]][0];
                // 选第一个的文本区域
                curBtn.content.children.textRegions[0].content.text = keys[i];
                curBtn.content.children.textRegions[1].content.text = data[keys[i]][1];
                curBtn.childrenLevel = true;
                this.showButtons!.push(new ButtonWrapper({ btnObj: curBtn, value: keys[i] }));
            }
        }

    }

    // 获取用户实际选择的选项，将数组下标转换为潜在的A，B，C，D
    getPrettySelected(choices : number[]): string {
        console.log("getPrettySelected", choices, this);
        let results: string[] = [];
        choices.forEach((index) => {
            // TODO: btn的类型好像可以不是AdvancedButtonRegionContent
            const btnContent = (this.buttons[index].btnObj?.content as AdvancedButtonRegionContent);
            results.push('"' + btnContent.value + '"');
        });
        return results.join(',');

    }

    convertToText(): string {
        console.log('BtnGenericGroupRegionContent convertToText', this);
        let text = "这到选择题的选项如下：";  
        this.buttons.forEach(button => {
            text += button.btnObj?.content?.convertToText() + '\n';
        });
        text += "用户选择了" + this.getPrettySelected(this.userAnswer) + "，总共选择了"
                + this.userAnswer.length.toString() + "个选项。";
        if(this.correctAnswer.length > 0) {
            text += this.isCorrect ? "用户这道题答对了。" : "用户这道题答错了。";
            text += "正确答案是：" + this.getPrettySelected(this.correctAnswer) + "。";
        }

        return text;
    }
};

export default BtnGenericGroupRegionContent;
