import service from '@/services/axios';


class MultimediaAPI {

    async genVoiceAndUpload(data: any, dir: string) {
        try {
            const response = await service.post('/gen_voice_and_upload?dir=' + dir, data);
            return response.data;
        } catch (error) {
            console.error('Error generating voice and uploading:', error);
            throw error;
        }
    }

    // example input
    /*{
        "prompt": "明天真美好，每天吃雪糕",
        "make_instrumental": false,
        "model": "chirp-v3-5",
        "wait_audio": false
    }*/

    async sonoGenSong(data: any) {
        try {
            const response = await service.post('/music_gen_v2', data);
            // 直接返回response, 不进一步取里面的字段
            return response;
        } catch (error) {
            console.error('Error generating song:', error);
            throw error;
        }
    }


    async getSongById(songId: number) {
        try {
            const response = await service.get(`/music_feed_v2/?sid=${songId}&uid=10001`);
            return response;
        } catch (error) {
            console.error('Error getting song by id:', error);
            throw error;
        }
    }

    async backUpToCloud(data: any) {
        try {
            const response = await service.post('/backup_to_cloud', data);
            return response;
        } catch (error) {
            console.error('Error backing up to cloud:', error);
            throw error;
        }
    }

    async imageGen(data: any) {
        try {
            const response = await service.post('/img_gen', data);
            return response;
        } catch (error) {
            console.error('Error generating image:', error);
            throw error;
        }
    }
}

export default MultimediaAPI;