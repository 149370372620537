import FormatPainterIcon from '@/assets/img/format_painter_icon.svg';
import PropTypes from 'prop-types';

const Toolbar = ({ handleCopyFormat }) => {
    return (
        <>
            <div className='toolbar-item' onClick={handleCopyFormat}>
                <img src={FormatPainterIcon} alt="FormatPainter" />
            </div>
        </>
    )
}
export default Toolbar;

Toolbar.propTypes = {
    handleCopyFormat: PropTypes.func
}
