import { Button, InputNumber, Input, Radio } from 'antd';
import styles from './ratingRegionModifier.module.less';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import MediaFileSelectorModifier from '../MediaFileSelectorModifier/MediaFileSelectorModifier';
import { ElementData } from '@/base/ElementData/ElementData';
import RatingRegionContent from '@/base/ElementData/RatingRegionContent';

interface RatingRegionModifierProps {
    focusedItem: ElementData<RatingRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};


const RatingRegionModifier: React.FC<RatingRegionModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex
}) => {
    const [ratingDescriptions, setRatingDescriptions] = useState(cloneDeep(focusedItem.content.ratingDescriptions));
    const changeRatingDescriptions = (index: number, value: string) => {
        const newRatingDescriptions = cloneDeep(ratingDescriptions);
        newRatingDescriptions[index] = value;
        setRatingDescriptions(newRatingDescriptions);
        onInputChange(focusedItem.id, 'ratingDescriptions', newRatingDescriptions);
    }

    const starNumChange = (value: number | null) => {
        if (value === null) return;
        // 评价星级改变时，评价描述也要改变
        const newRatingDescriptions = cloneDeep(ratingDescriptions);
        newRatingDescriptions.length = value + 1;
        setRatingDescriptions(newRatingDescriptions);
        onInputChange(focusedItem.id, 'ratingDescriptions', newRatingDescriptions);
        onInputChange(focusedItem.id, 'starNum', value);
    }

    return (
        <div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>X</div>
                <InputNumber
                    value={focusedItem.content.positionX}
                    onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                />
                <div className={styles.modifyItemTitle}>Y</div>
                <InputNumber
                    value={focusedItem.content.positionY}
                    onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>宽度</div>
                <InputNumber
                    value={focusedItem.content.width}
                    onChange={value => onInputChange(focusedItem.id, 'width', value)}
                    className={styles.widthInput}
                />
                <div className={styles.modifyItemTitle}>高度</div>
                <InputNumber
                    value={focusedItem.content.height}
                    onChange={value => onInputChange(focusedItem.id, 'height', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>Border Radius</div>
                <InputNumber
                    value={focusedItem.content.borderRadius}
                    onChange={value => onInputChange(focusedItem.id, 'borderRadius', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>标题</div>
                <Input
                    value={focusedItem.content.title}
                    onChange={e => onInputChange(focusedItem.id, 'title', e.target.value)}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>标题颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.titleColor}
                    onChange={e => {
                        onInputChange(focusedItem.id, 'titleColor', e.target.value);
                    }}
                    className={styles.colorBoard}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>标题字体大小</div>
                <InputNumber
                    value={focusedItem.content.titleFontSize}
                    onChange={value => onInputChange(focusedItem.id, 'titleFontSize', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>星星数量</div>
                <InputNumber
                    value={focusedItem.content.starNum}
                    onChange={starNumChange}
                />
            </div>
            <div>
                <h4>星级对应评价</h4>
                {focusedItem.content.starNum && Array.from({ length: focusedItem.content.starNum + 1 }).map((_, index) => (
                    <div key={index}>
                        <div className={styles.modifyItemTitle}>{index}颗星评价</div>
                        <Input
                            value={ratingDescriptions[index] || ''}
                            onChange={e => changeRatingDescriptions(index, e.target.value)}
                        />
                    </div>
                ))}
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>评价字体大小</div>
                <InputNumber
                    value={focusedItem.content.ratingDescFontSize}
                    onChange={value => onInputChange( focusedItem.id, 'ratingDescFontSize', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>评价区域高度</div>
                <InputNumber
                    value={focusedItem.content.ratingDescHeight}
                    onChange={value => onInputChange( focusedItem.id, 'ratingDescHeight', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>评价区域radius</div>
                <InputNumber
                    value={focusedItem.content.ratingDescBorderRadius}
                    onChange={value => onInputChange(focusedItem.id, 'ratingDescBorderRadius', value)}
                    className={styles.heightInput}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>评价文字颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.ratingDescColor}
                    onChange={e => {
                        onInputChange(focusedItem.id, 'ratingDescColor', e.target.value);
                    }}
                    className={styles.colorBoard}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>评价区域背景颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.ratingDescBackgroundColor}
                    onChange={e => {
                        onInputChange(focusedItem.id, 'ratingDescBackgroundColor', e.target.value);
                    }}
                    className={styles.colorBoard}
                />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>是否展示提交按钮</div>
                <Radio.Group
                    value={focusedItem.content.showSubmit}
                    onChange={e => onInputChange(focusedItem.id, 'showSubmit', e.target.value)}
                >
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                </Radio.Group>
            </div>
            {
                focusedItem.content.showSubmit &&
                <div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮宽度</div>
                        <InputNumber
                            value={focusedItem.content.btnWidth}
                            onChange={value => onInputChange(focusedItem.id, 'btnWidth', value)}
                            className={styles.widthInput}
                        />
                        <div className={styles.modifyItemTitle}>按钮高度</div>
                        <InputNumber
                            value={focusedItem.content.btnHeight}
                            onChange={value => onInputChange(focusedItem.id, 'btnHeight', value)}
                            className={styles.heightInput}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮文字大小</div>
                        <InputNumber
                            value={focusedItem.content.btnFontSize}
                            onChange={value => onInputChange(focusedItem.id, 'btnFontSize', value)}
                            className={styles.widthInput}
                        />
                    </div>  
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮border Radius</div>
                        <InputNumber
                            value={focusedItem.content.btnBorderRadius}
                            onChange={value => onInputChange(focusedItem.id, 'btnBorderRadius', value)}
                            className={styles.widthInput}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮背景颜色</div>
                            <input
                                type="color"
                                value={focusedItem.content.btnBackgroundColor}
                                onChange={e => {
                                    onInputChange(focusedItem.id, 'btnBackgroundColor', e.target.value);
                                }}
                                className={styles.colorBoard}
                            />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮文字颜色</div>
                            <input
                                type="color"
                                value={focusedItem.content.btnFontColor}
                                onChange={e => {
                                    onInputChange(focusedItem.id, 'btnFontColor', e.target.value);
                                }}
                                className={styles.colorBoard}
                            />
                    </div>  
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮border width</div>
                        <InputNumber
                            value={focusedItem.content.btnBorderWidth}
                            onChange={value => onInputChange(focusedItem.id, 'btnBorderWidth', value)}
                            className={styles.widthInput}
                        />
                    </div> 
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮border颜色</div>
                            <input
                                type="color"
                                value={focusedItem.content.btnBorderColor}
                                onChange={e => {
                                    onInputChange(focusedItem.id, 'btnBorderColor', e.target.value);
                                }}
                                className={styles.colorBoard}
                            />
                    </div> 
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮shadow_x</div>
                        <InputNumber
                            value={focusedItem.content.btnShadowX}
                            onChange={value => onInputChange(focusedItem.id, 'btnShadowX', value)}
                            className={styles.widthInput}
                        />
                        <div className={styles.modifyItemTitle}>按钮shadow_y</div>
                        <InputNumber
                            value={focusedItem.content.btnShadowY}
                            onChange={value => onInputChange(focusedItem.id, 'btnShadowY', value)}
                            className={styles.heightInput}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮shadow_blur</div>
                        <InputNumber
                            value={focusedItem.content.btnShadowBlur}
                            onChange={value => onInputChange(focusedItem.id, 'btnShadowBlur', value)}
                            className={styles.widthInput}
                        />
                        <div className={styles.modifyItemTitle}>按钮shadow_spread</div>
                        <InputNumber
                            value={focusedItem.content.btnShadowSpread}
                            onChange={value => onInputChange(focusedItem.id, 'btnShadowSpread', value)}
                            className={styles.heightInput}
                        />
                    </div>
                    <div className={styles.modifyItem}>
                        <div className={styles.modifyItemTitle}>按钮shadow颜色</div>
                            <input
                                type="color"
                                value={focusedItem.content.btnShadowColor}
                                onChange={e => {
                                    onInputChange(focusedItem.id, 'btnShadowColor', e.target.value);
                                }}
                                className={styles.colorBoard}
                            />
                    </div>
                </div>
            }    
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>背景颜色</div>
                <input
                    type="color"
                    value={focusedItem.content.backgroundColor}
                    onChange={e => {
                        onInputChange(focusedItem.id, 'backgroundColor', e.target.value);
                        onInputChange( focusedItem.id, 'backgroundImage', '');
                    }}
                    className={styles.colorBoard}
                />
            </div>
            <MediaFileSelectorModifier
                mediaType='images'
                afterSelectionCallback={(url) => onInputChange(focusedItem.id, 'backgroundImage', url)}
                btnText='背景图片'
            />
            <div>
                <Button onClick={() => changeZIndex(focusedItem.id, 'front')}>置于顶层</Button>
                <Button onClick={() => changeZIndex(focusedItem.id, 'back')}>置于底层</Button>
            </div>
        </div>
    )
};

export default RatingRegionModifier;