import { makeObservable, observable, action } from "mobx";

class CommonStatusStore {
    isDialogOpen: boolean = false;
    isSelected: boolean = false;
    isSubmitted: boolean = false;
    hasCorrectAnswer: boolean = false;
    btnLoading: boolean = false;
    genSongFinished: boolean = false;
    isClickPreset: boolean = false;
    isFetching: boolean = false;
    buttonFlashing: boolean = false;
    isClassifyFinished: boolean = false;
    isTeacherSpeaking: boolean = false;

    constructor() {
        makeObservable(this, {
            isDialogOpen: observable,
            isSelected: observable,
            isSubmitted: observable,
            hasCorrectAnswer: observable,
            btnLoading: observable,
            genSongFinished: observable,
            isClickPreset: observable,
            isFetching: observable,
            buttonFlashing: observable,
            isClassifyFinished: observable,
            isTeacherSpeaking: observable,
            openDialog: action,
            closeDialog: action,
            setIsSelected: action,
            setIsSubmitted: action,
            setHasCorrectAnswer: action,
            setBtnLoading: action,
            setGenSongFinished: action,
            setIsClickPreset: action,
            setIsFetching: action,
            setButtonFlashing: action,
            setIsClassifyFinished: action,
            setIsTeacherSpeaking: action,
        });
    }

    openDialog() {
        this.isDialogOpen = true;
    }

    closeDialog() {
        this.isDialogOpen = false;
    }

    setIsSelected(value: boolean) {
        this.isSelected = value;
    }

    setIsSubmitted(value: boolean) {
        this.isSubmitted = value;
    }

    setHasCorrectAnswer(value: boolean) {
        this.hasCorrectAnswer = value;
    }

    setBtnLoading(value: boolean) {
        this.btnLoading = value;
    }

    setGenSongFinished(value: boolean) {
        this.genSongFinished = value;
    }

    setIsClickPreset(value: boolean) {
        this.isClickPreset = value;
    }

    setIsFetching(value: boolean) {
        this.isFetching = value;
    }

    setButtonFlashing(value: boolean) {
        this.buttonFlashing = value;
    }

    setIsClassifyFinished(value: boolean) {
        this.isClassifyFinished = value;
    }

    setIsTeacherSpeaking(value: boolean) {
        this.isTeacherSpeaking = value;
    }
}

const commonStatusStore = new CommonStatusStore();
export { commonStatusStore, CommonStatusStore };
