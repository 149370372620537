import PropTypes from 'prop-types';
import GptIcon from '@/assets/img/chatgpt.svg';
import TeacherIcon from '@/assets/img/teacherProfile.png';
import UserIcon from '@/assets/img/chatuser_icon.svg';
import UserPng from '@/assets/img/user.png'
import styles from './aiChatRegion.module.less';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { rewriteText, containsVariablePattern } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import AIteacherAvatar from '/assets/img/teacherProfile.svg';
import { ChatMessage } from '@/base/ChatMessage';
import AudioPlayingIcon from '@/assets/img/chat_component/audio_playing.svg';

interface ChatBubbleProps {
    item: ChatMessage;
    role: string;
    userMessageAvatar?: string;
    isThisMsgPlaying?: boolean;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ item, role, userMessageAvatar, isThisMsgPlaying }) => {
    const { userInfoStore, ttsStore } = useStores();
    let mediaInfo = {};
    const userAvatar = userInfoStore.userInfoData.avatarUrl || UserPng;

    //if (item.type === 4) {
    //    try {
    //        // 解析 JSON 字符串
    //        mediaInfo = JSON.parse(item.mediaInfo);
    //    } catch (error) {
    //        mediaInfo = { type: '', url: '' };  // 解析失败设为空
    //    }
    //}

    const components = {
        code(props: any) {
            const { children, className, node, ...rest } = props
            const match = /language-(\w+)/.exec(className || '')
            return match ? (
                <SyntaxHighlighter
                    {...rest}
                    PreTag="div"
                    language={match[1]}
                    style={tomorrow}
                >
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            ) : (
                <code {...rest} className={className}>
                    {children}
                </code>
            )
        }
    };

    const finalMessage = containsVariablePattern(item.rawContent) ? rewriteText(item.rawContent) : item.rawContent;

    const pauseAudio = () => {
        ttsStore.setUserNeedPause(true);
    }
    return (
        <>
            {item.type === 3 &&
                <div className={styles.userMessage}>
                    <div className={styles.chatBubbleContent}>{finalMessage}</div>
                    <img
                        src={userMessageAvatar === "teacher" ? AIteacherAvatar : userAvatar}
                        alt="user"
                        className={styles.chatBubbleIcon}
                    />
                </div>}
            {item.type === 1 &&
                <div className={styles.gptMessage}>
                    <div className={styles.chatBubbleIcon}>
                        <img src={role === "gpt" ? GptIcon : TeacherIcon} alt="gpt" />
                        {isThisMsgPlaying && <img
                            src={AudioPlayingIcon}
                            alt="audioPlaying"
                            className={styles.audioPlayingIcon}
                            onClick={pauseAudio}
                        />}
                    </div>

                    <div className={styles.chatBubbleContent}>
                        <ReactMarkdown components={components}>
                            {finalMessage}
                        </ReactMarkdown>
                    </div>
                </div>
            }
            {
                item.type === 4 &&
                <div className={styles.gptMessage}>
                    <img src={role === "gpt" ? GptIcon : TeacherIcon} alt="gpt" className={styles.chatBubbleIcon} />
                    <div className={styles.chatBubbleContent}>
                        {item.mediaInfo && item.mediaInfo.mediaType === "image" &&
                            <img src={item.mediaInfo.mediaUrl} alt="media" className={styles.mediaContent} />
                        }
                    </div>
                </div>
            }
            {
                item.type === 5 &&
                <div className={styles.gptMessage}>
                    <img src={role === "gpt" ? GptIcon : TeacherIcon} alt="gpt" className={styles.chatBubbleIcon} />
                    <div className={styles.chatBubbleContent}>
                        {item.mediaInfo && item.mediaInfo.mediaType === "video" &&
                            <video
                                style={{ width: '100%', height: '100%' }}
                                src={item.mediaInfo.mediaUrl}
                                controls
                            />
                        }
                    </div>
                </div>
            }
        </>
    )
}
export default observer(ChatBubble);
