import PropTypes from 'prop-types';

const AudioStaticIcon = ({ color = '#1195db' }) => { // 添加默认值  
    return (
        <svg width="100%" height="100%" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 9C0 6.79086 1.79086 5 4 5H10V21H4C1.79086 21 0 19.2091 0 17V9Z" fill={color} />
            <path d="M10 5L14.94 1.9125C16.2721 1.07994 18 2.03762 18 3.6085V22.3915C18 23.9624 16.2721 24.9201 14.94 24.0875L10 21V5Z" fill={color} />
            <path id="thirdPath" d="M23 7C23 7 24 8.6 24 13C24 17.4 23 19 23 19" stroke={color} strokeWidth="4" strokeLinecap="round" />
            <path id="fourthPath" d="M30 3C30 3 32 6.13333 32 13C32 19.8667 30 23 30 23" stroke={color} strokeWidth="4" strokeLinecap="round" />
        </svg>
    );
}

AudioStaticIcon.propTypes = {
    color: PropTypes.string
}

export default AudioStaticIcon;