import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';

interface VisibleToTeacherProps {
    children: React.ReactNode;
}

const VisibleToTeacher: React.FC<VisibleToTeacherProps> = observer(({ children }) => {
    const { userInfoStore } = useStores();
    if (userInfoStore.userInfoData.userRole !== 1 
        || (userInfoStore.userInfoData.userRole === 1 
        && userInfoStore.userInfoData.userView !== 'teacher')) {
        return null;
    }

    return <>{children}</>;  
})

export default VisibleToTeacher;
