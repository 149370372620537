import {ElementContent} from './ElementData';

class VideoRegionContent extends ElementContent {
    src: string;
    
    constructor({
        positionX = 0,
        positionY = 0,
        width = 200,
        height = 200,
        src = ""
    }: Partial<VideoRegionContent> = {}) { 
        super(positionX, positionY, width, height);
        this.allowedFields = ['positionX', 'positionY', 'width', 'height', 'zIndex', 'src'];
        this.src = src;
    }
};

export default VideoRegionContent;