import styles from './rewardAnimation.module.less';
import Lottie from 'lottie-react';
import celebrateAnimation from '@/assets/animation/celebrate.json';
import CoinsIcon from "@/assets/img/user_center/coins_icon.svg";
import DiamondIcon from "@/assets/img/user_center/diamond_icon.svg";

interface RewardAnimationProps {
    rewardType: string;
    rewardNum: number;
}

const RewardAnimation: React.FC<RewardAnimationProps> = ({ rewardType, rewardNum }) => {
    return (
        <div className={styles.overlay}>
            <div className={styles.rewardInfo}>
                <div className={styles.rewardType}>
                    {rewardType === "coins" && <img src={CoinsIcon} alt="Coins" />}
                    {rewardType === "diamonds" && <img src={DiamondIcon} alt="Diamonds" />}
                </div>
                <div className={styles.background}></div>
                <div className={styles.rewardNum}>
                    x{rewardNum}
                </div>
            </div>
            <div className={styles.saHuaAnimation}>
                <Lottie animationData={celebrateAnimation} loop={true} />
            </div>
        </div>
    )
}

export default RewardAnimation;