import PropTypes from 'prop-types';
import { useState } from 'react';
import { InputNumber, Button } from 'antd';
import styles from './blockModifier.module.less';
import { cloneDeep } from 'lodash';
import { lectureStore } from '@/store/lectureStore';
import MediaFileSelectorModifier from '../MediaFileSelectorModifier/MediaFileSelectorModifier';
import URLModifier from '../URLModifier/URLModifier';
import { BlockData } from '@/base/BlockData';
import { PromptSetVarConfig } from '@/base/ElementData/UtilTypes';

interface BlockModifierProps {
    block: BlockData;
    setBlock: (block: BlockData) => void;
}

// 目前只有这里的field是snake_case
const BlockModifier: React.FC<BlockModifierProps> = ({
    block,
    setBlock,
    }) => {
    const [newConstantName, setNewConstantName] = useState('');
    const [newConstantValue, setNewConstantValue] = useState('');

    const onInputChangeBlkConfig = (key: string, value: any) => {
        const newBlock = cloneDeep(block);
        newBlock.extras[key] = value;
        setBlock(newBlock);
    };

    const addLectureConstants = () => {
        const newBlock = cloneDeep(block);
        if(!newBlock.extras['lectureConstants']) {
            newBlock.extras['lectureConstants'] = {};
        }
        newBlock.extras['lectureConstants'][newConstantName] = newConstantValue;
        setBlock(newBlock);
        lectureStore.setLectureVar(newConstantName, newConstantValue);
        setNewConstantName('');
        setNewConstantValue('');
    };

    const updateLectureConstants = (key: string, value: any) => {
        const newBlock = cloneDeep(block);
        newBlock.extras['lectureConstants'][key] = value;
        lectureStore.setLectureVar(key, value);
        setBlock(newBlock);
    };

    const deleteLectureConstants = (key: string) => () => {
        const newBlock = cloneDeep(block);
        delete newBlock.extras['lectureConstants'][key];
        lectureStore.delLectureVar(key);
        setBlock(newBlock);
    };

    const addPostProcessHandler = () => {
        const newBlock = cloneDeep(block);
        if(!newBlock.extras['postProcessHandlers']) {
            newBlock.extras['postProcessHandlers'] = [];
        }

        newBlock.extras['postProcessHandlers'].push(
            new PromptSetVarConfig({ prompt: "prompt", 
                destVar: "var" + String(newBlock.extras['postProcessHandlers'].length + 1) }));
        setBlock(newBlock);
    };

    const updatePostProcessHandler = (index: number, key: string, value: any) => {
        const newBlock = cloneDeep(block);
        newBlock.extras['postProcessHandlers'][index].setField((key as keyof PromptSetVarConfig), value);
        setBlock(newBlock);
    };

    const deletePostProcessHandler = (index: number) => () => {
        const newBlock = cloneDeep(block);
        newBlock.extras['postProcessHandlers'].splice(index, 1);
        setBlock(newBlock);
    };



    return (
        <div>
            <h4>Block Configurations</h4>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>Block Timeout (in seconds)</div>
                <InputNumber
                    value={block?.extras?.blkTimeout || 0}
                    onChange={value => onInputChangeBlkConfig('blkTimeout', value)}
                    className={styles.widthInput} />
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>选择背景</div>
                <MediaFileSelectorModifier
                    mediaType={'images'}
                    afterSelectionCallback={url => onInputChangeBlkConfig('background', url)}
                    btnText='背景'
                />
            </div>
            <URLModifier
                urlTitle='block背景url'
                value={block?.extras?.background}
                changeValue={url => onInputChangeBlkConfig('background', url)}
            />
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>当前block背景</div>
                <div className={styles.backgroundImg}>
                    {block?.extras?.background ?
                        <img src={block?.extras?.background} alt="background" className={styles.backgroundImage} /> :
                        <div className={styles.noBackground}>No background</div>
                    }
                </div>
            </div>
            <div className={styles.modifyItem}>
                <div className= {styles.modifyItemTitle}>给用户增加金币的个数</div>
                <InputNumber
                    value={block?.extras?.rewarded_coins_num}
                    onChange={value => onInputChangeBlkConfig('rewarded_coins_num', value)}
                    className={styles.widthInput} />
            </div>
            <h4>Lecture Constants</h4>
            <div className={styles.modifyItem}>
                {block?.extras['lectureConstants'] &&
                    Object.keys(block?.extras['lectureConstants']).map((constName, index) => (
                        <div className={styles.verticalLayout} key={String(index + 1)}>
                            <div key={index}>
                                <label>const name </label>
                                <textarea
                                    value={constName}
                                    readOnly
                                    className={styles.textareaPrompt} />
                                <br></br>
                                <label>const value </label>
                                <textarea
                                    value={block.extras['lectureConstants'][constName]}
                                    onChange={e => updateLectureConstants(constName, e.target.value)}
                                    className={styles.textareaVar} />
                            </div>
                            <br></br>
                            <Button onClick={deleteLectureConstants(constName)}>删除</Button>
                        </div>
                    ))}
                <hr></hr>
            </div>
            <div className={styles.modifyItem}>
                <div className={styles.modifyItemTitle}>New Lecture Constant Name</div>
                <textarea
                    value={newConstantName}
                    onChange={e => {setNewConstantName(e.target.value)}}
                    className={styles.textareaPrompt} />
            </div>
            <div className={styles.modifyItem}>
            <div className={styles.modifyItemTitle}>New Lecture Constant value</div>
                <textarea
                    value={newConstantValue}
                    onChange={e => setNewConstantValue(e.target.value)} 
                    className={styles.textareaPrompt} />
            </div>
            <Button onClick={addLectureConstants}>添加Lecture Constant</Button>
            <h4>Postprocess Handlers</h4>
            <div className={styles.modifyItem}>

                {block?.extras['postProcessHandlers'] &&
                    block?.extras['postProcessHandlers'].map((handler: PromptSetVarConfig, index: number) => (
                        <div className={styles.verticalLayout} key={String(index + 1)}>
                            <div key={index}>
                                <label>prompt </label>
                                <textarea
                                    value={handler.prompt}
                                    onChange={e => updatePostProcessHandler(index, 'prompt', e.target.value)}
                                    className={styles.textareaPrompt} />
                                <label>destination Lecture var </label>
                                <textarea
                                    value={handler.destVar}
                                    onChange={e => updatePostProcessHandler(index, 'destVar', e.target.value)}
                                    className={styles.textareaVar} />
                                <label>mode </label>
                                <textarea
                                    value={handler.mode}
                                    onChange={e => updatePostProcessHandler(index, 'mode', e.target.value)}
                                    className={styles.textareaVar} />
                            </div>
                            <br></br>
                            <Button onClick={deletePostProcessHandler(index)}>删除</Button>
                        </div>
                    ))}
                <hr></hr>
            </div>
            <Button onClick={addPostProcessHandler}>添加预设Postprocess Handler</Button>

        </div>
    )
};

export default BlockModifier;
