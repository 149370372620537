import PropTypes from 'prop-types';
import { InputNumber, Input, Switch, Select } from 'antd';
import styles from "./teacherWordsModifier.module.less";
import MediaFileSelectorModifier from '../MediaFileSelectorModifier/MediaFileSelectorModifier';
import useCommonFunction from '@/hooks/useCommonFunction';
import { cloneDeep } from 'lodash';
import TextToSpeech from '@/components/TextToSpeech/TextToSpeech';
import { ElementData } from '@/base/ElementData/ElementData';
import TeacherWordsRegionContent from '@/base/ElementData/TeacherWordsRegionContent';

const { TextArea } = Input;
interface TeacherWordsModifierProps {
    focusedItem: ElementData<TeacherWordsRegionContent>;
    onInputChange: (id: number, key: string, value: any) => void;
    changeZIndex: (id: number, direction: string) => void;
};


const TeacherWordsModifier: React.FC<TeacherWordsModifierProps> = ({
    focusedItem,
    onInputChange,
    changeZIndex,
}) => {
    const { generateSelectOptionsData, generateElementIdOptions } = useCommonFunction();
    const optionsData = generateSelectOptionsData();
    const elementIdOptions = generateElementIdOptions();

    //更新done_call_func的funcName
    const updateDoneCallFunc = (value: string) => {
        const newDoneCallFunc = cloneDeep(focusedItem?.content?.doneCallFunc || {});
        newDoneCallFunc.funcName = value;
        onInputChange(focusedItem.id, 'doneCallFunc', newDoneCallFunc);
    }
    //更新done_call_func的funcParams
    const updateDoneCallFuncParams = (key: string, value: any) => {
        const newDoneCallFunc = cloneDeep(focusedItem?.content?.doneCallFunc || {});
        const funcParams = newDoneCallFunc?.funcParams || {};
        const newFuncParams = { ...funcParams, [key]: value };
        newDoneCallFunc.funcParams = newFuncParams;
        onInputChange(focusedItem.id, 'doneCallFunc', newDoneCallFunc);
    }
    return (
        <div>
            <div className={styles.mainModify}>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>X</div>
                    <InputNumber
                        value={focusedItem.content.positionX}
                        onChange={value => onInputChange(focusedItem.id, 'positionX', value)}
                    />
                    <div className={styles.modifyItemTitle}>Y</div>
                    <InputNumber
                        value={focusedItem.content.positionY}
                        onChange={value => onInputChange(focusedItem.id, 'positionY', value)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>宽度</div>
                    <InputNumber
                        value={focusedItem.content.width}
                        onChange={value => onInputChange(focusedItem.id, 'width', value)}
                        className={styles.widthInput}
                    />
                    <div className={styles.modifyItemTitle}>高度</div>
                    <InputNumber
                        value={focusedItem.content.height}
                        onChange={value => onInputChange(focusedItem.id, 'height', value)}
                        className={styles.heightInput}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>选择头像</div>
                    <MediaFileSelectorModifier
                        mediaType={'images'}
                        afterSelectionCallback={url => onInputChange(focusedItem.id, 'teacherProfile', url)}
                        btnText='头像'
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>选择头像动图</div>
                    <MediaFileSelectorModifier
                        mediaType={'images'}
                        afterSelectionCallback={url => onInputChange(focusedItem.id, 'teacherProfileGif', url)}
                        btnText='动图'
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>当前头像动图</div>
                    <img
                        className={styles.teacherProfileGif}
                        src={focusedItem.content.teacherProfileGif}
                        alt='teacherProfileGif'
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>老师名字</div>
                    <div>
                        <Input
                            value={focusedItem.content.teacherName}
                            onChange={e => onInputChange(focusedItem.id, 'teacherName', e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>老师的话</div>
                    <div className={styles.modifyItemContent}>
                        <TextArea
                            value={focusedItem.content.teacherWords}
                            onChange={e => onInputChange(focusedItem.id, 'teacherWords', e.target.value)}
                            autoSize={{ minRows: 3, maxRows: 6 }}
                        />
                    </div>
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>是否自动播放</div>
                    <Switch
                        checked={focusedItem.content.autoPlay}
                        onChange={value => onInputChange(focusedItem.id, 'autoPlay', value)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <TextToSpeech
                        text={focusedItem.content.teacherWords}
                        btnText='生成音频'
                        successCallback={url => onInputChange(focusedItem.id, 'audioUrl', url)}
                        ttsVoice={focusedItem.content.ttsVoice}
                        ttsVoiceChangeCallback={value => onInputChange(focusedItem.id, 'ttsVoice', value)}
                    />
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>
                        当前是否已有音频链接：{focusedItem.content.audioUrl ? '是' : '否'}
                    </div>
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>展示文字速度</div>
                    <InputNumber
                        value={focusedItem.content.showSpeedWordNum}
                        onChange={value => onInputChange(focusedItem.id, 'showSpeedWordNum', value)}
                        className={styles.showSpeedWordInput}
                    />
                    字/
                    <InputNumber
                        value={focusedItem.content.showSpeedTime}
                        onChange={value => onInputChange(focusedItem.id, 'showSpeedTime', value)}
                        className={styles.showSpeedTimeInput}
                    />
                    毫秒
                </div>
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>每页最大字数</div>
                    <InputNumber
                        value={focusedItem.content.characterPerPage}
                        onChange={value => onInputChange(focusedItem.id, 'characterPerPage', value)}
                        className={styles.showSpeedWordInput}
                    />
                </div>
                <div className={styles.modifyItemCol}>
                    <div className={styles.modifyItemTitle}>
                        老师说完话后的操作
                    </div>
                    <Select
                        value={focusedItem.content.doneCallFunc?.funcName}
                        onChange={value => updateDoneCallFunc(value)}
                        className={styles.functionSelect}
                    >
                        {optionsData.map(option => (
                            <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                        ))}
                    </Select>
                </div>
                {
                    (focusedItem.content.doneCallFunc?.funcName === 'showSpecifiedElement' ||
                        focusedItem.content.doneCallFunc?.funcName === 'showSpecifiedElementAndHide' ||
                        focusedItem.content.doneCallFunc?.funcName === 'changeTeacherWords') && (
                        <div className={styles.modifyItemCol}>
                            <div className={styles.modifyItemTitle}>elementId</div>
                            <Select
                                value={focusedItem.content?.doneCallFunc?.funcParams?.elementId}
                                onChange={value => updateDoneCallFuncParams('elementId', value)}
                                className={styles.functionSelect}
                            >
                                {elementIdOptions.map(option => (
                                    <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                ))}
                            </Select>
                        </div>
                    )
                }
                {
                    focusedItem.content?.doneCallFunc?.funcName === 'changeTeacherWords' && (
                        <>
                            <div className={styles.modifyItemCol}>
                                <div className={styles.modifyItemTitle}>参数:new words</div>
                                <textarea
                                    value={focusedItem.content?.doneCallFunc?.funcParams?.newWords}
                                    onChange={e => updateDoneCallFuncParams('newWords', e.target.value)}
                                    className={styles.textareaSmall}
                                />
                            </div>
                            <div className={styles.modifyItemCol}>
                                <div className={styles.modifyItemTitle}>参数:audio url</div>
                                <TextToSpeech
                                    text={focusedItem.content?.doneCallFunc?.funcParams?.newWords}
                                    btnText='生成音频'
                                    successCallback={url => updateDoneCallFuncParams('audioUrl', url)}
                                />
                            </div>
                            <div className={styles.modifyItem}>
                                <div className={styles.modifyItemTitle}>当前是否已有音频链接:</div>
                                {focusedItem.content?.doneCallFunc?.funcParams?.audioUrl ? "是" : "否"}
                            </div>
                        </>
                    )
                }
                {
                    focusedItem.content?.doneCallFunc?.funcName === 'navigate' && (
                        <div className={styles.modifyItemCol}>
                            <div className={styles.modifyItemTitle}>url</div>
                            <Input
                                value={focusedItem.content?.doneCallFunc?.funcParams?.url}
                                onChange={e => updateDoneCallFuncParams('url', e.target.value)}
                            />
                        </div>
                    )
                }
                <div className={styles.modifyItem}>
                    <div className={styles.modifyItemTitle}>点击老师头像是否可以打开对话弹窗</div>
                    <Switch
                        checked={focusedItem.content.shouldOpenTeacherDialog}
                        onChange={value => onInputChange(focusedItem.id, 'shouldOpenTeacherDialog', value)}
                    />
                </div>
            </div>
        </div>
    )
}
export default TeacherWordsModifier;