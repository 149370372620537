import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button } from 'antd';
import MediaFileSelector from '@/components/MediaFileSelector/MediaFileSelector';

interface MediaFileSelectorModifierProps {
    mediaType: string;
    afterSelectionCallback: (url: string) => void;
    btnText: string;
};


const MediaFileSelectorModifier: React.FC<MediaFileSelectorModifierProps> = ({ 
    mediaType, 
    afterSelectionCallback, 
    btnText }) => {
    const [fileSelectorOpen, setFileSelectorOpen] = useState(false);
    const showFileSelector = () => {
        setFileSelectorOpen(true);
    }


    return (
        <div>
            <Button type="primary" onClick={() => showFileSelector()}>
                选择{btnText}
            </Button>
            <MediaFileSelector
                fileSelectorOpen={fileSelectorOpen}
                setFileSelectorOpen={setFileSelectorOpen}
                afterSelectionCallback={afterSelectionCallback}
                mediaType={mediaType}
            />
        </div>
    )
};

export default MediaFileSelectorModifier;
