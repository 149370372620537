import React from 'react';
import { ElementData, ElementContent } from '@/base/ElementData/ElementData';
import { cloneDeep } from 'lodash';

interface ResizeDirection {
    horizontal: "start" | "center" | "end";
    vertical: "start" | "center" | "end";
}

interface ElementResizableProps {
    elementData: ElementData<ElementContent>;
    handleResize: (newElementData: ElementData<ElementContent>) => void;
}

const directions: Record<string, ResizeDirection> = {
    left: { horizontal: "start", vertical: "center" },
    right: { horizontal: "end", vertical: "center" },
    top: { horizontal: "center", vertical: "start" },
    bottom: { horizontal: "center", vertical: "end" },
    "top-left": { horizontal: "start", vertical: "start" },
    "top-right": { horizontal: "end", vertical: "start" },
    "bottom-left": { horizontal: "start", vertical: "end" },
    "bottom-right": { horizontal: "end", vertical: "end" },
};

const ElementResizable: React.FC<ElementResizableProps> = ({ elementData, handleResize }) => {
    let data = {
        startX: 0,
        startY: 0,
        startWidth: 0,
        startHeight: 0,
        startLeft: 0,
        startTop: 0,
        direction: { horizontal: "center", vertical: "center" } as ResizeDirection,
    };

    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        let clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        let clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

        const { startX, startY, startWidth, startHeight, startLeft, startTop, direction } = data;
        if (direction.horizontal === "center") {
            clientX = startX;
        }
        if (direction.vertical === "center") {
            clientY = startY;
        }

        let durX = clientX - startX;
        let durY = clientY - startY;

        const newElement = cloneDeep(elementData);

        if (direction.vertical === "start") {
            durY = -durY;
            newElement.content.positionY = startTop - durY;
        }
        if (direction.horizontal === "start") {
            durX = -durX;
            newElement.content.positionX = startLeft - durX;
        }

        newElement.content.width = startWidth + durX;
        newElement.content.height = startHeight + durY;

        handleResize(newElement);
    };

    const handleMouseUp = () => {
        document.removeEventListener("touchmove", handleMouseMove);
        document.removeEventListener("touchend", handleMouseUp);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleMouseDown = (e: React.MouseEvent | React.TouchEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
        const direction = directions[(e.currentTarget as HTMLDivElement).dataset.dir!];

        data = {
            startX: clientX,
            startY: clientY,
            startWidth: elementData.content.width,
            startHeight: elementData.content.height,
            startLeft: elementData.content.positionX,
            startTop: elementData.content.positionY,
            direction: direction,
        };

        document.addEventListener("touchmove", handleMouseMove, { passive: false });
        document.addEventListener("touchend", handleMouseUp, { passive: false });
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    return (
        <div>
            {Object.keys(directions).map((key) => (
                <div
                    className={`block-resize block-resize-${key}`}
                    key={key}
                    data-dir={key}
                    onTouchStart={handleMouseDown}
                    onMouseDown={handleMouseDown}
                />
            ))}
        </div>
    );
};

export default ElementResizable;