import { useState } from 'react';
import { Button } from 'antd';
import service from '@/services/axios';
import styles from './mediaFileSelector.module.less';
import { modalConfirmWidget } from '../ModalWidget/ModalWidget';

type MediaFileData = {
    url: string;
    mediaType: string;
};

type MediaFileSelectorProps = {
    fileData: MediaFileData;
    handleClick: (url: string) => void;
    getMediaFileList: () => void;
};

const MediaFileItem: React.FC<MediaFileSelectorProps> = ({
    fileData,
    handleClick,
    getMediaFileList }) => {
    const [isRenaming, setIsRenaming] = useState(false);
    const [newFileName, setNewFileName] = useState('');

    const shortName = (name: string) => {
        // strip the prefix (mediatype) from the name
        const newName = name.substring(name.lastIndexOf('/') + 1);
        if (newName.length <= 16) return newName;

        const prefix = newName.substring(0, 2);
        const suffix = newName.substring(newName.length - 8);
        return `${prefix}****${suffix}`;
    };

    const getLongNameAndDir = (name: string) => {
        let dir = "";
        if (fileData.mediaType === 'images') {
            dir = 'images';
        } else if (fileData.mediaType === 'videos') {
            dir = 'videos';
        } else if (fileData.mediaType === 'audios') {
            dir = 'audios';
        }
        return [dir, dir + "/" + name.substring(name.lastIndexOf('/') + 1)];
    };

    const handleRenameClick = () => {
        setIsRenaming(true);
    };

    const handleInputChange = (e: any) => {
        setNewFileName(e.target.value);
    };

    const handleSaveClick = async () => {
        try {
            console.log('handleSaveClick');

            const [dir, oldFileName] = getLongNameAndDir(fileData.url);
            const completeFileName = dir + "/" + newFileName;
            const res: any = await service.post('/rename_file', { old_name: oldFileName, new_name: completeFileName });
            if (res.status === 'success') {
                console.log('rename file success', res);
            } else {
                console.log("failed");
            }
            setIsRenaming(false);
            getMediaFileList();

        } catch (error) {
            console.log('Error renaming file');
            setIsRenaming(false);
        }
    };

    const handleCancelClick = () => {
        setIsRenaming(false);
        setNewFileName('');
    };

    const deleteItem = async () => {
        setIsRenaming(false);
        try {
            const [_, fName] = getLongNameAndDir(fileData.url);
            const res: any = await service.post('/delete_file', { file_name: fName });
            if (res.status === 'success') {
                console.log('rename file success', res);
            } else {
                console.log("failed");
            }

            getMediaFileList();

        } catch (error) {
            console.log('Error deleting file');
        }
    };

    const handleDelete = () => {
        modalConfirmWidget({
            title: '删除文件',
            content: '确定要删除这个文件吗？',
            onOk: deleteItem,
        });
    };


    const genMediaContent = () => {
        let mediaContent;
        switch (fileData.mediaType) {
            case 'images':
                mediaContent = <img src={fileData.url} onClick={() => handleClick(fileData.url)} />;
                break;
            case 'videos':
                mediaContent = (
                    <div className={styles.videoBox}>
                        <video controls>
                            <source src={fileData.url} type="video/mp4" />
                        </video>
                        <div onClick={() => handleClick(fileData.url)} className={styles.chooseBtn}>选择</div>
                    </div>
                );
                break;
            case 'audios':
                mediaContent = (
                    <div className={styles.audioBox}>
                        <audio controls >
                            <source src={fileData.url} type="audio/mpeg" />
                        </audio>
                        <Button onClick={() => handleClick(fileData.url)} className={styles.chooseBtn}>选择</Button>
                    </div>
                );
                break;
            default:
                mediaContent = null;
        }
        return mediaContent;
    };

    return (<div className={styles.mediaFileItem}>
        {genMediaContent()}
        {isRenaming ? (
            <div>
                <input type="text" placeholder={shortName(fileData.url)} value={newFileName} onChange={handleInputChange} />
                <button onClick={handleSaveClick}>Save</button>
                <button onClick={handleCancelClick}>Cancel</button>
            </div>
        ) : (
            <div>
                <input type="text" value={shortName(fileData.url)} readOnly />
                <button onClick={handleRenameClick}>Rename</button>
                <button onClick={handleDelete}>Delete</button>
            </div>
        )}
    </div>);
};

export default MediaFileItem;
