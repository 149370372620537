import { pyodideStore, PyodideStore } from './pyodideStore';
import { lectureStore, LectureStore } from './lectureStore';
import { userInfoStore, UserInfoStore } from './userInfoStore';
import { ttsStore, TTSStore } from './ttsStore';
import { commonStatusStore, CommonStatusStore} from './commonStatusStore';
import { audioStore, AudioStore } from './audioStore';
import { viewportStore, ViewportStore } from './viewportStore';

type RootStore = {
    pyodideStore: PyodideStore,
    lectureStore: LectureStore,
    userInfoStore: UserInfoStore,
    ttsStore: TTSStore,
    commonStatusStore: CommonStatusStore,
    audioStore: AudioStore,
    viewportStore: ViewportStore
};

export const rootStore: RootStore = {
    pyodideStore,
    lectureStore,
    userInfoStore,
    ttsStore,
    commonStatusStore,
    audioStore,
    viewportStore,
};

export type { RootStore };